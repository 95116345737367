package frm.vali

import ein2b.core.validation.eRuleVali

val ValiS3AccessKey = eRuleVali{
    val msg1 = "r@accessKey를 입력해 주세요.@5/3/P"
    Case{
        String(msg1)
        Trim()
        MinLength(1, msg1)
        MaxLength(250, "r@accessKey를 250자 미만으로 입력해 주세요.@5/3/Q")
    }
}

val ValiS3SecretKey = eRuleVali{
    val msg1 = "r@secretKey를 입력해 주세요.@5/3/R"
    Case{
        String(msg1)
        Trim()
        MinLength(1, msg1)
        MaxLength(250, "r@secretKey를 250자 미만으로 입력해 주세요.@5/3/S")
    }
}

val ValiS3Bucket = eRuleVali{
    val msg1 = "r@bucket을 입력해 주세요.@5/3/T"
    Case{
        String(msg1)
        Trim()
        MinLength(1, msg1)
        MaxLength(100, "r@bucket을 100자 미만으로 입력해 주세요.@5/3/U")
    }
}

val ValiS3Region = eRuleVali{
    val msg1 = "r@region을 입력해 주세요.@5/3/V"
    Case{
        String(msg1)
        Trim()
        MinLength(1, msg1)
        MaxLength(50, "r@region을 50자 미만으로 입력해 주세요.@5/3/W")
    }
}

val ValiS3Prefix = eRuleVali{
    val msg1 = "r@prefix를 입력해 주세요.@5/3/X"
    Case{
        String(msg1)
        Trim()
        MinLength(1, msg1)
        MaxLength(100, "r@prefix를 100자 미만으로 입력해 주세요.@5/3/Y")
    }
}