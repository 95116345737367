package entity

import app.App
import ein2b.core.core.ePage
import ein2b.core.entity.eEntity
import ein2b.core.entity.field.LongField.default
import ein2b.core.view.eView
import frm.api.EntApiCat1E
import frm.enum.EnumTypeCat
import frm.model.MdlCat2
import frm.model.MdlCat2ItemParam
import frm.model.MdlCat2Param
import frm.model.MdlHex2Type
import m42.common.model.MdlM42ApiPageMeta
import m42.model.ViewModel
import org.w3c.dom.HTMLElement

class EntClientAddCat2Item{
    var key = ""
    var value = ""
    var title = ""
    var hex2TypeRowid = 0L
}
fun strToList(v:String):MutableList<EntClientAddCat2Item>{
    val result = mutableListOf<EntClientAddCat2Item>()
    v.split("\n").map{
        it.split("|").also{item->
            result += EntClientAddCat2Item().also{ cat2Item ->
                cat2Item.key = item[0]
                cat2Item.value = item[1]
                cat2Item.title = item[2]
            }
        }
    }
    return result
}

class EntClientCat1Edit:ViewModel(){
    companion object{
        const val RPP = 50L
    }

    class EntClientCat2Item{
        lateinit var view:eView<HTMLElement>
        var cat2ItemRowid = 0L
        var cat2ItemKey = ""
        var cat2ItemValue = ""
        var cat2ItemTitle = ""
        var updatedDate = ""
        var eventCnt = 0
        var isLogActive = false
        var hex2TypeRowid = 0L
    }
    class EntClientHex2Type{
        var hex2TypeRowid = 0L
        var title = ""
        var typeCat = EnumTypeCat.STRING
        var js = ""
    }
    class EntClientCat2(val mdlCat2:MdlCat2?=null){
        lateinit var view: eView<HTMLElement>
        var param = MdlCat2Param()
        var cat2Rowid = 0L
        var isSelect = false
        var pageMeta = MdlM42ApiPageMeta().also {
            it.rowPerPage = "$RPP"
        }
        var page = 1L
        var pageList = listOf<Cat2Page>()
        val currPage get() = pageList.find { it.page == page }
        var cat2Title = mdlCat2?.title ?: ""
        var itemCnt = "${mdlCat2?.cat2ItemList?.size}" ?: "0"
        var itemAddList = ""
        fun setData(){
            mdlCat2?.also {cat2->
                param.also {
                    it.cat2Rowid = cat2.cat2Rowid
                    it.title = cat2.title
                    it.cat2ItemList = cat2.cat2ItemList.map {from->
                        MdlCat2ItemParam().also {to->
                           to._cat2Rowid = cat2.cat2Rowid
                           to.cat2ItemRowid = from.cat2ItemRowid
                           to.key = from.key
                           to.title = from.title
                           to.value = from.value
                        }
                    }.toMutableList()
                }
                cat2Rowid = cat2.cat2Rowid
            }
            val totalRows = mdlCat2?.cat2ItemList?.size ?: 0
            pageMeta.totalRows = "$totalRows"

            val totalPage = ePage.totalPage(totalRows.toLong(), RPP)
            pageList = (1..totalPage).map {
                val startIdx = ePage.start(it, RPP).toInt()
                var endIdx = ePage.end(it, RPP).toInt()
                if(totalRows < endIdx) endIdx = totalRows
                Cat2Page(it).also { cat2Page ->
                    cat2Page.cat2ItemList = mdlCat2?.cat2ItemList?.subList(startIdx, endIdx)?.map { mdlCat2Item->
                        EntClientCat2Item().also {cat2Item->
                            cat2Item.cat2ItemRowid = mdlCat2Item.cat2ItemRowid
                            cat2Item.cat2ItemKey = mdlCat2Item.key
                            cat2Item.cat2ItemValue = mdlCat2Item.value
                            cat2Item.cat2ItemTitle = mdlCat2Item.title
                            cat2Item.hex2TypeRowid = mdlCat2Item.hex2TypeRowid
                            cat2Item.updatedDate = App.datePart(mdlCat2Item.updateDate)
                            cat2Item.eventCnt = mdlCat2Item.eventCnt
                            cat2Item.isLogActive = mdlCat2Item.isLogging
                        }
                    }?.toMutableList() ?: mutableListOf()
                }
            }

        }
        fun init(){
            setPage(1)
        }
        fun setPage(p:Long){
            page = p
            pageMeta.page = "$page"
        }

    }

    class Cat2Page(val page:Long){
        var cat2ItemList = mutableListOf<EntClientCat2Item>()
    }
    var cat1Rowid = 0L
    var cat1Title = ""
    var cat2List = listOf<EntClientCat2>()
    var cat2ItemAddStr = ""
    val cat2 get() = cat2List.find { it.isSelect }
    var cat2ItemList = mutableListOf<EntClientCat2Item>()
    var cat2AddList = mutableListOf<EntClientCat2>()
    var deleteList = mutableListOf<Long>()
    var editList = mutableListOf<MdlCat2ItemParam>()
    var page = 1L
    var hex2TypeList = mutableListOf<EntClientHex2Type>()
    fun init(){
        cat1Rowid = 0L
        cat1Title = ""
        cat2ItemAddStr = ""
        cat2List = listOf()
        cat2ItemList = mutableListOf()
        cat2AddList = mutableListOf()
        deleteList = mutableListOf()
        editList = mutableListOf()
        hex2TypeList = mutableListOf()
    }

    fun setData(target: EntApiCat1E.Res){
        val cat1 = target.cat1
        cat1Rowid = cat1.cat1Rowid
        cat1Title = cat1.title
        cat2List = listOf()
        cat2ItemList = mutableListOf()
        cat2AddList = mutableListOf()
        cat2List = cat1.cat2List.map { mdlCat2->
            EntClientCat2(mdlCat2).also { cat2 -> cat2.setData() }
        }
        hex2TypeList = target.hex2TypeList.map { from ->
            EntClientHex2Type().also { to ->
                to.hex2TypeRowid = from.hex2TypeRowid
                to.title = from.title
                to.typeCat = from.typeCat
                to.js = from.js
            }
        }.toMutableList()
    }
    fun cat2Select(cat2: EntClientCat2?){
        cat2List.forEach { it.isSelect = false }
        cat2List.find { it.cat2Rowid == cat2?.cat2Rowid }?.let {
             it.isSelect = true
            it.init()
        }
    }
    fun cat2SetPage(p: Long){
        cat2?.setPage(p)
    }
    fun addForm(){
        cat2AddList += EntClientCat2()
    }
}