package router.viewForm

import app.Factory
import comp.CompPageTitle
import comp.compPageTitle
import comp.input.compToggleRadio
import comp.input.compToggleRadioSet
import compLabelInputSectionTextSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import entity.EntClientViewForm
import frm.model.MdlViewAddParam
import frm.model.MdlViewEditParam
import frm.model.MdlViewParam
import frm.vali.ValiViewName
import m42.model.HcInit
import org.w3c.dom.HTMLElement
import org.w3c.dom.events.Event
import prop.compLabelInputSectionText

private val factory = Factory.htmlUrl("viewForm/main")
private val cat2BtnFactory = Factory.htmlUrl("viewForm/cat2Btn")

enum class K{
    nav, viewTitle, submit1, submit2,
    cat2BtnList, cat2Btn_title, cat2Btn_cnt, cat2Btn_errorIcon,
    cat2;
    override fun toString() = if ("_" in name) name.substring(name.lastIndexOf("_") + 1) else name
}
suspend fun ViewFormView(title:String, btnTitle:String, isAdd:Boolean = false, submit:suspend (MdlViewParam)->Unit, block:(eView<HTMLElement>, model:EntClientViewForm)->Unit
) = eView(factory){staticView->
    val model = EntClientViewForm()
    CompPageTitle(staticView){}
    staticView.compLabelInputSectionTextSet(K.viewTitle, "r@뷰 이름@5/6/5", ValiViewName, true, sectionWrapperClass = "", sectionClass = ""){
        it.inputClass = "width3-1"
        it.changeBlock = { v->
            model.viewTitle = v
            v
        }
    }
    staticView.compToggleRadioSet<EntClientViewForm.Cat2>(K.cat2BtnList){
        it.wrapperClass = "cat2-list flex-center"
        it.itemClass = "cat2-list-item"
        it.checkBlock = { idx ->
            model.cat2Select(it.itemList[idx].value)
            eLaunch{ staticView.entity(model) }
        }
    }
    subCat2View(staticView, model)
    staticView.sub(K.submit1).html = btnTitle
    staticView.sub(K.submit2).html = btnTitle
    block(staticView, model)
    suspend fun cat2BtnRender(){
        staticView.sub(K.cat2BtnList).compToggleRadio<EntClientViewForm.Cat2>{ comp ->
            comp.setList(model.cat2List.map{
                comp.item(it.cat2Title, it, it.isSelected)
            }, cat2BtnFactory){ view, item ->
                val cat2 = item.value
                view.sub(K.cat2Btn_title).html = cat2.cat2Title
                view.sub(K.cat2Btn_cnt).html = cat2.itemCnt
                view.sub(K.cat2Btn_errorIcon){
                    if(cat2.isOk) it.displayNone() else it.displayBlock()
                }
            }
        }
    }
    staticView.addEntityHook(HcInit::class, object:eEntityHook<HTMLElement, HcInit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:HcInit){
            model.init()
        }
    })
    staticView.addEntityHook(EntClientViewForm::class, object:eEntityHook<HTMLElement, EntClientViewForm>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntClientViewForm){
            view.sub(K.nav).compPageTitle().setTitle(title, "v0" to entity.cat1Title)
            view.sub(K.viewTitle).compLabelInputSectionText{
                it.inputValue(entity.viewTitle)
            }
            cat2BtnRender()
            view.sub(K.cat2).entity(entity)
            val submitClick = { _:Event, _:HTMLElement ->
                eLaunch{
                    val param = if(isAdd) MdlViewAddParam().also{ addParam->
                        addParam.cat1Rowid = entity.cat1Rowid
                    }else MdlViewEditParam().also{ editParam->
                        editParam.viewRowid = entity.viewRowid
                    }
                    param.viewItemList = mutableListOf()

                    val viewTitle = view.sub(K.viewTitle).compLabelInputSectionText()
                    var isCheck = viewTitle.check()
                    view.sub(K.cat2).entity(param)
                    entity.cat2List.forEach{ cat2 ->
                        cat2.viewItemList.forEach{ viewItem ->
                            if(!viewItem.isOk) isCheck = false
                        }
                    }
                    cat2BtnRender()
                    if(isCheck){
                        param.title = viewTitle.out()
                        submit(param)
                    }
                }
            }
            view.sub(K.submit1).click = submitClick
            view.sub(K.submit2).click = submitClick
        }
    })
}