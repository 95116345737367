package router.cat1Edit

import app.*
import frm.api.EntApiCat1E
import frm.api.EntApiCat1Wp
import frm.api.member.EntApiMemberSessionCheck
import frm.model.MdlCat1EpParam
import frm.model.MdlCat1Param
import frm.model.MdlCat2EpParam
import m42.common.api.EntM42Api.Companion.ENTITY_KEY

object N {
    suspend fun editReady(cat1Rowid: Long): EntApiCat1E.Res? = ClientApiCat1E.net { req ->
        req.cat1Rowid = cat1Rowid
    }

    suspend fun edit(param: MdlCat1EpParam): EntApiCat1E.Res? = ClientApiCat1Ep.net(ENTITY_KEY to { param })
    suspend fun cat2Add(param: MdlCat2EpParam): EntApiCat1E.Res? = ClientApiCat2Ep.net(ENTITY_KEY to { param })
    suspend fun cat2Delete(cat1Rowid: Long, cat2Rowid: Long): EntApiCat1E.Res? = ClientApiCat2Dp.net { req ->
        req.cat1Rowid = cat1Rowid
        req.cat2Rowid = cat2Rowid
    }
}