package router.pwReset

import app.*
import frm.api.member.EntApiMemberRsa
import frm.api.member.*

object N{
    suspend fun rsaGet(): EntApiMemberRsa.Res? = ClientApiMemberRsa.net()
    suspend fun emailE2(urlToken: String): EntApiMemberPwEmailE2.Res? = ClientApiMemberPwEmailE2.net{req->
        req.urlToken = urlToken
    }
    suspend fun pwEmailChange(rsaId:String, pw:String, token:String): EntApiMemberPwEmailEp.Res? = ClientApiMemberPwEmailEp.net{ req->
        req.rsaId = rsaId
        req.pw = pw
        req.token = token
    }
}