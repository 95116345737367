package frm.model

import ein2b.core.entity.eEntity
import frm.enum.EnumEventCat

class MdlCat2ItemEventCmdParam:eEntity(true){
    var cat2ItemEventCmdRowid by long // 등록은 0, 수정은 해당 값
    var cat2ItemRowid by long // 카테고리 2 아이템 Row ID
    var eventCat by enum<EnumEventCat>() // EnumEventCat에 있는 값
    var condition by string // 조건 값
    var period by int // 재발송 주기, 예: 3600초 = 1시간
    var executionPeriod by int // 기본값 1
    var emailList by string // 이메일 리스트, 여러 개면 ','로 구분
    var message by string //이메일 본분에 들어가는 내용
}