package router.viewDetail

import app.ALink
import app.Factory
import app.RouterKey
import comp.CompPageTitle
import comp.compPageTitle
import ein2b.core.coroutine.eLaunch
import ein2b.core.date.eUtc
import ein2b.core.date.now
import ein2b.core.date.toLocalDateTime
import ein2b.core.view.*
import entity.EntClientView
import frm.api.EntApiViewV
import kotlinx.browser.window
import m42.common.M42ApiNet
import m42.model.HcInit
import org.w3c.dom.HTMLElement
import router.comp.CompLog

private val factory = Factory.htmlUrl("viewDetail/main")
private val cat2Factory = Factory.htmlUrl("viewDetail/cat2")
private val cat2ItemFactory = Factory.htmlUrl("viewDetail/cat2item")
private enum class K{
    nav, cat2List, empty,
    cat2_title, cat2_itemList, refreshDate,
    item_title, item_value, item_eventBtn, item_logBtn;
    override fun toString() = if ("_" in name) name.substring(name.lastIndexOf("_") + 1) else name
}
suspend fun ViewDetail() = eView(factory){ staticView->
    val model = EntClientView()
    var intervalId = -1
    fun uploadCheckClearInterval(){
        if(intervalId > -1) window.clearInterval(intervalId)
    }
    CompPageTitle(staticView){}
    staticView.sub(K.refreshDate)
    staticView.sub(K.cat2List).displayNone()
    staticView.sub(K.empty).displayNone()

    staticView.addEntityHook(HcInit::class, object: eEntityHook<HTMLElement, HcInit> {
        override suspend fun invoke(view:eView<HTMLElement>, entity: HcInit){
            uploadCheckClearInterval()
        }
    })
    staticView.addEntityHook(EntApiViewV.Res::class, object: eEntityHook<HTMLElement, EntApiViewV.Res> {
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntApiViewV.Res){
            model.setData(entity)
            view.entity(model)
        }
    })

    staticView.addEntityHook(EntClientView::class, object: eEntityHook<HTMLElement, EntClientView> {
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntClientView){
            view.sub(K.nav).compPageTitle { it.setTitle(entity.viewTitle) }
            intervalId = window.setInterval({
                eLaunch{
                    N.view(entity.viewRowid)?.also {res->
                        uploadCheckClearInterval()
                        entity.setData(res)
                        view.entity(model)
                    }
                }
            }, 5000)
            view.sub(K.refreshDate){
                val refreshDate = eUtc.now().toLocalDateTime(M42ApiNet.timeZone).format("yyyy-mm-dd(WK) HH24:MM:SS")
                it.attr("v0" to refreshDate)
                it.html = "r@갱신일 : $refreshDate@5/6/u"
            }
            view.sub(K.empty){
                if(entity.cat2List.isEmpty()) it.displayBlock() else it.displayNone()
            }
            view.sub(K.cat2List){
                if(entity.cat2List.isEmpty()) it.displayNone() else it.displayBlock()
                it.setClearList{ listView->
                    entity.cat2List.forEach{ cat2Data->
                        listView += eView(cat2Factory) { cat2View ->
                            cat2View.sub(K.cat2_title).html = cat2Data.title
                            cat2View.sub(K.cat2_itemList).setClearList { listView->
                                cat2Data.viewItemList.forEach { item->
                                    listView += eView(cat2ItemFactory){ itemView->
                                        itemView.sub(K.item_title).html = item.title
                                        itemView.sub(K.item_value){ value ->
                                            value.className = if(item.hasEvent) "h2 font-color-red" else "h2"
                                            value.html = item.value
                                        }
                                        itemView.sub(K.item_eventBtn){ eventBtnView->
                                            ALink(RouterKey.EVENT, mutableMapOf("r" to item.cat2ItemRowid, "dr" to item.viewDecoratorRowid)).href(eventBtnView)
                                            eventBtnView.attr("v0" to item.eventCnt)
                                            eventBtnView.html = if(item.eventCnt == 0) "r@Evt@5/4/h" else "r@Evt (${item.eventCnt})@5/4/g"
                                        }
                                        CompLog(itemView, K.item_logBtn){ compLog ->
                                            compLog.cat2ItemRowid = item.cat2ItemRowid
                                            compLog.isActive = item.isLogActive
                                            compLog.viewDecoratorRowid = item.viewDecoratorRowid
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    })
}