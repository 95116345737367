package router.log

import app.*
import comp.CompPageTitle
import comp.compPagination
import comp.compPaginationSet
import comp.input.compToggleRadio
import comp.input.compToggleRadioSet
import compLabelInputSectionNumberSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import entity.EntClientLog
import enum.EnumClientLogOnOff
import frm.api.EntApiCat2ItemLogE
import frm.vali.ValiLogStorageDay
import m42.app.M42App
import m42.model.HcInit
import org.w3c.dom.HTMLElement
import prop.compLabelInputSectionNumber
import router.subView.subCatInfo
import view.CompViewToast

private val factory = Factory.htmlUrl("log")
private val itemFactory = Factory.html("""<div data-view=""></div>""")
private enum class K{ catInfo, logMaxStorageComp, storageDayEpBtn, isLogging, empty, list, page }
suspend fun LogView() = eView(factory){ staticView ->
    val model = EntClientLog()
    CompPageTitle(staticView){
        it.title = "r@로그@5/7/7"
        it.backClick = { eLaunch{ hashManager.back() } }
    }

    subCatInfo(staticView, K.catInfo)
    staticView.compLabelInputSectionNumberSet(K.logMaxStorageComp, "r@최대 보관 일자@5/7/c", ValiLogStorageDay, sectionWrapperClass=""){}
    staticView.compToggleRadioSet<Boolean>(K.isLogging){ comp ->
        comp.wrapperClass = "toggle-list"
        comp.checkBlock = { idx ->
            val v = comp.itemList[idx].value
            eLaunch{
                N.edit(model.catInfo!!.cat2ItemRowid, v)?.also{
                    CompViewToast.open("r@로그 활성 상태가 변경되었습니다.@5/7/b")
                    staticView.entity(it)
                }
            }
        }
    }
    M42App.emptySub(staticView, K.empty, K.list, K.storageDayEpBtn)
    staticView.compPaginationSet(K.page, "page"){}
    staticView.addEntityHook(HcInit::class, object:eEntityHook<HTMLElement, HcInit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:HcInit){
            view.clearCompValue(K.isLogging)
            model.init()
        }
    })
    staticView.addEntityHook(EntApiCat2ItemLogE.Res::class, object:eEntityHook<HTMLElement, EntApiCat2ItemLogE.Res>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntApiCat2ItemLogE.Res){
            model.setData(entity)
            view.entity(model)
        }
    })
    staticView.addEntityHook(EntClientLog::class, object: eEntityHook<HTMLElement, EntClientLog>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntClientLog){
            entity.catInfo?.also{ view.sub(K.catInfo).entity(it) }
            view.sub(K.logMaxStorageComp).compLabelInputSectionNumber().inputValue(entity.logMaxStorageDay.toString())
            view.sub(K.storageDayEpBtn){ btn ->
                btn.html = "r@저장@5/7/d"
                btn.click = {_,_->
                    eLaunch{
                        val comp = view.sub(K.logMaxStorageComp).compLabelInputSectionNumber()
                        if(comp.check()){
                            N.editMaxStorageDay(model.catInfo!!.cat2ItemRowid, comp.out().toInt())?.also{
                                CompViewToast.open("r@최대 보관 일자가 변경되었습니다.@5/7/e")
                                staticView.entity(it)
                            }
                        }
                    }
                }
            }

            view.sub(K.isLogging).compToggleRadio<Boolean>{ comp ->
                comp.setList{
                    EnumClientLogOnOff.entries.map{ enum ->
                        comp.item(enum.title, enum.value, entity.isLogging == enum.value)
                    }
                }
            }

            val isEmpty = entity.list.isEmpty()
            view.sub(K.empty){
                if(isEmpty) it.displayBlock() else it.displayNone()
            }
            view.sub(K.list){ listView ->
                if(isEmpty) listView.displayNone() else listView.displayBlock()
                listView.setClearList{ listListView ->
                    entity.list.forEach{ logData ->
                        listListView += eView(itemFactory){
                            it.html = App.logDate(logData.value, logData.regDate)
                        }
                    }
                }
            }
            view.sub(K.page).compPagination{comp->
                comp.pageLink = ALink(
                    RouterKey.LOG,
                    mutableMapOf("r" to entity.catInfo!!.cat2ItemRowid, "dr" to entity.viewDecoratorRowid), "p"
                )
                comp.setPage(entity.meta.totalRows.toLong(), entity.meta.page.toLong(), entity.meta.rowPerPage.toLong(), entity.meta.pagePerGroup.toLong())
            }
        }
    })
}