package frm.api

import ein2b.core.entity.eEntity
import frm.model.*
import m42.common.enum.EnumApiAccessAllow

object EntApiViewW:EntFrmApi<EntApiViewW.Req, EntApiViewW.Res>(Res::class, ::Req, ::Res){
    const val PATH = "${API_VIEW_PATH}/w"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow = EnumApiAccessAllow.LOGIN
    class Req: eEntity(){
        var cat1Rowid by long
    }
    class Res:eEntity(){
        var cat1Rowid by long
        var cat1Title by string
        var cat2List by entityList(::MdlCat2)
        var viewDecoratorList by entityList(::MdlViewDecorator)
    }
}

object EntApiViewE:EntFrmApi<EntApiViewE.Req, EntApiViewE.Res>(Res::class, ::Req, ::Res){
    const val PATH = "${API_VIEW_PATH}/e"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow = EnumApiAccessAllow.LOGIN
    class Req: eEntity(){
        var viewRowid by long
    }
    class Res:eEntity(){
        var cat1Title by string
        var view by entity(::MdlView)
        var cat2List by entityList(::MdlCat2)
        var viewDecoratorList by entityList(::MdlViewDecorator)
    }
}
object EntApiViewWp:EntFrmApi<MdlViewAddParam, EntApiViewWp.Res>(Res::class, ::MdlViewAddParam, EntApiViewWp::Res){
    const val PATH = "${API_VIEW_PATH}/wp"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow = EnumApiAccessAllow.LOGIN
    override var isMenu = true
    class Res:eEntity(){
        var viewRowid by long
    }
}

object EntApiViewEp:EntFrmApi<MdlViewEditParam, EntApiViewEp.Res>(Res::class, ::MdlViewEditParam, EntApiViewEp::Res){
    const val PATH = "${API_VIEW_PATH}/ep"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow = EnumApiAccessAllow.LOGIN
    override var isMenu = true
    class Res:eEntity(){
        var viewRowid by long
    }
}

object EntApiViewV:EntFrmApi<EntApiViewV.Req, EntApiViewV.Res>(Res::class, ::Req, ::Res){
    const val PATH = "${API_VIEW_PATH}/v"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow = EnumApiAccessAllow.LOGIN
    class Req: eEntity(){
        var viewRowid by long
    }
    class Res:eEntity(){
        var cat2List by entityList(::MdlCat2)
        var view by entity(::MdlView)
        var rateCatMin by int //리프레쉬 주기 (분)
        var viewDecoratorList by entityList(::MdlViewDecorator)
    }
}