package router.storage

import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import m42.common.model.MdlM42ApiRowid
import m42.domBase.PageBase
import org.w3c.dom.HTMLElement

class StorageP(router:eRouter<HTMLElement>):PageBase(::StorageView, router){
    override suspend fun net(data:eRouterData){
        data.parse(MdlM42ApiRowid())?.also {r->
            N.editReady(r.r.toLong())?.also{
                view?.entity(it)
            }
        }

    }
}