package comp

import app.ALink
import app.Factory
import ein2b.core.core.ePage
import ein2b.core.core.err
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import org.w3c.dom.HTMLElement
/* *********** CompPagination 사용법

val page = view.compPagination("page", "page"){
        it.totalRows = 100          // 전체 로우
        it.currentPage = 2          // 현재 페이지
        it.rpp = 20                 // 페이지당 로우 수
        it.ppg = 10                 // 보여줄 최대 페이지 수
        it.clickEvent = {           // 페이지 클릭했을 때
            console.log(it)
        }
}

위의 예시에서 next 버튼이 비활성화 된다.
보통 이닛시점엔 페이지를 모르기 때문에 만들어놓고 setPage를 쓴다.
page.setPage(200, 33, 10, 5)

* */
suspend fun eView<HTMLElement>.compPaginationSet(subKey:Any, wrapperClass:String = "", block:(CompPagination)->Unit):CompPagination{
    return CompPagination(sub(subKey, CompPagination.factory, null), wrapperClass, block)
}
class CompPagination private constructor(private val view:eView<HTMLElement>):Comp{
    companion object{
        private const val WRAPPER = "CompPagination_wrapper"
        private const val PREV = "CompPagination_prev"
        private const val NEXT = "CompPagination_next"
        private const val PAGE_LIST = "CompPagination_page_list"
        private const val prevClass = "page-prev"
        private const val nextClass = "page-next"
        private const val disabledClass = "disabled"
        private const val pageClass = "page-list-page"
        private const val selectedClass = "selected"
        internal val factory = Factory.html("""
<div data-view="$WRAPPER">
    <a data-view="$PREV" class="alink $prevClass"></a>
    <div data-view="$PAGE_LIST" class="page-list"></div>
    <a data-view="$NEXT" class="alink $nextClass"></a>
</div>""")
        internal val pageFactory = Factory.html("""<a data-view="" class="alink"></a>""")
        suspend operator fun invoke(view:eView<HTMLElement>, wrapperClass:String = "", block:(CompPagination)->Unit):CompPagination{
            val comp = CompPagination(view)
            block(comp)
            comp.pageLink.addArgKey = comp.argKey
            if(wrapperClass.isNotBlank()) view.sub(WRAPPER).className = wrapperClass
            comp.setPage(comp.totalRows, comp.currentPage, comp.rpp, comp.ppg)
            comp.baseProp(view)
            return comp
        }
    }
    var totalRows = 1L
    var currentPage = 1L
    var rpp = 1L
    var ppg = 1L
    var argKey = "p"
    var pageLink = ALink().also{ it.addArgKey = argKey }
    /** clickEvent를 걸면 a Href가 아니게 됨 */
    var clickEvent:(suspend (Long)->Unit)? = null

    suspend fun setPage(totalRowsV:String, currentPageV:String, rppV:String, ppgV:String){
        setPage(totalRowsV.toLong(), currentPageV.toLong(), rppV.toLong(), ppgV.toLong())
    }
    suspend fun setPage(totalRowsV:Long, currentPageV:Long, rppV:Long, ppgV:Long){
        totalRows = totalRowsV
        currentPage = currentPageV
        rpp = rppV
        ppg = ppgV

        val currGroup = ePage.currGroup(currentPage, totalRows, rpp, ppg)
        val prevAndNextPage = ePage.prevAndNextPage(currentPage, totalRows, rpp, ppg)
        val totalGroup = ePage.totalGroup(totalRows, rpp, ppg)
        val pageListInGroup = ePage.pageListInGroup(currentPage, totalRows, rpp, ppg)

        view.sub(PREV){ prevView ->
            prevView.disabled = currGroup == 1L
            prevView.className = "alink $prevClass${if(prevView.disabled == true) " $disabledClass" else ""}"
            if(prevView.disabled == false){
                val prevPage = prevAndNextPage.first
                clickEvent?.also{
                    prevView.click = { _,_-> eLaunch{ it(prevPage) } }
                } ?:also {
                    pageLink.href(prevView, prevPage)
                }
            }
        }
        view.sub(NEXT){ nextView ->
            nextView.disabled = currGroup == totalGroup
            nextView.className = "alink $nextClass${if(nextView.disabled == true) " $disabledClass" else ""}"
            if(nextView.disabled == false){
                val nextPage = prevAndNextPage.second
                clickEvent?.also{
                    nextView.click = { _,_-> eLaunch{ it(nextPage) } }
                } ?:also {
                    pageLink.href(nextView, nextPage)
                }
            }
        }
        view.sub(PAGE_LIST).setClearList{
            pageListInGroup.forEach{ page ->
                it += eView(pageFactory){ pageView ->
                    pageView.html = "$page"
                    pageView.className = "alink $pageClass${if(page == currentPage) " $selectedClass" else ""}"
                    clickEvent?.also{
                        pageView.click = { _,_-> eLaunch{ it(page) } }
                    } ?:also {
                        pageLink.href(pageView, page)
                    }
                }
            }
        }
    }
    suspend fun displayBlock(){
        view.sub(WRAPPER).displayBlock()
    }
    suspend fun displayNone(){
        view.sub(WRAPPER).displayNone()
    }
}

// ============================ prop ============================
inline fun eView<HTMLElement>.compPagination(block:(CompPagination)->Unit = { }):CompPagination{
    val comp = this["compPagination"] as? CompPagination ?: err("fail to get compPagination")
    block(comp)
    return comp
}














































