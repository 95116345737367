package router.storage

import app.*
import frm.api.EntApiCat1E
import frm.api.EntApiCat1Wp
import frm.api.EntApiStorageConfigEp
import frm.api.member.EntApiMemberSessionCheck
import frm.model.MdlCat1Param
import frm.model.MdlStorageConfigEpParam
import m42.common.api.EntM42Api.Companion.ENTITY_KEY

object N{
    suspend fun editReady(cat1Rowid:Long): EntApiStorageConfigEp.Res? = ClientApiStorageConfigE.net{req->
        req.cat1Rowid = cat1Rowid
    }
    suspend fun edit(param: MdlStorageConfigEpParam):EntApiStorageConfigEp.Res? = ClientApiStorageConfigEp.net(ENTITY_KEY to { param })
}