package frm.enum

import ein2b.core.entity.field.EnumRowid

enum class EnumRateCat(val min:Int):EnumRowid<Int>{
    MIN_1(1){
        override val rowid:Int
            get() = 1
    },
    MIN_5(5){
        override val rowid:Int
            get() = 5
    },
    MIN_10(10){
        override val rowid:Int
            get() = 10
    },
    MIN_30(30){
        override val rowid:Int
            get() = 30
    },
    MIN_60(60){
        override val rowid:Int
            get() = 60
    };
}