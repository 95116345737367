package router.login

import app.Factory
import app.RouterKey
import app.hashManager
import ein2b.core.view.eEntityHook
import ein2b.core.view.eView
import frm.api.member.EntApiMemberRsa
import m42.model.HcInit
import org.w3c.dom.HTMLElement
import router.subView.subLogin

private val factory = Factory.htmlUrl("login")
private enum class K{ login }
suspend fun LoginView() = eView(factory){ staticView->
    subLogin(staticView, K.login){ hashManager.goUrl(RouterKey.HOME) }

    staticView.addEntityHook(HcInit::class, object:eEntityHook<HTMLElement, HcInit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:HcInit){
            view.sub(K.login).entity(entity)
        }
    })
    staticView.addEntityHook(EntApiMemberRsa.Res::class, object:eEntityHook<HTMLElement, EntApiMemberRsa.Res>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntApiMemberRsa.Res){
            view.sub(K.login).entity(entity)
        }
    })
}