package router.cat1Add

import app.*
import frm.api.EntApiCat1Wp
import frm.api.member.EntApiMemberSessionCheck
import frm.model.MdlCat1Param
import m42.common.api.EntM42Api.Companion.ENTITY_KEY

object N{
    suspend fun addReady(): EntApiMemberSessionCheck.Res? = ClientApiMemberSessionCheck.net()
    suspend fun add(param:MdlCat1Param):EntApiCat1Wp.Res? = ClientApiCat1Wp.net(ENTITY_KEY to { param })

}