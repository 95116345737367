package router.hex2Type

import app.*
import frm.enum.EnumTypeCat

object N{
    suspend fun get(p:Long) = ClientApiHex2TypeE.net{ req ->
        req.p = p
    }
    suspend fun edit(title:String, js:String, typeCat:EnumTypeCat, rowid:Long=0L) = ClientApiHex2TypeEp.net{req->
        req.title = title
        req.js = js
        req.hex2TypeRowid = rowid
        req.typeCat = typeCat
    }
}