package comp.input

import app.Factory
import comp.OutType
import ein2b.core.core.err
import ein2b.core.validation.eVali
import ein2b.core.view.*
import org.w3c.dom.HTMLElement

class CompOneRadio:CompInput<Boolean, Boolean, Boolean>{
    companion object{
        enum class K{ wrap }
        operator fun invoke(block:(CompOneRadio)->Unit):CompOneRadio{
            val comp = CompOneRadio()
            block(comp)
            comp.value = CompValue(comp.initValue, comp.initValue, comp.vali, comp.errorListener, { it }){ comp.target.value = it }
            return comp
        }
    }

    override val outs:HashMap<OutType, suspend () -> Boolean> = hashMapOf(OutType.DEFAULT to { value.value })
    override lateinit var value:CompValue<Boolean, Boolean>
    var initValue = false
    override var errorListener:((Boolean, String)->Unit)? = null
    override var vali:eVali? = null
    override var placeholder = ""
    override var tabIndex = -2
    override val factory:suspend ()-> HTMLElement = Factory.html("""<div data-view="${K.wrap}" class="radio"></div>""")

    private lateinit var target:eView<HTMLElement>
    var isDisabled = false
    var checkBlock:(()->Unit)? = null
    override suspend fun init(it:eView<HTMLElement>){
        target = it.sub(K.wrap){
            it.className = className(initValue)
            it.click = { _, _->
                if(!isDisabled){
                    inputValue(true)
                    checkBlock?.invoke()
                }
            }
        }
        inputValue(initValue)
    }
    override suspend fun error(isOk:Boolean){}
    override suspend fun clear(){
        value.isOk = true
        isDisabled = false
        inputValue(initValue)
    }
    fun inputValue(v:Boolean){
        value.inputValue(v)
        value.check()
        target.className = className(v)
    }
    private fun className(v:Boolean) = "radio" + (if(isDisabled) " disabled" else "") + (if(v) " selected" else "")
    override suspend fun displayNone() = target.displayNone()
    override suspend fun displayBlock() = target.displayBlock()
    override suspend fun displayInlineBlock() = target.displayInlineBlock()
    override suspend fun displayFlex() = target.displayFlex()
}

// ============================ prop ============================
suspend fun eView<HTMLElement>.compOneRadioSet(subKey:Any, block:(CompOneRadio)->Unit = {}){
    CompOneRadio{ block(it) }.also{
        it.comp(this, subKey)
    }
}
inline fun eView<HTMLElement>.compOneRadio(block:(CompOneRadio)->Unit = { }):CompOneRadio{
    val comp = this["compOneRadio_value"] as? CompOneRadio ?: err("fail to get CompOneRadio")
    block(comp)
    return comp
}