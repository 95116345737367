package frm.api

import ein2b.core.entity.eEntity
import frm.model.*
import m42.common.enum.EnumApiAccessAllow

object EntApiCat1Wp:EntFrmApi<MdlCat1Param, EntApiCat1Wp.Res>(Res::class, ::MdlCat1Param, ::Res){
    const val PATH = "${API_CAT1_PATH}/wp"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow = EnumApiAccessAllow.LOGIN
    override var isMenu = true
    class Res:eEntity(){
        var cat1Rowid by long
    }
}
object EntApiCat1E:EntFrmApi<EntApiCat1E.Req, EntApiCat1E.Res>(Res::class, ::Req, ::Res){
    const val PATH = "${API_CAT1_PATH}/e"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow = EnumApiAccessAllow.LOGIN
    class Req:eEntity(){
        var cat1Rowid by long
    }
    class Res:eEntity(){
        var cat1 by entity(::MdlCat1)
        var hex2TypeList by entityList(::MdlHex2Type)
    }
}
object EntApiCat1Ep:EntFrmApi<MdlCat1EpParam, EntApiCat1E.Res>(EntApiCat1E.Res::class, ::MdlCat1EpParam, EntApiCat1E::Res){
    const val PATH = "${API_CAT1_PATH}/ep"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow = EnumApiAccessAllow.LOGIN
    override var isMenu = true
}