package entity

import app.App
import ein2b.core.entity.Union
import ein2b.core.entity.eEntity
import frm.api.EntApiEventV
import frm.enum.EnumEventCat
import frm.model.MdlCat2ItemEvent
import frm.model.MdlCat2ItemEventCmd
import frm.model.MdlCat2ItemEventMeta
import m42.model.ViewModel

class EntClientEventUrl:eEntity(){
    var r by string
    var dr by string{ default("0") }
    var cmdR by string{ default("0") }
    var p by string{ default("1") }
}
sealed class EntClientEventCat:eEntity(){
    companion object:Union<EntClientEventCat>(EntClientEventCat::One, EntClientEventCat::Two)
    var isForm = true
    var isSelected = false
    var isOk = false
    var cat by enum<EnumEventCat>()
    class One:EntClientEventCat(){
        var condition by string{ default("") }
        override fun init(){
            isSelected = false
            condition = ""
        }
        override fun setData(v:String){
            isSelected = true
            condition = v
        }
    }
    class Two:EntClientEventCat(){
        var condition1 by string{ default("") }
        var condition2 by string{ default("") }
        override fun init(){
            isSelected = false
            condition1 = ""
            condition2 = ""
        }
        override fun setData(v:String){
            isSelected = true
            if(v.isNotBlank()){
                val list = v.split(",")
                condition1 = list.first()
                condition2 = list.last()
            }else{
                condition1 = ""
                condition2 = ""
            }
        }
    }
    abstract fun init()
    abstract fun setData(v:String)
}

class EntClientEvent:ViewModel(){
    class Event:eEntity(){
        var cmd by entity(::MdlCat2ItemEventCmd)
        var cat by union(EntClientEventCat)
    }
    class EventDetail:eEntity(){
        var cat2ItemEventCmdRowid by long
        var title by string
        var isSelected by boolean
    }
    var meta = MdlCat2ItemEventMeta.DEFAULT
    var viewDecoratorRowid = "0"  //데코레이터 로우아디
    var catInfo = EntClientCatInfo.DEFAULT
    var list = listOf<Event>()
    var eventList = listOf<EventDetail>()
    var eventLogList = listOf<MdlCat2ItemEvent>()
    var form = Form()
    class Form:eEntity(){
        var isOpen = false
        var isAdd = true
        var cat2ItemEventCmdRowid = 0L
        var cat10 = EntClientEventCat.One().also{ it.cat = EnumEventCat.LESS_THAN_VALUE }
        var cat20 = EntClientEventCat.One().also{ it.cat = EnumEventCat.GREATER_THAN_VALUE }
        var cat30 = EntClientEventCat.Two().also{ it.cat = EnumEventCat.WITHIN_THE_RANGE }
        var cat40 = EntClientEventCat.Two().also{ it.cat = EnumEventCat.EXCLUDE_THE_RANGE }
        var cat50 = EntClientEventCat.One().also{ it.cat = EnumEventCat.IN }
        var cat60 = EntClientEventCat.One().also{ it.cat = EnumEventCat.NOT_EQUAL }
        var eventCat:EnumEventCat? = null //수정인 경우 카테고리를 선택하면 기존값 넣어줄때 사용
        var condition = ""
        var period = ""
        var executePeriod = "1"
        var emailList = ""
        var message = ""
        val catList = listOf(cat10, cat20, cat30, cat40, cat50, cat60)
        fun init(){
            isOpen = false
            isAdd = true
            eventCat = null
            condition = ""
            period = ""
            emailList = ""
            message = ""
            catList.forEach{ it.init() }
        }
        fun setCat(cat:EntClientEventCat){
            catList.forEach{ it.init() }
            catList.find{ it.cat == cat.cat }?.also{
                it.setData(if(it.cat == eventCat) condition else "")
            }
        }
        fun setData(cmd:MdlCat2ItemEventCmd){
            isOpen = true
            isAdd = false
            cat2ItemEventCmdRowid = cmd.cat2ItemEventCmdRowid
            eventCat = cmd.eventCat
            condition = cmd.condition
            period = "${cmd.period}"
            executePeriod = "${cmd.executionPeriod}"
            emailList = cmd.emailList
            message = cmd.message
            catList.find{ it.cat == eventCat }?.setData(condition)
        }
    }
    fun init(){
        meta = MdlCat2ItemEventMeta.DEFAULT
        catInfo = EntClientCatInfo.DEFAULT
        list = listOf()
        form.init()
        eventList = listOf()
        eventLogList = listOf()
        viewDecoratorRowid = "0"
    }
    fun setData(target:EntApiEventV.Res){
        viewDecoratorRowid = target.viewDecoratorRowid
        meta = target.meta
        catInfo = EntClientCatInfo().also{ it.setData(target.cat1.title, target.cat2.title, target.cat2Item.also { cat2Item ->
            cat2Item.value = App.getConverterStr(viewDecoratorRowid, cat2Item.hex2TypeRowid.toString(), cat2Item.value)
        })}
        list = target.cat2ItemEventCmdList.map{ eventCmd ->
            Event().also{ event ->
                val cat = eventCmd.eventCat
                event.cmd = eventCmd
                event.cat = when(cat){
                    EnumEventCat.LESS_THAN_VALUE,EnumEventCat.GREATER_THAN_VALUE,EnumEventCat.IN,EnumEventCat.NOT_EQUAL -> EntClientEventCat.One().also{
                        it.isForm = false
                        it.cat = cat
                        it.setData(event.cmd.condition)
                    }
                    EnumEventCat.WITHIN_THE_RANGE,EnumEventCat.EXCLUDE_THE_RANGE -> EntClientEventCat.Two().also{
                        it.isForm = false
                        it.cat = cat
                        it.setData(event.cmd.condition)
                    }
                }
            }
        }
        eventList = mutableListOf(
            EventDetail().also{
                it.cat2ItemEventCmdRowid = 0
                it.title = "r@전체@5/8/q"
                it.isSelected = true
            }
        ) + list.map{ event ->
            EventDetail().also{ eventDetail ->
                eventDetail.cat2ItemEventCmdRowid = event.cmd.cat2ItemEventCmdRowid
                eventDetail.title = "${event.cmd.cat2ItemEventCmdRowid} : ${event.cmd.condition}"
                eventDetail.isSelected = target.meta.cat2ItemEventCmdRowid == event.cmd.cat2ItemEventCmdRowid
            }
        }
        eventLogList = target.cat2ItemEventList.onEach {
            it.value = App.getConverterStr(viewDecoratorRowid, target.cat2Item.hex2TypeRowid.toString(), it.value)
        }
    }
}