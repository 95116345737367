package router.storage

import app.CompApp
import app.Factory
import app.hashManager
import comp.CompPageTitle
import comp.CompSelectAndInput
import comp.compInputSectionOneSet
import comp.input.*
import compLabelInputSectionNumberSet
import compLabelInputSectionSelectSet
import compLabelInputSectionTextSet
import compLabelInputSectionToggleRadioSet
import compLabelInputSelectSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.date.eUtc
import ein2b.core.entity.eEntity
import ein2b.core.view.*
import entity.EntClientStorage
import enum.EnumClientLogOnOff
import enum.EnumClientOnOff
import frm.api.EntApiStorageConfigEp
import frm.enum.EnumRateCat
import frm.enum.EnumStorageCat
import frm.model.MdlS3Config
import frm.model.MdlStorageConfig
import frm.model.MdlStorageConfigEpParam
import frm.vali.*
import m42.app.M42App
import m42.model.HcInit
import m42.model.HcJsonData
import org.w3c.dom.HTMLElement
import prop.compInputSection
import prop.compLabelInputSection
import prop.compLabelInputSectionText
import prop.compLabelInputSelect
import view.CompViewToast

private val factory = Factory.htmlUrl("storage/storage")
//language=html
private val catFactory = Factory.html("""<div data-view="" class="btn border margin-right12"></div>""")
private val configFactory = Factory.htmlUrl("storage/config")

private enum class K{
    saveBtn, cat1Title, catList, configList,
     storageCat, rateCat, accessKey, secretKey, bucket, region, prefix, logMaxStorageComp, isActive, isDelete, lastUpdatedWrapper, lastUpdated;
    override fun toString() = if ("_" in name) name.substring(name.lastIndexOf("_") + 1) else name
}
suspend fun StorageView() = eView(factory){ staticView->
    val model = EntClientStorage()
    CompPageTitle(staticView){
        it.title = "r@수집기 편집@5/3/D"
        it.backClick = { eLaunch{ hashManager.back() } }
    }
    M42App.emptySub(staticView, K.saveBtn, K.cat1Title)
    staticView.sub(K.catList).setClearList{ catListView ->
        EnumStorageCat.entries.forEach{ cat ->
            catListView += eView(catFactory){
                it.html = "+ ${cat.title}"
                it.click = { _,_->
                    model.addForm(cat)
                    eLaunch { staticView.entity(model) }
                }
            }
        }
    }
    staticView.sub(K.configList)
    staticView.addEntityHook(HcInit::class, object: eEntityHook<HTMLElement, HcInit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:HcInit){
            model.init()
            view.entity(model)
        }
    })
    staticView.addEntityHook(EntApiStorageConfigEp.Res::class, object: eEntityHook<HTMLElement, EntApiStorageConfigEp.Res>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntApiStorageConfigEp.Res){
            model.setData(entity)
            view.entity(model)
        }
    })
    suspend fun configListView(subView:eView<HTMLElement>, configList:MutableList<EntClientStorage.EntStorageConfig>){
        if(configList.isEmpty()) subView.displayNone() else subView.displayBlock()
        subView.setClearList { listView ->
            configList.forEachIndexed {idx, config->
                listView += eView(configFactory){itemView->
                    config.view = itemView
                    itemView.compLabelInputSectionSelectSet(K.storageCat, "r@저장소 종류@5/3/10", isInline = true){comp->
                        comp.wrapperClass = "selectbox-border width3-1"
                        comp.afterInited = {
                            comp.setList(*EnumStorageCat.entries.map {
                                comp.item(it.name, it, it == config.storageCat)
                            }.toTypedArray())
                        }
                        comp.isDisabled = true
                    }
                    itemView.compLabelInputSectionSelectSet<EnumRateCat>(K.rateCat, "r@주기@5/3/H", isInline = true){comp->
                        comp.wrapperClass = "selectbox-border width3-1"
                        comp.afterInited = {
                            comp.setList(*EnumRateCat.entries.map {
                                comp.item(it.name, it, it == config.rateCat)
                            }.toTypedArray())
                        }

                        comp.vali = comp.singleRule("r@주기를 선택해 주세요.@5/3/I")
                        comp.placeholder = "r@주기를 선택해 주세요.@5/3/J"
                    }
                    itemView.compLabelInputSectionTextSet(K.accessKey, "r@accessKey@5/3/K", ValiS3AccessKey, true).also {comp->
                        comp.inputValue(config.accessKey)
                    }
                    itemView.compLabelInputSectionTextSet(K.secretKey, "r@secretKey@5/3/L", ValiS3SecretKey, true).also {comp->
                        comp.inputValue(config.secretKey)
                    }
                    itemView.compLabelInputSectionTextSet(K.bucket, "r@bucket@5/3/M", ValiS3Bucket, true).also {comp->
                        comp.inputValue(config.bucket)
                    }
                    itemView.compLabelInputSectionTextSet(K.region, "r@region@5/3/N", ValiS3Region, true).also{comp->
                        comp.inputValue(config.region)
                    }
                    itemView.compLabelInputSectionTextSet(K.prefix, "r@prefix@5/3/O", ValiS3Prefix, true).also { comp->
                        comp.inputValue(config.prefix)
                    }
                    itemView.compInputSectionOneSet<Number>(
                        K.logMaxStorageComp,
                        CompInputNumber{ }
                    ).also {it.inputView().compInputNumber().value.inputValue(config.logMaxStorageDay.toString())}
                    itemView.compToggleRadioSet(K.isActive){ comp ->
                        comp.wrapperClass = "toggle-list"
                        comp.checkBlock = { idx ->
                            val v = comp.itemList[idx].value
                            eLaunch{
                                config.isActive = v
                            }
                        }
                        comp.afterInited = {
                            comp.setList {
                                EnumClientOnOff.entries.map{ enum ->
                                    comp.item(enum.title, enum.value, config.isActive == enum.value)
                                }
                            }
                        }
                    }
                    itemView.compToggleRadioSet(K.isDelete){ comp ->
                        comp.wrapperClass = "toggle-list"
                        comp.checkBlock = { idx ->
                            val v = comp.itemList[idx].value
                            eLaunch{
                                config.isDelete = v
                            }
                        }
                        comp.afterInited = {
                            comp.setList {
                                EnumClientOnOff.entries.map{ enum ->
                                    comp.item(enum.title, enum.value, config.isDelete == enum.value)
                                }
                            }
                        }
                    }
                    itemView.sub(K.lastUpdatedWrapper){
                        if(config.lastUpdated=="") it.displayNone() else it.displayFlex()
                    }
                    itemView.sub(K.lastUpdated).html = config.lastUpdated
                }
            }
        }
    }
    suspend fun checkStorageConfig(storageAddParam: MdlStorageConfigEpParam, configList:MutableList<EntClientStorage.EntStorageConfig>):Boolean{
        var isCheck = true
        storageAddParam.storageConfigList = mutableListOf()
        configList.forEach { config->
            val s3 = MdlS3Config()
            val configParam = MdlStorageConfig()
            config.view?.also {cView->
                cView.sub(K.rateCat).compLabelInputSection<EnumRateCat>{
                    if(it.check()) configParam.rateCat = it.out() else isCheck = false
                }
                cView.sub(K.storageCat).compLabelInputSection<EnumStorageCat>{
                    if(it.check()) configParam.storageCat = it.out() else isCheck = false
                }
                cView.sub(K.accessKey).compLabelInputSectionText{
                    if(it.check()) s3.accessKey = it.out() else isCheck = false
                }
                cView.sub(K.secretKey).compLabelInputSectionText{
                    if(it.check()) s3.secretKey = it.out() else isCheck = false
                }
                cView.sub(K.region).compLabelInputSectionText{
                    if(it.check()) s3.region = it.out() else isCheck = false
                }
                cView.sub(K.bucket).compLabelInputSectionText{
                    if(it.check()) s3.bucket = it.out() else isCheck = false
                }
                cView.sub(K.prefix).compLabelInputSectionText{
                    if(it.check()) s3.prefix = it.out() else isCheck = false
                }
                cView.sub(K.logMaxStorageComp).compInputSection().inputView().compInputNumber{
                    if(it.check()) configParam.logMaxStorageDay = it.out().toInt() else isCheck = false
                }
                cView.sub(K.isActive).compToggleRadio{
                    it.check()
                    configParam.isActive = it.out()
                }
                cView.sub(K.isDelete).compToggleRadio{
                    it.check()
                    configParam.isDelete = it.out()
                }
            }
            configParam.also { p->
                p.cat1Rowid = storageAddParam.cat1Rowid
                p.storageConfigRowid = config.configRowid
                p.data = s3.stringify()
            }
            storageAddParam.storageConfigList.add(configParam)
        }
        return isCheck
    }
    staticView.addEntityHook(EntClientStorage::class, object:eEntityHook<HTMLElement, EntClientStorage>{
        override suspend fun invoke(view: eView<HTMLElement>, entity:EntClientStorage){
            view.sub(K.cat1Title).html = entity.cat1Title
            view.sub(K.configList) {
                configListView(it, entity.configList)
            }
            view.sub(K.saveBtn).click = {_,_->
                val configParam = MdlStorageConfigEpParam().also {
                    it.cat1Rowid = entity.cat1Rowid
                }
                eLaunch {
                    val result = if(entity.configList.isEmpty()) false else checkStorageConfig(configParam, entity.configList)
                    if(result){
                        N.edit(configParam)?.also{
                            CompViewToast.setLocalStorage("r@수집기가 저장되었습니다.@5/3/Z")
                            M42App.reload()
                        }
                    }
                }

            }
        }
    })
}