package router.event

import app.CompRouter
import app.RouterKey
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import entity.EntClientEventUrl
import m42.app.M42App
import m42.domBase.Channel
import m42.domBase.PageBase
import org.w3c.dom.HTMLElement

class EventP(router:eRouter<HTMLElement>):PageBase(::EventView, router){
    override suspend fun net(data:eRouterData){
        data.parse(EntClientEventUrl())?.also{ ent ->
            N.get(ent.r.toLong(), ent.cmdR.toLong(), ent.p.toLong())?.also{ res ->
                res.viewDecoratorRowid = ent.dr
                view?.entity(res)
            }
        } ?: M42App.routerError()
    }
    init{
        addChannel(Channel.RELOAD){ type, v ->
            when(type){
                Channel.RELOAD -> {
                    if(v is String && v == RouterKey.EVENT){
                        eLaunch{
                            val aLink = CompRouter.backLink(RouterKey.EVENT)
                            aLink.args["r"]?.also{ r ->
                                val ent = EntClientEventUrl().also{
                                    it.r = "$r"
                                    aLink.args["cmdR"]?.also{ cmdR -> it.cmdR = "$cmdR" }
                                    aLink.args["p"]?.also{ p -> it.p = "$p" }
                                }
                                N.get(ent.r.toLong(), ent.cmdR.toLong(), ent.p.toLong())?.also{ res ->
                                    view?.entity(res)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}