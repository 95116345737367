package frm.api

import ein2b.core.entity.eEntity
import frm.model.MdlViewDecorator
import m42.common.enum.EnumApiAccessAllow
import m42.common.model.MdlM42ApiPageMeta

object EntApiViewDecoratorE:EntFrmApi<EntApiViewDecoratorE.Req, EntApiViewDecoratorE.Res>(Res::class, ::Req, ::Res){
    const val PATH = "${API_VIEWDECORATOR_PATH}/e"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow = EnumApiAccessAllow.LOGIN
    class Req:eEntity(){
        var p by long{ default(1) }
    }
    class Res:eEntity(){
        var meta by entity(::MdlM42ApiPageMeta)
        var viewDecoratorList by entityList(::MdlViewDecorator)
    }}

object EntApiViewDecoratorEp:EntFrmApi<MdlViewDecorator, EntApiViewDecoratorEp.Res>(Res::class, ::MdlViewDecorator, ::Res){
    const val PATH = "${API_VIEWDECORATOR_PATH}/ep"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow = EnumApiAccessAllow.LOGIN
    class Res:eEntity()
}