package router.viewForm

import app.CompApp
import app.Factory
import comp.compPagination
import comp.compPaginationSet
import comp.input.*
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import entity.EntClientViewForm
import frm.model.MdlViewAddParam
import frm.model.MdlViewEditParam
import frm.model.MdlViewItemParam
import frm.model.MdlViewParam
import frm.vali.ValiViewItemTitle
import frm.vali.ValiViewUnit
import m42.app.M42App
import org.w3c.dom.HTMLElement

private val cat2Factory = Factory.htmlUrl("viewForm/cat2")
private val cat2ItemFactory = Factory.htmlUrl("viewForm/cat2Item")
private val viewItemTitleFactory = Factory.htmlUrl("viewForm/viewItemTitle")
private val viewItemFactory = Factory.htmlUrl("viewForm/viewItem")
private enum class CAT2{
    viewItem, allDelete, viewItemTitleList, viewItemList,
    item, allAdd, itemList, page,
    item_add, item_title, item_log,
    viewItemTitle_delete, viewItemTitle_title, viewItem_title, viewItem_unit, viewItem_viewDecorator, viewItem_log;
    override fun toString() = if ("_" in name) name.substring(name.lastIndexOf("_") + 1) else name
}
suspend fun subCat2View(staticView:eView<HTMLElement>, model:EntClientViewForm) = staticView.sub(K.cat2, cat2Factory){ subView ->
    subView.addEntityHook(EntClientViewForm::class, object:eEntityHook<HTMLElement, EntClientViewForm>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntClientViewForm){
            val cat2 = entity.cat2
            val isEmpty = cat2.isEmpty
            val page = cat2.currPage
            if(isEmpty) view.displayNone() else view.displayBlock()
            view.init(view.initBlock ?:{
                view.compPaginationSet(CAT2.page, "page"){
                    it.clickEvent = { p ->
                        entity.cat2SetPage(p)
                        staticView.entity(entity)
                        M42App.windowScrollTo(0.0, 0.0)
                    }
                }
                Unit
            })

            view.sub(CAT2.viewItem){
                if(cat2.viewItemList.isEmpty()) it.displayNone() else it.displayBlock()
            }
            view.sub(CAT2.allDelete).click = { _, _ ->
                cat2.allCheck(false)
                eLaunch{ staticView.entity(entity) }
            }
            view.sub(CAT2.viewItemTitleList).setClearList{ listView ->
                cat2.viewItemList.forEach{viewItem ->
                    listView += eView(viewItemTitleFactory){viewItemTitleView ->
                        viewItemTitleView.sub(CAT2.viewItemTitle_delete).click = { _, _->
                            cat2.itemCheckInActive(viewItem)
                            eLaunch{ staticView.entity(entity) }
                        }
                        viewItemTitleView.sub(CAT2.viewItemTitle_title).html = viewItem.cat2Itemtitle
                    }
                }
            }
            view.sub(CAT2.viewItemList).setClearList{ listView->
                cat2.viewItemList.forEach{ viewItem->
                    listView += eView(viewItemFactory){ viewItemView ->
                        viewItem.view = viewItemView
                        viewItemView.compInputTextSet(CAT2.viewItem_title){ comp ->
                            comp.initValue = viewItem.viewItemTitle
                            comp.placeholder = "r@뷰 타이틀@5/6/9"
                            comp.vali = ValiViewItemTitle
                            comp.inputClass = "width6-1"
                            comp.changeBlock = { v ->
                                viewItem.viewItemTitle = v
                                v
                            }
                            comp.errorListener = { ok, _ ->
                                eLaunch{ comp.error(ok) }
                                viewItem.isViewItemTitleOk = ok
                            }
                            comp.afterInited = {
                                comp.error(viewItem.isViewItemTitleOk)
                            }
                        }
                        viewItemView.compInputTextSet(CAT2.viewItem_unit){ comp ->
                            comp.initValue = viewItem.viewItemUnit
                            comp.placeholder = "r@단위@5/6/a"
                            comp.vali = ValiViewUnit
                            comp.inputClass = "margin-left8 width12-1"
                            comp.changeBlock = { v ->
                                viewItem.viewItemUnit = v
                                v
                            }
                            comp.errorListener = { ok, _ ->
                                eLaunch{ comp.error(ok) }
                                viewItem.isViewItemUnitOk = ok
                            }
                            comp.afterInited = {
                                comp.error(viewItem.isViewItemUnitOk)
                            }
                        }
                        viewItemView.compSelectSet<Long>(CAT2.viewItem_viewDecorator){ comp ->
                            comp.wrapperClass = "margin-left8 width6-1"
                            comp.checkBlock = { idx ->
                                viewItem.viewDecoratorRowid = comp.itemList[idx].value
                            }
                            comp.afterInited = {
                                comp.setList{
                                    entity.viewDecoratorList.map{ d ->
                                        comp.item(
                                            d.title,
                                            d.viewDecoratorRowid,
                                            d.viewDecoratorRowid == viewItem.viewDecoratorRowid
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }
            view.sub(CAT2.item){
                if(page.itemList.isEmpty()) it.displayNone() else it.displayBlock()
            }
            view.sub(CAT2.allAdd).click = { _, _->
                cat2.allCheck(true)
                eLaunch{ staticView.entity(entity) }
            }
            view.sub(CAT2.itemList).setClearList{ itemListView ->
                page.itemList.forEach{ cat2PageItem ->
                    itemListView += eView(cat2ItemFactory){ itemView ->
                        itemView.sub(CAT2.item_add).click = { _,_->
                            cat2.itemCheckActive(cat2PageItem)
                            eLaunch{ staticView.entity(entity) }
                        }
                        itemView.sub(CAT2.item_title).html = cat2PageItem.cat2Itemtitle
                    }
                }
            }
            view.sub(CAT2.page).compPagination{
                it.setPage(cat2.pageMeta.totalRows, cat2.pageMeta.page, cat2.pageMeta.rowPerPage, cat2.pageMeta.pagePerGroup)
            }
        }
    })
    suspend fun viewItemCheck(param:MdlViewParam){
        model.cat2List.forEach{ cat2 ->
            cat2.viewItemList.forEach{ viewItem ->
                viewItem.errorClear()
                val viewItemParam = MdlViewItemParam().also{ viewItemParam ->
                    viewItemParam.viewItemRowid = viewItem.viewItemRowid
                    viewItemParam.cat2ItemRowid = viewItem.cat2ItemRowid
                    viewItemParam.viewDecoratorRowid = viewItem.viewDecoratorRowid
                    viewItemParam.title = ""
                    viewItemParam.unit = ""
                }
                if(cat2.isSelected){
                    viewItem.view?.also{ viewItemView ->
                        val title = viewItemView.sub(CAT2.viewItem_title).compInputText()
                        val unit = viewItemView.sub(CAT2.viewItem_unit).compInputText()
                        val viewDecorator = viewItemView.sub(CAT2.viewItem_viewDecorator).compSelect<Long>()
                        if(CompApp.checkAll(title, unit, viewDecorator)){
                            viewItemParam.viewDecoratorRowid = viewDecorator.out()
                            viewItemParam.title = title.out()
                            viewItemParam.unit = unit.out()
                        }
                    }
                }else{
                    try{
                        viewItemParam.title = viewItem.viewItemTitle
                    }catch(e:Throwable){
                        viewItem.isViewItemTitleOk = false
                    }
                    try{
                        viewItemParam.unit = viewItem.viewItemUnit
                    }catch(e:Throwable){
                        viewItem.isViewItemUnitOk = false
                    }
                }
                param.viewItemList.add(viewItemParam)
            }
        }
    }
    subView.addEntityHook(MdlViewAddParam::class, object:eEntityHook<HTMLElement, MdlViewAddParam>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:MdlViewAddParam){
            viewItemCheck(entity)
        }
    })
    subView.addEntityHook(MdlViewEditParam::class, object:eEntityHook<HTMLElement, MdlViewEditParam>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:MdlViewEditParam){
            viewItemCheck(entity)
        }
    })
    subView.entity(model)
}