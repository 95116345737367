package router.cat1Add

import app.Factory
import app.RouterKey
import app.clearCompValue
import app.hashManager
import comp.*
import comp.input.CompTextarea
import compLabelInputSectionTextSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import entity.EntClientCat1Add
import entity.strToList
import frm.api.member.EntApiMemberSessionCheck
import frm.model.*
import frm.vali.ValiCat1Title
import frm.vali.ValiCat2Title
import m42.app.M42App
import m42.model.HcInit
import org.w3c.dom.HTMLElement
import prop.compInputSectionString
import prop.compLabelInputSectionText
import router.cat1Edit.cat2AddForm
import view.CompViewAlert
import view.CompViewConfirm

private val factory = Factory.htmlUrl("cat1Add/main")
private val cat2Factory = Factory.htmlUrl("cat1Form/cat2")

private enum class K{
    saveBtn, cat2_delBtn,cat1Title, cat2_title, cat2_addForm,cat2AddList, cat2AddBtn;
    override fun toString() = if ("_" in name) name.substring(name.lastIndexOf("_") + 1) else name
}

suspend fun Cat1AddView() = eView(factory){ staticView->
    val model = EntClientCat1Add()
    CompPageTitle(staticView){ it.title = "r@출처 등록@5/3/3" }

    staticView.compLabelInputSectionTextSet(K.cat1Title, "r@출처명@5/3/7", ValiCat1Title, true, 120, "input-section width3-1", "box-shadow"){ comp ->
        comp.changeBlock = { v->
            model.cat1Title = v
            v
        }
    }
    M42App.emptySub(staticView, K.cat2AddList, K.cat2AddBtn, K.saveBtn)



    staticView.addEntityHook(HcInit::class, object:eEntityHook<HTMLElement, HcInit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:HcInit){
            model.init()
            view.clearCompValue(K.cat1Title)
        }
    })
    staticView.addEntityHook(EntApiMemberSessionCheck.Res::class, object:eEntityHook<HTMLElement, EntApiMemberSessionCheck.Res>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntApiMemberSessionCheck.Res){
            view.entity(model)
        }
    })
    suspend fun cat2Check(cat1Param: MdlCat1Param, cat2List: MutableList<EntClientCat1Add.Cat2>):Boolean {
        var isCheck = true
        cat2List.forEach { cat2 ->
            val cat2Param = MdlCat2Param().also {
                it.cat2ItemList = mutableListOf()
            }
            cat2.view.sub(K.cat2_title).compLabelInputSectionText {
                if (it.check()) cat2Param.title = it.out() else isCheck = false
            }
            isCheck = cat2AddForm(cat2.view, cat2Param)
            cat1Param.cat2List.add(cat2Param)
        }
        return isCheck
    }

    staticView.addEntityHook(EntClientCat1Add::class, object:eEntityHook<HTMLElement, EntClientCat1Add>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntClientCat1Add){
            view.sub(K.cat2AddBtn).click = { _, _->
                entity.addForm()
                eLaunch{ view.entity(model) }
            }
            view.sub(K.cat2AddList).setClearList { listView ->
                entity.cat2AddList.forEachIndexed { itemIdx, cat2 ->
                    listView += eView(cat2Factory) { cat2View ->
                        cat2.view = cat2View
                        cat2View.sub(K.cat2_delBtn).click = { _, _->
                            eLaunch{
                                CompViewConfirm.open(
                                    "r@정말 삭제하시겠습니까? (관련 뷰 아이템/로그/이벤트 모두 삭제됩니다.)@5/3/u",
                                    "r@삭제@5/3/v",
                                    "r@취소@5/3/w"
                                ){
                                    entity.cat2AddList.removeAt(itemIdx)
                                    eLaunch{ staticView.entity(model) }
                                }
                            }
                        }
                        cat2View.compLabelInputSectionTextSet(K.cat2_title, "r@구분명@5/3/8", ValiCat2Title, true, 100, sectionWrapperClass = "", sectionClass = "input-section width4-3"){ compTitle ->
                            compTitle.initValue = cat2.title
                            compTitle.changeBlock = { v->
                                cat2.title = v
                                v
                            }
                        }
                        cat2View.compInputSectionOneSet<String>(K.cat2_addForm, CompTextarea{ compAddList ->
                            compAddList.initValue = cat2.cat2ItemStr
                            compAddList.placeholder = "r@키|값|타이틀 형식으로 입력하세요.@5/3/9"
                            compAddList.changeBlock = { v->
                                cat2.cat2ItemStr = v
                                v
                            }
                        }, inputClass = "input-section")
                    }
                }


            }
            view.sub(K.saveBtn).click = { _, _->
                eLaunch{
                    val param = MdlCat1Param().also {
                        it.cat2List = mutableListOf()
                    }
                    view.sub(K.cat1Title).compLabelInputSectionText{
                        if(it.check()) param.title = it.out()
                    }
                    if(cat2Check(param, entity.cat2AddList)){
                        N.add(param)?.also{res->
                            CompViewAlert.open("r@출처가 등록되었습니다. 수정 페이지로 이동합니다.@5/3/s"){
                                hashManager.goUrl(RouterKey.CAT1_EDIT, "r" to res.cat1Rowid)
                            }
                        }
                    }

                }
            }
        }
    })
}