package frm.vali

import ein2b.core.validation.eRuleSet
import ein2b.core.validation.eRuleVali

val ValiLogPeriod = eRuleVali{
    Case{
        String()
        Trim()
        MinLength(1, "r@주기를 입력해 주세요.@5/7/8")
        IntNum("r@주기는 숫자만 입력 가능합니다.@5/7/9")
    }
}

val ValiLogStorageDay = eRuleVali{
    Case{
        Rule("r@최대 보관 일자를 입력해주세요.@5/7/f"){v,_->
            _valiUintCheck(v)
        }
        Rule("r@최대 보관 일자를 입력해주세요.@5/7/f"){v,_->
            if("$v".toLong() > 0L) v else eRuleSet.FALSE
        }
    }
}