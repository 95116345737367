package router.event

import app.Factory
import comp.input.compInputText
import comp.input.compInputTextSet
import comp.input.compOneRadio
import comp.input.compOneRadioSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.displayNone
import ein2b.core.view.eEntityHook
import ein2b.core.view.eView
import ein2b.core.view.html
import entity.EntClientEvent
import entity.EntClientEventCat
import frm.enum.EnumEventCat
import org.w3c.dom.HTMLElement

private val catFactories = mapOf(
    EnumEventCat.LESS_THAN_VALUE to Factory.htmlUrl("event/cat10"),
    EnumEventCat.GREATER_THAN_VALUE to Factory.htmlUrl("event/cat20"),
    EnumEventCat.IN to Factory.htmlUrl("event/cat50"),
    EnumEventCat.NOT_EQUAL to Factory.htmlUrl("event/cat60")
)
suspend fun subCatOne(staticView:eView<HTMLElement>, subKey:Any, model:EntClientEvent, catModel:EntClientEventCat.One){
    val isCat5060 = catModel.cat == EnumEventCat.NOT_EQUAL || catModel.cat == EnumEventCat.IN
    val factory = catFactories[catModel.cat] ?: catFactories[EnumEventCat.IN]!!
    staticView.sub(subKey, factory){ subView ->
        subView.addEntityHook(EntClientEventCat.One::class, object:eEntityHook<HTMLElement, EntClientEventCat.One>{
            override suspend fun invoke(view:eView<HTMLElement>, entity:EntClientEventCat.One){
                if(entity.isForm){
                    view.init(view.initBlock ?: {
                        view.sub(K.cat_radioWrap)
                        view.sub(K.cat_value).displayNone()

                        view.compOneRadioSet(K.cat_radio){
                            it.checkBlock = {
                                model.form.setCat(entity)
                                eLaunch{ staticView.entity(model) }
                            }
                        }
                        view.compInputTextSet(K.cat_condition){
                            if(isCat5060){
                                it.placeholder = "r@(,)콤마로 여러개 입력 가능@5/8/f"
                                it.inputClass = "flex-grow-1"
                            }else{
                                it.inputClass = "width80px"
                            }
                            it.changeBlock = { v ->
                                entity.condition = v
                                v
                            }
                        }
                    })
                    view.sub(K.cat_radio).compOneRadio().inputValue(entity.isSelected)
                    view.sub(K.cat_condition).compInputText{
                        it.clear()
                        it.value.inputValue(entity.condition)
                    }
                }else{
                    view.sub(K.cat_radioWrap).displayNone()
                    view.sub(K.cat_radio)
                    view.sub(K.cat_condition).displayNone()

                    view.sub(K.cat_value).html = entity.condition
                }
            }
        })
        subView.addEntityHook(EntClientEvent.Form::class, object:eEntityHook<HTMLElement, EntClientEvent.Form>{
            override suspend fun invoke(view:eView<HTMLElement>, entity:EntClientEvent.Form){
                catModel.isOk = !catModel.isSelected || catModel.condition.isNotBlank()
                view.sub(K.cat_condition).compInputText().error(catModel.isOk)
            }
        })
        subView.entity(catModel)
    }
}