package frm.api

import ein2b.core.entity.eEntity

internal const val API_PATH = "/api"
internal const val API_MEMBER_PATH = "$API_PATH/member"
internal const val API_MEMBER_MASTER_PATH = "$API_MEMBER_PATH/master"
internal const val API_MEMBER_PW_PATH = "$API_MEMBER_PATH/pw"
internal const val API_CAT1_PATH = "$API_PATH/cat1"
internal const val API_CAT2_PATH = "$API_PATH/cat2"
internal const val API_VIEW_PATH = "$API_PATH/view"
internal const val API_EVENT_PATH = "$API_PATH/event"
internal const val API_EVENT_CMD_PATH = "$API_PATH/eventcmd"
internal const val API_LOG_PATH = "$API_PATH/log"
internal const val API_STORAGE_PATH = "$API_PATH/storageconfig"

internal const val API_HEX2TYPE_PATH = "$API_PATH/hex2type"
internal const val API_VIEWDECORATOR_PATH = "$API_PATH/viewdecorator"

class EntApiEmpty:eEntity()