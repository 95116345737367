package frm.api

import ein2b.core.entity.eEntity
import frm.model.*
import m42.common.enum.EnumApiAccessAllow

object EntApiEventV:EntFrmApi<EntApiEventV.Req, EntApiEventV.Res>(Res::class, ::Req, ::Res){
    const val PATH = "$API_EVENT_PATH/v"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow = EnumApiAccessAllow.LOGIN
    class Req:eEntity(){
        var cat2ItemRowid by long
        var cat2ItemEventCmdRowid by long{ default(0) }
        var p by long{ default(1) }
    }
    class Res:eEntity(){
        var meta by entity(::MdlCat2ItemEventMeta)
        var cat1 by entity(::MdlCat1)
        var cat2 by entity(::MdlCat2)
        var cat2Item by entity(::MdlCat2Item)
        var cat2ItemEventCmdList by entityList(::MdlCat2ItemEventCmd)
        var cat2ItemEventList by entityList(::MdlCat2ItemEvent)
        var viewDecoratorRowid = "0"
    }
}
object EntApiEventCmdWp:EntFrmApi<MdlCat2ItemEventCmdParam, EntApiEmpty>(EntApiEmpty::class, ::MdlCat2ItemEventCmdParam, ::EntApiEmpty){
    const val PATH = "${API_EVENT_CMD_PATH}/wp"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow = EnumApiAccessAllow.LOGIN
}
object EntApiEventCmdE:EntFrmApi<EntApiEventCmdE.Req, EntApiEventCmdE.Res>(EntApiEventCmdE.Res::class, ::Req, ::Res){
    const val PATH = "${API_EVENT_CMD_PATH}/e"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow = EnumApiAccessAllow.LOGIN
    class Req:eEntity(){
        var cat2ItemEventCmdRowid by long
    }
    class Res:eEntity(){
        var cat2ItemEventCmd by entity(::MdlCat2ItemEventCmd)
    }
}
//이벤트 명령 수정
object EntApiEventCmdEp1:EntFrmApi<MdlCat2ItemEventCmdParam, EntApiEmpty>(EntApiEmpty::class, ::MdlCat2ItemEventCmdParam, ::EntApiEmpty){
    const val PATH = "${API_EVENT_CMD_PATH}/ep1"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow = EnumApiAccessAllow.LOGIN
}
//이벤트 명령 활성/비활성
object EntApiEventCmdEp2:EntFrmApi<EntApiEventCmdEp2.Req, EntApiEmpty>(EntApiEmpty::class, ::Req, ::EntApiEmpty){
    const val PATH = "${API_EVENT_CMD_PATH}/ep2"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow = EnumApiAccessAllow.LOGIN
    class Req:eEntity(){
        var cat2ItemEventCmdRowid by long
        var isActive by boolean
    }
}
//이벤트 로그 수정
object EntApiEventEp:EntFrmApi<EntApiEventEp.Req, EntApiEmpty>(EntApiEmpty::class, ::Req, ::EntApiEmpty){
    const val PATH = "${API_EVENT_PATH}/ep"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow = EnumApiAccessAllow.LOGIN
    class Req:eEntity(){
        var cat2ItemEventRowid by long
        var memo by string
    }
}