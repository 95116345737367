package frm.model

import ein2b.core.date.eUtc
import ein2b.core.entity.eEntity
import frm.enum.EnumRateCat
import frm.enum.EnumStorageCat
import frm.vali.ValiLogStorageDay

class MdlStorageConfig:eEntity(true){
    var storageConfigRowid by long{
        default(0L) // 등록수정 같이씀
    }
    var cat1Rowid by long
    var storageCat by enum<EnumStorageCat>()
    var rateCat by enum<EnumRateCat>()
    var data by string
    var lastUpdated by utc{
        default(eUtc.default()) //등록 같이 씀
    }
    var isActive by boolean
    var isDelete by boolean
    var logMaxStorageDay by int {
        default(0)
    }
}

class MdlS3Config:eEntity(true){
    var accessKey by string
    var secretKey by string
    var bucket by string
    var region by string
    var prefix by string
}