package router.event

import app.ALink
import app.App
import app.Factory
import app.RouterKey
import comp.compInputSectionOneSet
import comp.compPagination
import comp.compPaginationSet
import comp.input.CompInputText
import compLabelInputSelectSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import entity.EntClientEvent
import frm.vali.ValiEventMemo
import org.w3c.dom.HTMLElement
import prop.compLabelInputSelect

private val factory = Factory.htmlUrl("event/detail")
private val itemFactory = Factory.htmlUrl("event/detail_item")

internal enum class DETAIL{
    empty, eventSelect, listPage, list,
    item_id, item_value, item_date, item_memoView, item_memoText, item_editBtn, item_addBtn, item_memoForm, item_memo, item_saveBtn, item_cancelBtn,
    page;
    override fun toString() = if ("_" in name) name.substring(name.lastIndexOf("_") + 1) else name
}
suspend fun subDetail(staticView:eView<HTMLElement>, subKey:Any, model:EntClientEvent) = staticView.sub(subKey, factory){ subView ->
    subView.addEntityHook(EntClientEvent::class, object:eEntityHook<HTMLElement, EntClientEvent>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntClientEvent){
            view.init(view.initBlock ?: {
                view.compLabelInputSelectSet<Long>(DETAIL.eventSelect, "r@이벤트 선택@5/8/k", null, "width6-1", true, 70, labelInputWrapperClass = "").also{
                    it.placeholder = ""
                }
                view.compPaginationSet(DETAIL.page, "page"){}
                Unit
            })
            view.sub(DETAIL.eventSelect).compLabelInputSelect<Long>().input.also{ comp ->
                comp.clickLink = ALink(
                    RouterKey.EVENT,
                    mutableMapOf("r" to entity.catInfo.cat2ItemRowid, "p" to 1),
                    "cmdR"
                )
                comp.setList{
                    entity.eventList.map{
                        comp.item(it.title, it.cat2ItemEventCmdRowid, it.isSelected)
                    }
                }
            }

            val isEmpty = entity.eventLogList.isEmpty()
            view.sub(DETAIL.empty){
                if(isEmpty) it.displayBlock() else it.displayNone()
                it.html = "r@등록된 이벤트 기록이 없습니다.@5/8/l"
            }
            view.sub(DETAIL.listPage){
                if(isEmpty) it.displayNone() else it.displayBlock()
            }
            view.sub(DETAIL.list).setClearList{ listListView ->
                entity.eventLogList.forEach{ event ->
                    val isMemoEmpty = event.memo.isEmpty()
                    listListView += eView(itemFactory){ itemView ->
                        itemView.sub(DETAIL.item_id).html = "${event.cat2ItemEventCmdRowid}"
                        itemView.sub(DETAIL.item_value).html = event.value
                        itemView.sub(DETAIL.item_date).html = App.datePart(event.regDate)
                        itemView.sub(DETAIL.item_memoView){
                            if(isMemoEmpty || event.isFormOpen) it.displayNone() else it.displayFlex()
                        }
                        itemView.sub(DETAIL.item_memoText).html = event.memo
                        itemView.sub(DETAIL.item_editBtn){
                            it.click = { _,_->
                                event.isFormOpen = true
                                eLaunch{ view.entity(entity) }
                            }
                        }
                        itemView.sub(DETAIL.item_addBtn){
                            if(isMemoEmpty && !event.isFormOpen) it.displayInlineBlock() else it.displayNone()
                            it.click = { _,_->
                                event.isFormOpen = true
                                eLaunch{ view.entity(entity) }
                            }
                        }
                        itemView.sub(DETAIL.item_memoForm){
                            if(event.isFormOpen) it.displayFlex() else it.displayNone()
                        }
                        val memo = itemView.compInputSectionOneSet<String>(DETAIL.item_memo, CompInputText{
                            it.placeholder = "r@메모@5/8/z"
                            it.inputClass = "width270px"
                            it.vali = ValiEventMemo
                            it.initValue = event.memo
                        }, wrapperClass = "flex-grow-1", inputClass = "input-section")
                        itemView.sub(DETAIL.item_saveBtn).click = { _,_->
                            if(memo.check()){
                                eLaunch{
                                    N.eventMemoWrite(event.cat2ItemEventRowid, memo.out())?.also{
                                        submitEnd("r@이벤트 메모가 작성되었습니다.@5/8/H", entity)
                                    }
                                }
                            }
                        }
                        itemView.sub(DETAIL.item_cancelBtn).click = { _,_->
                            event.isFormOpen = false
                            eLaunch{ view.entity(entity) }
                        }
                    }
                }
            }
            view.sub(DETAIL.page).compPagination{ comp ->
                comp.pageLink = ALink(
                    RouterKey.EVENT,
                    mutableMapOf("r" to entity.catInfo.cat2ItemRowid, "dr" to entity.viewDecoratorRowid, "cmdR" to entity.meta.cat2ItemEventCmdRowid),
                    "p"
                )
                comp.setPage(entity.meta.totalRows.toLong(), entity.meta.page.toLong(), entity.meta.rowPerPage.toLong(), entity.meta.pagePerGroup.toLong())
            }
        }
    })
    subView.entity(model)
}