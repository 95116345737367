package router.pwChange

import app.ClientApiMemberPwEp
import app.ClientApiMemberRsa
import app.ClientApiMemberSessionCheck
import frm.api.member.EntApiMemberPwEp
import frm.api.member.EntApiMemberRsa
import frm.api.member.EntApiMemberSessionCheck

object N{
    suspend fun rsaGet():EntApiMemberRsa.Res? = ClientApiMemberRsa.net()
    suspend fun pwChangeReady():EntApiMemberSessionCheck.Res? = ClientApiMemberSessionCheck.net()
    suspend fun pwChange(rsaId:String, pw:String, newPw:String):EntApiMemberPwEp.Res? = ClientApiMemberPwEp.net{ req->
        req.rsaId = rsaId
        req.oldPw = pw
        req.newPw = newPw
    }
}