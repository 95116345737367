package frm.api

import ein2b.core.entity.eEntity
import frm.model.*
import frm.vali.ValiLogStorageDay
import m42.common.enum.EnumApiAccessAllow
import m42.common.model.MdlM42ApiPageMeta

object EntApiCat2ItemLogE:EntFrmApi<EntApiCat2ItemLogE.Req, EntApiCat2ItemLogE.Res>(Res::class, ::Req, ::Res){
    const val PATH = "${API_LOG_PATH}/e"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow = EnumApiAccessAllow.LOGIN
    class Req:eEntity(){
        var cat2ItemRowid by long
        var p by long{ default(1) }
    }
    class Res:eEntity(){
        var meta by entity(::MdlM42ApiPageMeta)
        var cat1 by entity(::MdlCat1)
        var cat2 by entity(::MdlCat2)
        var viewItem by entity(::MdlViewItem)
        var cat2Item by entity(::MdlCat2Item)
        var cat2ItemLogList by entityList(::MdlCat2ItemLog)
        var viewDecoratorRowid = "0"
    }
}
object EntApiCat2ItemLogEp:EntFrmApi<EntApiCat2ItemLogEp.Req, EntApiCat2ItemLogE.Res>(EntApiCat2ItemLogE.Res::class, ::Req, EntApiCat2ItemLogE::Res){
    const val PATH = "${API_LOG_PATH}/ep"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow = EnumApiAccessAllow.LOGIN
    class Req:eEntity(){
        var cat2ItemRowid by long
        var isLogging by boolean
    }
}

object EntApiCat2ItemLogStorageDayEp:EntFrmApi<EntApiCat2ItemLogStorageDayEp.Req, EntApiCat2ItemLogE.Res>(EntApiCat2ItemLogE.Res::class, ::Req, EntApiCat2ItemLogE::Res){
    const val PATH = "${API_LOG_PATH}/storageday/ep"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow = EnumApiAccessAllow.LOGIN
    class Req:eEntity(){
        var cat2ItemRowid by long
        var logMaxStorageDay by int { validator(ValiLogStorageDay) }
    }
}

