package frm.model

import ein2b.core.entity.eEntity
import frm.enum.EnumRateCat
import frm.enum.EnumStorageCat

class MdlStorageConfigEpParam:eEntity(){
    var cat1Rowid by long
    var storageConfigList by entityList(::MdlStorageConfig)
}
