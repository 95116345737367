package router.event

import app.CompApp
import app.Factory
import comp.input.compError
import comp.input.compErrorSet
import compLabelInputSectionTextAreaSet
import compLabelInputSectionTextSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import entity.EntClientEvent
import frm.enum.EnumEventCat
import frm.model.MdlCat2ItemEventCmdParam
import frm.vali.ValiEventCmdEmailList
import frm.vali.ValiEventCmdExcecutePeriod
import frm.vali.ValiEventCmdMessage
import frm.vali.ValiEventCmdPeriod
import org.w3c.dom.HTMLElement
import prop.compLabelInputSectionText
import prop.compLabelInputSectionTextArea

private val factory = Factory.htmlUrl("event/form")
internal enum class FORM{ title, cat10, cat20, cat30, cat40, cat50, cat60, catError, period, executePeriod, emailList, message, close, submit }
suspend fun subForm(staticView:eView<HTMLElement>, subKey:Any, model:EntClientEvent) = staticView.sub(subKey, factory){ subView ->
    subView.addEntityHook(EntClientEvent::class, object:eEntityHook<HTMLElement, EntClientEvent>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntClientEvent){
            val form = entity.form
            if(form.isOpen) view.displayBlock() else view.displayNone()
            view.init(view.initBlock ?: {
                subCatOne(view, FORM.cat10, entity, form.cat10)
                subCatOne(view, FORM.cat20, entity, form.cat20)
                subCatTwo(view, FORM.cat30, entity, form.cat30)
                subCatTwo(view, FORM.cat40, entity, form.cat40)
                subCatOne(view, FORM.cat50, entity, form.cat50)
                subCatOne(view, FORM.cat60, entity, form.cat60)
                view.compErrorSet(FORM.catError)
                view.compLabelInputSectionTextSet(FORM.period, "r@재발송 주기@5/8/3", ValiEventCmdPeriod, sectionClass = ""){
                    it.inputClass = "width12-1"
                    it.changeBlock = { v ->
                        form.period = v
                        v
                    }
                }
                view.compLabelInputSectionTextSet(FORM.executePeriod, "r@적용 기준일@5/8/K", ValiEventCmdExcecutePeriod, sectionClass = ""){
                    it.inputClass = "width12-1"
                    it.changeBlock = { v ->
                        form.executePeriod = v
                        v
                    }

                }
                view.compLabelInputSectionTextSet(FORM.emailList, "r@이메일 리스트@5/8/4", ValiEventCmdEmailList, sectionClass = "flex-grow-1 input-section"){
                    it.placeholder = "r@(,)콤마로 여러개 입력 가능@5/8/5"
                    it.changeBlock = { v ->
                        form.emailList = v
                        v
                    }
                }
                view.compLabelInputSectionTextAreaSet(FORM.message, "r@이메일 내용@5/8/6", ValiEventCmdMessage, sectionClass = "flex-grow-1 input-section", sectionWrapperClass = "margin-top24"){
                    it.changeBlock = { v ->
                        form.message = v
                        v
                    }
                }
                Unit
            })
            view.sub(FORM.cat10).entity(form.cat10)
            view.sub(FORM.cat20).entity(form.cat20)
            view.sub(FORM.cat30).entity(form.cat30)
            view.sub(FORM.cat40).entity(form.cat40)
            view.sub(FORM.cat50).entity(form.cat50)
            view.sub(FORM.cat60).entity(form.cat60)
            val catError = view.sub(FORM.catError).compError().also{ it.clear() }
            val period = view.sub(FORM.period).compLabelInputSectionText{
                it.clear()
                it.inputValue(form.period)
            }
            val executePeriod = view.sub(FORM.executePeriod).compLabelInputSectionText{
                it.clear()
                it.inputValue(form.executePeriod)
            }
            val emailList = view.sub(FORM.emailList).compLabelInputSectionText{
                it.clear()
                it.inputValue(form.emailList)
            }
            val message = view.sub(FORM.message).compLabelInputSectionTextArea{
                it.clear()
                it.inputValue(form.message)
            }
            view.sub(FORM.title).html = if(form.isAdd) "r@이벤트 추가@5/8/h" else "r@이벤트 수정@5/8/o"
            view.sub(FORM.close).click = { _,_->
                form.init()
                eLaunch{ view.entity(entity) }
            }
            view.sub(FORM.submit){
                it.html = if(form.isAdd) "r@이벤트 추가@5/8/d" else "r@이벤트 수정@5/8/p"
                it.click = { _,_->
                    var isCheck = form.catList.any{ it.isSelected }
                    eLaunch{
                        catError.update(isCheck, "r@이벤트 조건을 선택해주세요.@5/8/r")
                        view.sub(FORM.cat10).entity(form)
                        view.sub(FORM.cat20).entity(form)
                        view.sub(FORM.cat30).entity(form)
                        view.sub(FORM.cat40).entity(form)
                        view.sub(FORM.cat50).entity(form)
                        view.sub(FORM.cat60).entity(form)
                        if(!form.catList.all{ it.isOk }){
                            isCheck = false
                            catError.update(isCheck, "r@이벤트 조건에 해당하는 값을 입력해주세요.@5/8/s")
                        }
                        if(!CompApp.checkAll(period, executePeriod, emailList, message)) isCheck = false
                        if(isCheck){
                            val param = MdlCat2ItemEventCmdParam().also{ param ->
                                param.cat2ItemEventCmdRowid = form.cat2ItemEventCmdRowid
                                param.cat2ItemRowid = entity.catInfo.cat2ItemRowid
                                param.eventCat = form.catList.find{ it.isSelected }!!.cat
                                //param.eventCat = form.eventCat!!
                                param.condition = when(param.eventCat){
                                    EnumEventCat.LESS_THAN_VALUE -> form.cat10.condition
                                    EnumEventCat.GREATER_THAN_VALUE -> form.cat20.condition
                                    EnumEventCat.WITHIN_THE_RANGE -> "${form.cat30.condition1},${form.cat30.condition2}"
                                    EnumEventCat.EXCLUDE_THE_RANGE -> "${form.cat40.condition1},${form.cat40.condition2}"
                                    EnumEventCat.IN -> form.cat50.condition
                                    EnumEventCat.NOT_EQUAL -> form.cat60.condition
                                }
                                param.period = form.period.toInt()
                                param.executionPeriod = form.executePeriod.toInt()
                                param.emailList = form.emailList
                                param.message = form.message
                            }
                            if(form.isAdd){
                                N.cmdAdd(param)?.also{ _ ->
                                    submitEnd("r@이벤트가 추가 되었습니다.@5/8/F", entity)
                                }
                            }else{
                                N.cmdEdit(param)?.also{ _ ->
                                    submitEnd("r@이벤트가 수정 되었습니다.@5/8/G", entity)
                                }
                            }
                        }
                    }
                }
            }
        }
    })
    subView.entity(model)
}