package router.hex2Type

import CompLabelInputSection
import app.CompApp
import app.Factory
import app.clearCompValue
import comp.Comp
import comp.CompValueOut
import comp.OutType
import comp.input.CompInputText
import comp.input.CompSelect
import comp.input.CompTextarea
import comp.input.compSelect
import compLabelInputSectionSelectSet
import compLabelInputSectionTextAreaSet
import compLabelInputSectionTextSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.click
import ein2b.core.view.eView
import ein2b.core.view.html
import entity.EntClientHex2Type
import frm.enum.EnumTypeCat
import org.w3c.dom.HTMLElement
import prop.compLabelInputSection
import prop.compLabelInputSectionText
import prop.compLabelInputSectionTextArea

class CompHex2TypeForm:Comp,CompValueOut<EntClientHex2Type.Hex2Type>{
    private enum class K{ title, typeCatComp, js, submit }
    companion object{
        private val factory = Factory.htmlUrl("hex2Type/form")
        suspend operator fun invoke(rootView:eView<HTMLElement>, subKey:Any, block:(CompHex2TypeForm)->Unit){
            val comp = CompHex2TypeForm()
            block(comp)
            rootView.sub(subKey, factory){
                it.compLabelInputSectionTextSet(K.title, "r@컨버터 명@5/b/3", isInline = true, sectionWrapperClass = "flex-grow-1"){ title ->
                    title.changeBlock = { v ->
                        comp.mdl.title = v
                        v
                    }
                }.also {compInputSectionText->
                    comp.compTitle = compInputSectionText
                }
                it.compLabelInputSectionSelectSet<EnumTypeCat>(
                    K.typeCatComp, "r@타입 선택@5/c/1",
                    "r@타입을 선택해 주세요.@5/c/2",
                    "r@타입을 선택해 주세요.@5/c/1",
                    isInline = true
                ){ compSelect->
                    compSelect.checkBlock = { idx->
                        val v = compSelect.itemList[idx].value
                        comp.mdl.typeCat = v
                    }
                }.also {
                    comp.compTypeCat = it
                }

                it.compLabelInputSectionTextAreaSet(K.js, "r@컨버터 스크립트@5/b/4", isInline = true, sectionWrapperClass = "margin-top15"){ js ->
                    js.changeBlock = { v ->
                        comp.mdl.js = v
                        v
                    }
                }.also {compInputSectionTextarea->
                    comp.compJs = compInputSectionTextarea
                }
                it.sub(K.submit){ submitView ->
                    submitView.html = comp.btnLabel
                    submitView.click = { _,_-> eLaunch{ comp.submitBlock() } }
                }
                comp.target = it
                comp.mdlRender()
                comp.baseProp(it, true)
            }
        }
    }
    private lateinit var target:eView<HTMLElement>
    lateinit var submitBlock:suspend ()->Unit
    var btnLabel = "r@추가@5/b/2"
    val mdl = EntClientHex2Type.Hex2Type()
    private lateinit var compTitle:CompLabelInputSection<String,String,String,CompInputText>
    private lateinit var compJs:CompLabelInputSection<String,String,String,CompTextarea>
    private lateinit var compTypeCat:CompLabelInputSection<List<Int>, List<EnumTypeCat>, EnumTypeCat, CompSelect<EnumTypeCat>>

    suspend fun mdlRender(){
        target.sub(K.title).compLabelInputSectionText{ title ->
            title.inputValue(mdl.title)
        }
        target.sub(K.js).compLabelInputSectionTextArea{ js ->
            js.inputValue(mdl.js)
        }
        target.sub(K.typeCatComp).compLabelInputSection().inputView().compSelect<EnumTypeCat> { comp ->
            comp.setList {
                EnumTypeCat.entries.map {
                    comp.item(it.name, it, it == mdl.typeCat)
                }
            }
        }
    }

    override val outs:HashMap<OutType, suspend ()->EntClientHex2Type.Hex2Type> = hashMapOf(OutType.DEFAULT to { mdl })
    override suspend fun clear(){
        mdl.init()
        target.clearCompValue(K.title, K.js, K.typeCatComp)
    }
    override fun check() = CompApp.checkAll(compTitle, compJs, compTypeCat)

}
suspend fun eView<HTMLElement>.compHex2TypeFormSet(subKey:Any, block:(CompHex2TypeForm)->Unit = {}){
    CompHex2TypeForm(this, subKey, block)
}