package router.event

import app.*
import frm.model.MdlCat2ItemEventCmdParam
import m42.common.api.EntM42Api

object N{
    suspend fun get(cat2ItemRowid:Long, cat2ItemEventCmdRowid:Long, p:Long) = ClientApiEventV.net{ req ->
        req.cat2ItemRowid = cat2ItemRowid
        req.cat2ItemEventCmdRowid = cat2ItemEventCmdRowid
        req.p = p
    }
    suspend fun cmdAdd(param:MdlCat2ItemEventCmdParam) = ClientApiEventCmdWp.net(EntM42Api.ENTITY_KEY to { param })
    suspend fun cmdGet(cat2ItemEventCmdRowid:Long) = ClientApiEventCmdE.net{ req ->
        req.cat2ItemEventCmdRowid = cat2ItemEventCmdRowid
    }
    suspend fun cmdEdit(param:MdlCat2ItemEventCmdParam) = ClientApiEventCmdEp1.net(EntM42Api.ENTITY_KEY to { param })
    suspend fun cmdIsActive(cat2ItemEventCmdRowid:Long, isActive:Boolean) = ClientApiEventCmdEp2.net{ req ->
        req.cat2ItemEventCmdRowid = cat2ItemEventCmdRowid
        req.isActive = isActive
    }
    suspend fun eventMemoWrite(cat2ItemEventRowid:Long, memo:String) = ClientApiEventEp.net{ req ->
        req.cat2ItemEventRowid = cat2ItemEventRowid
        req.memo = memo
    }
}