package frm.enum

import ein2b.core.entity.field.EnumRowid

// 코틀린 타입
// - Event 발생 여부를 판단하기 위해 비교할 때 쓴다.
// - Log를 출력하거나 View에 표시하기 위한 것이라면 쓰지 않는다!
enum class EnumTypeCat(private val handler:TypeCatHandler): EnumRowid<Int> {
    STRING(StringTypeCatHandler){
        override val rowid = 1
    },
    BOOLEAN(BooleanTypeCatHandler){
        override val rowid = 2
    },
    BYTE(ByteTypeCatHandler){
        override val rowid = 3
    },
    SHORT(ShortTypeCatHandler){
        override val rowid = 10
    },
    INT(IntTypeCatHandler){
        override val rowid = 11
    },
    LONG(LongTypeCatHandler){
        override val rowid = 12
    },
    FLOAT(FloatTypeCatHandler){
        override val rowid = 15
    },
    DOUBLE(DoubleTypeCatHandler){
        override val rowid = 16
    };

    fun checkEvent(eventCat: EnumEventCat, value1: Any, value2: String) = handler.checkEvent(eventCat, value1, value2)
}

interface TypeCatHandler {
    fun checkEvent(eventCat: EnumEventCat, value1: Any, value2: String): Boolean
}

private val REG_INTEGER = """^-?[0-9]+$""".toRegex()
private fun isIntegerType(s: Any) = s is Byte || s is Int || s is Short || s is Long || (s as? String)?.let{REG_INTEGER.matches(s)} ?: false

private val REG_DOUBLE_OR_FLOAT = """^-?([0-9]*[.])?[0-9]+([eE][-+]?[0-9]+)?$""".toRegex()
private fun isDoubleOrFloat(s: Any) = s is Number || (s as? String)?.let{REG_DOUBLE_OR_FLOAT.matches(s)}?:false


private object StringTypeCatHandler : TypeCatHandler {
    override fun checkEvent(eventCat: EnumEventCat, value1: Any, value2: String): Boolean {
        val v1 = ((value1 as? String)?:"$value1").trim()
        return when(eventCat){
            EnumEventCat.LESS_THAN_VALUE -> v1.compareTo(value2) < 0
            EnumEventCat.GREATER_THAN_VALUE -> v1.compareTo(value2) > 0
            EnumEventCat.WITHIN_THE_RANGE -> false
            EnumEventCat.EXCLUDE_THE_RANGE -> false
            EnumEventCat.IN ->{
                val conditions = value2.split(",").map { it.trim() }
                v1 in conditions
            }
            EnumEventCat.NOT_EQUAL -> {
                val conditions = value2.split(",").map { it.trim() }
                v1 !in conditions
            }
        }
    }
}

private object ByteTypeCatHandler : TypeCatHandler {
    override fun checkEvent(eventCat: EnumEventCat, value1: Any, value2: String): Boolean {
        return if(isIntegerType(value1)){
            val v1 = value1.toString().toByte()
            when(eventCat){
                EnumEventCat.LESS_THAN_VALUE ->{
                    if(isIntegerType(value2)) v1 < value2.toByte() else false
                }
                EnumEventCat.GREATER_THAN_VALUE ->{
                    if(isIntegerType(value2)) v1 > value2.toByte() else false
                }
                EnumEventCat.WITHIN_THE_RANGE ->{
                    val conditions = value2.split(",").filter { isIntegerType(it) }.map { it.toByte() }
                    if(conditions.size != 2) false
                    else conditions[0] < v1 && v1 < conditions[1]
                }
                EnumEventCat.EXCLUDE_THE_RANGE ->{
                    val conditions = value2.split(",").filter { isIntegerType(it) }.map { it.toByte() }
                    if(conditions.size != 2) false
                    else v1 < conditions[0] || conditions[1] > v1
                }
                EnumEventCat.IN ->{
                    val conditions = value2.split(",").filter { isIntegerType(it) }.map { it.toByte() }
                    v1 in conditions
                }
                EnumEventCat.NOT_EQUAL -> {
                    val conditions = value2.split(",").filter { isIntegerType(it) }.map { it.toByte() }
                    v1 !in conditions
                }
            }
        }else false
    }
}

private object IntTypeCatHandler : TypeCatHandler {
    override fun checkEvent(eventCat: EnumEventCat, value1: Any, value2: String): Boolean {
        return if(isIntegerType(value1)){
            val v1 = value1.toString().toInt()
            when(eventCat){
                EnumEventCat.LESS_THAN_VALUE ->{
                    if(isIntegerType(value2)) v1 < value2.toInt() else false
                }
                EnumEventCat.GREATER_THAN_VALUE ->{
                    if(isIntegerType(value2)) v1 > value2.toInt() else false
                }
                EnumEventCat.WITHIN_THE_RANGE ->{
                    val conditions = value2.split(",").filter { isIntegerType(it) }.map { it.toInt() }
                    if(conditions.size != 2) false
                    else conditions[0] < v1 && v1 < conditions[1]
                }
                EnumEventCat.EXCLUDE_THE_RANGE ->{
                    val conditions = value2.split(",").filter { isIntegerType(it) }.map { it.toInt() }
                    if(conditions.size != 2) false
                    else v1 < conditions[0] || conditions[1] > v1
                }
                EnumEventCat.IN ->{
                    val conditions = value2.split(",").filter { isIntegerType(it) }.map { it.toInt() }
                    v1 in conditions
                }
                EnumEventCat.NOT_EQUAL -> {
                    val conditions = value2.split(",").filter { isIntegerType(it) }.map { it.toInt() }
                    v1 !in conditions
                }
            }
        }else false
    }
}

private object ShortTypeCatHandler : TypeCatHandler {
    override fun checkEvent(eventCat: EnumEventCat, value1: Any, value2: String): Boolean {
        return if(isIntegerType(value1)){
            val v1 = value1.toString().toShort()
            when(eventCat){
                EnumEventCat.LESS_THAN_VALUE ->{
                    if(isIntegerType(value2)) v1 < value2.toShort() else false
                }
                EnumEventCat.GREATER_THAN_VALUE ->{
                    if(isIntegerType(value2)) v1 > value2.toShort() else false
                }
                EnumEventCat.WITHIN_THE_RANGE ->{
                    val conditions = value2.split(",").filter { isIntegerType(it) }.map { it.toShort() }
                    if(conditions.size != 2) false
                    else conditions[0] < v1 && v1 < conditions[1]
                }
                EnumEventCat.EXCLUDE_THE_RANGE ->{
                    val conditions = value2.split(",").filter { isIntegerType(it) }.map { it.toShort() }
                    if(conditions.size != 2) false
                    else v1 < conditions[0] || conditions[1] > v1
                }
                EnumEventCat.IN ->{
                    val conditions = value2.split(",").filter { isIntegerType(it) }.map { it.toShort() }
                    v1 in conditions
                }
                EnumEventCat.NOT_EQUAL -> {
                    val conditions = value2.split(",").filter { isIntegerType(it) }.map { it.toShort() }
                    v1 !in conditions
                }
            }
        }else false
    }
}

private object LongTypeCatHandler : TypeCatHandler {
    override fun checkEvent(eventCat: EnumEventCat, value1: Any, value2: String): Boolean {
        return if(isIntegerType(value1)){
            val v1 = value1.toString().toLong()
            when(eventCat){
                EnumEventCat.LESS_THAN_VALUE ->{
                    if(isIntegerType(value2)) v1 < value2.toLong() else false
                }
                EnumEventCat.GREATER_THAN_VALUE ->{
                    if(isIntegerType(value2)) v1 > value2.toLong() else false
                }
                EnumEventCat.WITHIN_THE_RANGE ->{
                    val conditions = value2.split(",").filter { isIntegerType(it) }.map { it.toLong() }
                    if(conditions.size != 2) false
                    else conditions[0] < v1 && v1 < conditions[1]
                }
                EnumEventCat.EXCLUDE_THE_RANGE ->{
                    val conditions = value2.split(",").filter { isIntegerType(it) }.map { it.toLong() }
                    if(conditions.size != 2) false
                    else v1 < conditions[0] || conditions[1] > v1
                }
                EnumEventCat.IN ->{
                    val conditions = value2.split(",").filter { isIntegerType(it) }.map { it.toLong() }
                    v1 in conditions
                }
                EnumEventCat.NOT_EQUAL -> {
                    val conditions = value2.split(",").filter { isIntegerType(it) }.map { it.toLong() }
                    v1 !in conditions
                }
            }
        }else false
    }
}

private object FloatTypeCatHandler : TypeCatHandler {
    override fun checkEvent(eventCat: EnumEventCat, value1: Any, value2: String): Boolean {
        return if(isDoubleOrFloat(value1)){
            val v1 = value1.toString().toFloat()
            when(eventCat){
                EnumEventCat.LESS_THAN_VALUE ->{
                    if(isDoubleOrFloat(value2)) v1 < value2.toFloat() else false
                }
                EnumEventCat.GREATER_THAN_VALUE ->{
                    if(isDoubleOrFloat(value2)) v1 > value2.toFloat() else false
                }
                EnumEventCat.WITHIN_THE_RANGE ->{
                    val conditions = value2.split(",").filter { isDoubleOrFloat(it) }.map { it.toFloat() }
                    if(conditions.size != 2) false
                    else conditions[0] < v1 && v1 < conditions[1]
                }
                EnumEventCat.EXCLUDE_THE_RANGE ->{
                    val conditions = value2.split(",").filter { isDoubleOrFloat(it) }.map { it.toFloat() }
                    if(conditions.size != 2) false
                    else v1 < conditions[0] || conditions[1] > v1
                }
                EnumEventCat.IN ->{
                    val conditions = value2.split(",").filter { isDoubleOrFloat(it) }.map { it.toFloat() }
                    v1 in conditions
                }
                EnumEventCat.NOT_EQUAL -> {
                    val conditions = value2.split(",").filter { isDoubleOrFloat(it) }.map { it.toFloat() }
                    v1 !in conditions
                }
            }
        }else false
    }
}

private object DoubleTypeCatHandler : TypeCatHandler {
    override fun checkEvent(eventCat: EnumEventCat, value1: Any, value2: String): Boolean {
        return if(isDoubleOrFloat(value1)){
            val v1 = value1.toString().toDouble()
            when(eventCat){
                EnumEventCat.LESS_THAN_VALUE ->{
                    if(isDoubleOrFloat(value2)) v1 < value2.toDouble() else false
                }
                EnumEventCat.GREATER_THAN_VALUE ->{
                    if(isDoubleOrFloat(value2)) v1 > value2.toDouble() else false
                }
                EnumEventCat.WITHIN_THE_RANGE ->{
                    val conditions = value2.split(",").filter { isDoubleOrFloat(it) }.map { it.toDouble() }
                    if(conditions.size != 2) false
                    else conditions[0] < v1 && v1 < conditions[1]
                }
                EnumEventCat.EXCLUDE_THE_RANGE ->{
                    val conditions = value2.split(",").filter { isDoubleOrFloat(it) }.map { it.toDouble() }
                    if(conditions.size != 2) false
                    else v1 < conditions[0] || conditions[1] > v1
                }
                EnumEventCat.IN ->{
                    val conditions = value2.split(",").filter { isDoubleOrFloat(it) }.map { it.toDouble() }
                    v1 in conditions
                }
                EnumEventCat.NOT_EQUAL -> {
                    val conditions = value2.split(",").filter { isDoubleOrFloat(it) }.map { it.toDouble() }
                    v1 !in conditions
                }
            }
        }else false
    }
}

private object BooleanTypeCatHandler : TypeCatHandler {
    override fun checkEvent(eventCat: EnumEventCat, value1: Any, value2: String): Boolean {
        val v1 = (value1 as? String) == "1" || (value1 as? String) == "true" || (value1 as? Boolean) == true
        return when(eventCat){
            EnumEventCat.LESS_THAN_VALUE -> false
            EnumEventCat.GREATER_THAN_VALUE -> false
            EnumEventCat.WITHIN_THE_RANGE -> false
            EnumEventCat.EXCLUDE_THE_RANGE -> false
            EnumEventCat.IN ->{
                val conditions = value2.split(",").map{ it == "1" || it == "true" }
                v1 in conditions
            }
            EnumEventCat.NOT_EQUAL -> {
                val conditions = value2.split(",").map{ it == "1" || it == "true" }
                v1 !in conditions
            }
        }
    }
}