package router.hex2Type

import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import m42.app.M42App
import m42.common.model.MdlM42ApiPage
import m42.domBase.PageBase
import org.w3c.dom.HTMLElement

class Hex2TypeP(router:eRouter<HTMLElement>):PageBase(::Hex2TypeView, router){
    override suspend fun net(data:eRouterData){
        data.parse(MdlM42ApiPage())?.also{ ent ->
            N.get(ent.p.toLong())?.also{ res ->
                view?.entity(res)
            }
        } ?: M42App.routerError()
    }
}