import app.Factory
import comp.Comp
import comp.input.*
import ein2b.core.validation.eVali
import ein2b.core.view.className
import ein2b.core.view.eView
import ein2b.core.view.html
import ein2b.core.view.width
import org.w3c.dom.HTMLElement

suspend fun eView<HTMLElement>.compLabelInputTextSet(subKey:Any, label:String, vali:eVali? = null, isInline:Boolean = false, width:Int = 100):CompInputText{
    return CompLabelInput(this, subKey, label, CompInputText{
        it.vali = vali
    }, isInline, width).input
}
suspend fun eView<HTMLElement>.compLabelInputNumberSet(subKey:Any, label:String, vali:eVali? = null, isInline:Boolean = false, width:Int = 100):CompInputNumber{
    return CompLabelInput(this, subKey, label, CompInputNumber{
        it.vali = vali
    }, isInline, width).input
}
suspend fun eView<HTMLElement>.compLabelInputPriceSet(subKey:Any, label:String, isInline:Boolean = false, width:Int = 100, block:(CompInputPrice)->Unit):CompInputPrice{
    return CompLabelInput(this, subKey, label, CompInputPrice(block), isInline, width).input
}
suspend fun eView<HTMLElement>.compLabelInputDateSet(subKey:Any, label:String, ymdPattern:String = "Y/m/d(w)", wrapperClass: String = "input-date", isInline:Boolean = false, width:Int = 100):CompInputDate{
    return CompLabelInput(this, subKey, label, CompInputDate{
        it.ymdPattern = ymdPattern
        it.wrapperClass = wrapperClass
    }, isInline, width).input
}
suspend fun eView<HTMLElement>.compLabelInputPasswordSet(subKey:Any, label:String, vali:eVali? = null, isInline:Boolean = false, width:Int = 100):CompInputPassword{
    return CompLabelInput(this, subKey, label, CompInputPassword{
        it.vali = vali
    }, isInline, width).input
}
suspend fun <V> eView<HTMLElement>.compLabelInputSelectSet(subKey:Any, label:String, vali:eVali? = null, wrapperClass:String = "width6-1", isInline:Boolean = false, width:Int = 100, labelInputWrapperClass:String = "margin-top20"):CompSelect<V>{
    return CompLabelInput(this, subKey, label, CompSelect<V>{
        it.wrapperClass = wrapperClass
        it.placeholder = "선택 안함"
        it.vali = vali
    }, isInline, width, labelInputWrapperClass).input
}
suspend fun eView<HTMLElement>.compLabelInputCheckboxSet(subKey:Any, label:String, default:Boolean = false, isInline:Boolean = true, width:Int = 100, wrapperClass:String = "margin-top20"):CompOneCheckBox{
    return CompLabelInput(this, subKey, label, CompOneCheckBox{ it.initValue = default }, isInline, width, wrapperClass).input
}

class CompLabelInput<IN:Any,V,OUT,INPUT:CompInput<IN,V,OUT>>(val input:INPUT):Comp {
    companion object{
        //language=html
        private val factory = Factory.html("""
<div class="margin-top20">
    <label data-view="label" class="input-block-label"></label>
    <div class="input-section"><b data-view="input"></b></div>
</div>""")
        //language=html
        private val inLineFactory = Factory.html("""
<div class="flex-center margin-top20">
    <label data-view="label" class="input-inline-label flex-shrink-0"></label>
    <div class="input-section flex-grow-1"><b data-view="input"></b></div>
</div>""")
        suspend operator fun <IN:Any,V,OUT, INPUT:CompInput<IN,V,OUT>> invoke(
            root:eView<HTMLElement>, subKey:Any, label:String, input:INPUT, isInline:Boolean = true, width:Int = 100, wrapperClass:String = "margin-top20"
        ):CompLabelInput<IN,V,OUT,INPUT>{
            val comp = CompLabelInput(input)
            comp.wrapperClass = wrapperClass
            root.sub(subKey, if(isInline) inLineFactory else factory){ subView->
                val wrapperDefaultClass = if(isInline) "flex-center " else ""
                subView.className = "$wrapperDefaultClass${comp.wrapperClass}"
                subView.sub("label"){
                    it.html = label
                    if(isInline) it.width = "${width}px"
                }
                input.comp(subView, "input")
            }
            comp.baseProp(root.sub(subKey))
            return comp
        }
    }
    var wrapperClass = ""
    fun check() = input.value.check()
    val isOk get() = input.value.isOk
    val value get() = input.value.value
    fun inputValue(v:IN) = input.value.inputValue(v)
    suspend fun clear() = input.clear()
}