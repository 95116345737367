package comp.input

import app.ALink
import app.Factory
import comp.OutType
import app.eWindow
import ein2b.core.core.err
import ein2b.core.core.uuid
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import org.w3c.dom.HTMLElement
abstract class CompSelectBase<V>:CompInputMulti<V, V>(){
    companion object{
        protected const val CLASS_NAME = "CompSelect"
        protected const val WRAPPER = "${CLASS_NAME}_wrapper"
        protected const val PLACEHOLDER = "${CLASS_NAME}_placeholder"
        protected const val SELECT = "${CLASS_NAME}_select"
        protected const val ARROW = "${CLASS_NAME}_arrow"
        protected const val ITEM_LIST = "${CLASS_NAME}_item_list"
        protected const val SELECTED_CLASS = "selected"
        protected const val FOCUSED_CLASS = "focused"
        protected const val DISABLED_CLASS = "disabled"
        protected const val ERROR_CLASS = "error"
        //language=html
        protected val FACTORY:suspend ()-> HTMLElement = Factory.html("""
    <div data-view="$WRAPPER">        
        <div class="selectbox flex-grow-1">
            <div data-view="$WRAPPER.$PLACEHOLDER" class="placeholder"></div>
            <div data-view="$WRAPPER.$SELECT" class="ellipsis"></div>
        </div>        
        <div data-view="$WRAPPER.$ARROW" class="arrow"></div>
        <ul data-view="$WRAPPER.$ITEM_LIST" class="item-list scroll2"></ul>
    </div>""")

    }
    val compId = uuid("")
    final override val outs:HashMap<OutType, suspend () -> V> = hashMapOf(OutType.DEFAULT to { value.value.first() })
    final override var placeholder:String = ""
    final override val factory:suspend ()-> HTMLElement = FACTORY
    final override suspend fun error(isOk:Boolean){
        value.isOk = isOk
        target.className = setClassName()
    }
    final override val subKey:String = WRAPPER
    final override var wrapperDefaultClass = "comp-selectbox"
    protected var isItemListOpen = false
    protected var defaultIdx = -1
    var isDisabled = false
    var clickLink:ALink? = null
    var checkBlock:((idx:Int)->Unit)? = null
    var initBlock:((idx:Int)->Unit)? = null
    var isReverse = false
    abstract var itemClass:String
    var itemTitleClass = ""

    suspend fun itemListDisplay(isOpen:Boolean = false){
        isItemListOpen = isOpen
        target.sub(ITEM_LIST){ if(isItemListOpen) it.displayBlock() else it.displayNone() }
        target.className = setClassName()
    }
    suspend fun setDefault(){
        target.sub(SELECT).html = initValue
        target.sub(PLACEHOLDER).displayBlock()
        itemList.forEach{ listItem->
            listItem.isSelect = defaultIdx == listItem.idx
            listItem.view?.className = setClassName(listItem)
        }
        value.inputValue(if(defaultIdx == -1) listOf() else listOf(defaultIdx))
    }
    suspend fun setInputValue(v:String) {
        target.sub(SELECT).html = v
    }
    protected fun setClassName():String = "$wrapperDefaultClass${if(wrapperClass.isBlank()) "" else " $wrapperClass"}" +
            if(isDisabled) " $DISABLED_CLASS"
            else if(isItemListOpen) " $FOCUSED_CLASS"
            else if(value.isOk) ""
            else " $ERROR_CLASS"
    protected fun setClassName(item:Item<V>):String{
        if(item.isSelect) eLaunch{
            target.sub(PLACEHOLDER).displayNone()
            target.sub(SELECT).html = item.label
        }
        return "$itemClass${if(item.isDisabled) " $DISABLED_CLASS" else if(item.isSelect) " $SELECTED_CLASS" else ""}"
    }
}






























