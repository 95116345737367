package router.join

import app.*
import compLabelInputSectionPasswordSet
import compLabelInputSectionTextSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import ein2b.js.crypto.eCrypto
import entity.EntClientJoin
import frm.api.member.EntApiMemberMasterJoinEmailStep1
import frm.api.member.EntApiMemberRsa
import frm.model.MdlMemberJoinParam
import frm.vali.*
import m42.model.HcInit
import org.w3c.dom.HTMLElement
import prop.compLabelInputSectionPassword
import prop.compLabelInputSectionText
import router.comp.CompMemberEmail
import router.comp.compMemberEmail

private val factory = Factory.htmlUrl("join")
private enum class K{
    step1, email, pw, pwConfirm, userName, phone, company, checkbox, submitBtn,
    step2, sendEmail,
    moveLoginBtn;
}
suspend fun JoinView() = eView(factory){ staticView->
    val model = EntClientJoin
    staticView.sub(K.step1)
    staticView.sub(K.step2)
    staticView.compLabelInputSectionTextSet(K.email, "r@아이디 (이메일 주소)@5/5/y", ValiMemAccountEmail){
        it.placeholder = "r@nameexample.com@5/5/z"
    }
    staticView.compLabelInputSectionPasswordSet(K.pw, "r@비밀번호@5/5/A", ValiMemAccountPw){
        it.placeholder = "r@8~32자, 영문/숫자/특수문자 중 2개 이상의 조합@5/5/B"
    }
    staticView.compLabelInputSectionPasswordSet(K.pwConfirm, "r@비밀번호 확인@5/5/C")
    staticView.compLabelInputSectionTextSet(K.userName, "r@이름@5/5/D", ValiMemAccountUserName, sectionWrapperClass = "flex2-item")
    staticView.compLabelInputSectionTextSet(K.phone, "r@연락처@5/5/E", ValiMemAccountPhone, sectionWrapperClass = "flex2-item")
    staticView.compLabelInputSectionTextSet(K.company, "r@회사명@5/5/F", ValiMemCoName)


    staticView.sub(K.submitBtn)

    CompMemberEmail(staticView, K.sendEmail){
        it.title = "r@인증 메일이 발송되었습니다.@5/5/G"
        it.contents = "r@이메일 주소로 인증 메일을 발송했습니다.<br>" +
                "메일함에서 인증 절차를 완료해 주세요.<br>" +
                "(인증 메일은 발송 후 20분간 유효합니다.)@5/5/H"
    }
    staticView.sub(K.moveLoginBtn).href = ALink(RouterKey.LOGIN).link

    staticView.addEntityHook(HcInit::class, object:eEntityHook<HTMLElement,HcInit>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: HcInit) {
            model.init()
            view.sub(K.step1).displayBlock()
            view.sub(K.step2).displayNone()
            view.clearCompValue(K.email, K.pw, K.pwConfirm, K.userName, K.phone, K.company)
            view.entity(model)
        }
    })
    staticView.addEntityHook(EntApiMemberRsa.Res::class, object:eEntityHook<HTMLElement, EntApiMemberRsa.Res>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntApiMemberRsa.Res) {
            view.sub(K.step1).displayNone()
            view.sub(K.sendEmail).compMemberEmail().setEmail(model.email)
            view.sub(K.step2).displayBlock()
        }
    })
    staticView.addEntityHook(EntClientJoin::class, object:eEntityHook<HTMLElement,EntClientJoin>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntClientJoin) {
            view.sub(K.submitBtn){
                it.click = {_,_ -> eLaunch {
                    val email = staticView.sub(K.email).compLabelInputSectionText()
                    val pw = staticView.sub(K.pw).compLabelInputSectionPassword()
                    val pwConfirm = staticView.sub(K.pwConfirm).compLabelInputSectionPassword()
                    val userName = staticView.sub(K.userName).compLabelInputSectionText()
                    val phone = staticView.sub(K.phone).compLabelInputSectionText()
                    val company = staticView.sub(K.company).compLabelInputSectionText()
                    if(CompApp.checkAll(email, pw, pwConfirm, userName, phone, company)){
                        if(pw.out() == pwConfirm.out()){
                            N.rsaGet()?.also {rsa->
                                N.joinStep1(MdlMemberJoinParam().also {req->
                                    req.email = email.out()
                                    req.pw = eCrypto.rsaEncrypt(pw.out(), rsa.rsa.key)
                                    req.companyName = company.out()
                                    req.phone = phone.out()
                                    req.userName = userName.out()
                                    req.rsaId = rsa.rsa.id
                                })?.also {res->
                                    if(res.isError){
                                        if(res.error.emailErrMsg.isNotEmpty()) email.changeError(res.error.emailErrMsg, false)
                                        if(res.error.companyErrMsg.isNotEmpty()) company.changeError(res.error.companyErrMsg, false)
                                    }else{
                                        model.email = email.out()
                                        staticView.entity(res)
                                    }
                                }
                            }
                        }else{
                            pwConfirm.changeError("r@비밀번호가 일치하지 않습니다.@5/5/I", false)
                        }
                    }
                } }
            }
        }
    })
    staticView.addEntityHook(EntApiMemberMasterJoinEmailStep1.Res::class, object:eEntityHook<HTMLElement,EntApiMemberMasterJoinEmailStep1.Res>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntApiMemberMasterJoinEmailStep1.Res) {
            view.sub(K.step1).displayNone()
            view.sub(K.sendEmail).compMemberEmail().setEmail(model.email)
            view.sub(K.step2).displayBlock()
        }
    })

}
