package router.event

import app.*
import comp.CompPageTitle
import comp.input.compToggleRadioSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import entity.EntClientEvent
import entity.EntClientEventCat
import enum.EnumClientOnOff
import frm.api.EntApiEventV
import m42.app.M42App
import m42.model.HcInit
import org.w3c.dom.HTMLElement
import router.subView.subCatInfo
import view.CompViewToast

private val factory = Factory.htmlUrl("event/event")
private val itemFactory = Factory.htmlUrl("event/item")
internal enum class K{
    addOpen, catInfo,
    cat_radioWrap, cat_radio, cat_condition, cat_condition1, cat_condition2, cat_value, cat_value1, cat_value2,
    empty, list, item_isActive, item_id, item_editOpen, item_cat, item_period, item_emailList, item_message, item_date,
    detail, form;
    override fun toString() = if ("_" in name) name.substring(name.lastIndexOf("_") + 1) else name
}
internal suspend fun submitEnd(msg:String, model:EntClientEvent){
    CompViewToast.setLocalStorage(msg)
    M42App.reload()
}
suspend fun EventView() = eView(factory){ staticView ->
    val model = EntClientEvent()
    CompPageTitle(staticView){
        it.title = "r@이벤트@5/8/2"
        it.backClick = { eLaunch{ hashManager.back() } }
    }
    staticView.sub(K.addOpen).click = { _,_->
        model.form.isOpen = true
        eLaunch{ staticView.sub(K.form).entity(model) }
    }
    subCatInfo(staticView, K.catInfo)
    M42App.emptySub(staticView, K.empty, K.list)
    subDetail(staticView, K.detail, model)
    subForm(staticView, K.form, model)
    staticView.addEntityHook(HcInit::class, object:eEntityHook<HTMLElement, HcInit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:HcInit){
            model.init()
        }
    })
    staticView.addEntityHook(EntApiEventV.Res::class, object:eEntityHook<HTMLElement, EntApiEventV.Res>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntApiEventV.Res){
            model.setData(entity)
            view.entity(model)
        }
    })
    staticView.addEntityHook(EntClientEvent::class, object:eEntityHook<HTMLElement, EntClientEvent>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntClientEvent){
            view.sub(K.catInfo).entity(entity.catInfo)

            val isEmpty = entity.list.isEmpty()
            view.sub(K.empty){
                if(isEmpty) {
                    it.displayBlock()
                } else {
                    it.displayNone()
                }
            }
            view.sub(K.list){ listView ->
                if(isEmpty) listView.displayNone() else listView.displayBlock()
                listView.setClearList{ listListView ->
                    entity.list.forEach{ eventData ->
                        val cmd = eventData.cmd
                        listListView += eView(itemFactory){ itemView ->
                            itemView.compToggleRadioSet<Boolean>(K.item_isActive){ comp ->
                                comp.wrapperClass = "toggle-list"
                                comp.afterInited = {
                                    comp.setList{
                                        EnumClientOnOff.entries.map{ enum ->
                                            comp.item(enum.title, enum.value, enum.value == cmd.isActive)
                                        }
                                    }
                                }
                                comp.checkBlock = { idx ->
                                    val isActive = comp.itemList[idx].value
                                    eLaunch{
                                        N.cmdIsActive(eventData.cmd.cat2ItemEventCmdRowid, isActive)?.also{
                                            submitEnd("r@이벤트 활성화 상태가 변경되었습니다.@5/8/I", entity)
                                        }
                                    }
                                }
                            }
                            itemView.sub(K.item_id).html = "${cmd.cat2ItemEventCmdRowid}"
                            itemView.sub(K.item_editOpen).click = { _, _->
                                eLaunch{
                                    N.cmdGet(cmd.cat2ItemEventCmdRowid)?.also{
                                        entity.form.setData(it.cat2ItemEventCmd)
                                        eLaunch{ view.sub(K.form).entity(entity) }
                                    }
                                }
                            }
                            when(eventData.cat){
                                is EntClientEventCat.One -> subCatOne(itemView, K.item_cat, entity, eventData.cat as EntClientEventCat.One)
                                is EntClientEventCat.Two -> subCatTwo(itemView, K.item_cat, entity, eventData.cat as EntClientEventCat.Two)
                            }
                            itemView.sub(K.item_period){
                                it.attr("v0" to cmd.period)
                                it.html = "r@${cmd.period} 초 주기@5/8/j"
                            }
                            itemView.sub(K.item_emailList).html = cmd.emailList
                            itemView.sub(K.item_message).html = cmd.message
                            itemView.sub(K.item_date).html = App.datePart(cmd.regDate)
                        }
                    }
                }
            }
            view.sub(K.detail).entity(entity)
            view.sub(K.form).entity(entity)
        }
    })
}