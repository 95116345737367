package router.homeLogin

import app.Api
import app.Factory
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.displayBlock
import ein2b.core.view.displayNone
import ein2b.core.view.eEntityHook
import ein2b.core.view.eView
import frm.api.member.EntApiMemberRsa
import m42.model.HcInit
import org.w3c.dom.HTMLElement
import router.subView.subHome
import router.subView.subLogin

private val factory = Factory.htmlUrl("homeLogin")
private enum class K{ home, login, loginForm }
suspend fun HomeLoginView() = eView(factory){ staticView->
    subHome(staticView, K.home)
    staticView.sub(K.login)
    subLogin(staticView, K.loginForm){
        eLaunch{
            staticView.entity(it)
        }
    }
    staticView.addEntityHook(HcInit::class, object:eEntityHook<HTMLElement, HcInit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:HcInit){
            view.sub(K.home).displayNone()
            view.sub(K.login).displayNone()
            view.sub(K.loginForm).entity(entity)
        }
    })
    staticView.addEntityHook(EntApiMemberRsa.Res::class, object:eEntityHook<HTMLElement, EntApiMemberRsa.Res>{
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntApiMemberRsa.Res){
            view.sub(K.home){
                if(Api.isLogin) it.displayBlock() else it.displayNone()
            }
            view.sub(K.login){
                if(Api.isLogin) it.displayNone() else it.displayBlock()
            }
            view.sub(K.loginForm).entity(entity)
        }
    })
}