package frm.model

import ein2b.core.entity.eEntity
import frm.vali.ValiViewItemTitle
import frm.vali.ValiViewName
import frm.vali.ValiViewUnit

open class MdlViewParam:eEntity(true){
    var title by string{ validator(ValiViewName) }
    var viewItemList by entityList(::MdlViewItemParam)
}
class MdlViewAddParam:MdlViewParam(){
    var cat1Rowid by long
}

class MdlViewEditParam:MdlViewParam(){
    var viewRowid by long
    var _cat1Rowid by long{ encoding.isExcluded() }
}

class MdlViewItemParam:eEntity(true){
    var viewItemRowid by long{ default(0L) } //add edit 같이
    var cat2ItemRowid by long
    var viewDecoratorRowid by long
    var title by string{ validator(ValiViewItemTitle) }
    var unit by string{ validator(ValiViewUnit) }
    var _viewRowid by long{ encoding.isExcluded() }
}