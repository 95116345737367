package router.cat1Edit

import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import m42.app.M42App
import m42.common.model.MdlM42ApiRowid
import m42.domBase.PageBase
import org.w3c.dom.HTMLElement

class Cat1EditP(router:eRouter<HTMLElement>):PageBase(::Cat1EditView2, router){
    override suspend fun net(data:eRouterData){
        data.parse(MdlM42ApiRowid())?.also{
            N.editReady(it.r.toLong())?.also{ res ->
                view?.entity(res)
            }
        } ?: M42App.routerError()
    }
}