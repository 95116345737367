package router.subView

import app.*
import ein2b.core.view.eEntityHook
import ein2b.core.view.eView
import ein2b.core.view.html
import entity.EntClientCatInfo
import m42.app.M42App
import m42.model.HcInit
import org.w3c.dom.HTMLElement

private val factory = Factory.htmlUrl("subView/catInfo")
private enum class CAT{ catTitle, cat2ItemTitle, valueDate }
suspend fun subCatInfo(staticView:eView<HTMLElement>, subKey:Any) = staticView.sub(subKey, factory){ subView ->
    M42App.emptySub(subView, CAT.catTitle, CAT.cat2ItemTitle, CAT.valueDate)
    subView.addEntityHook(EntClientCatInfo::class, object:eEntityHook<HTMLElement, EntClientCatInfo>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntClientCatInfo){
            view.sub(CAT.catTitle).html = entity.catTitle
            view.sub(CAT.cat2ItemTitle).html = entity.cat2ItemTitle
            view.sub(CAT.valueDate).html = entity.valueDate
        }
    })
}