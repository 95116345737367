package frm.vali

import ein2b.core.validation.eRuleSet
import ein2b.core.validation.eRuleVali

val ValiEventCmdPeriod = eRuleVali{
    Case{
        String()
        Trim()
        MinLength(1, "r@재발송 주기를 입력해 주세요.@5/8/t")
        IntNum("r@재발송 주기는 숫자만 입력 가능합니다.@5/8/u")
    }
}
val ValiEventCmdExcecutePeriod = eRuleVali{
    Case{
        String()
        Trim()
        MinLength(1, "r@적용 기준일을 입력해 주세요.@5/8/L")
        IntNum("r@적용 기준일은 숫자만 입력 가능합니다.@5/8/M")
    }
}
private val REG_EMAIL = """^[0-9a-zA-Z-_.]+@[0-9a-zA-Z-.]+(?:[.]+[A-Za-z]{2,4})+$""".toRegex()
val ValiEventCmdEmailList = eRuleVali{
    Case{
        String()
        Trim()
        MinLength(1, "r@이메일 리스트를 입력해 주세요.@5/8/v")
        Rule("r@이메일 형식을 확인해 주세요.@5/8/w"){ v, _->
            var isCheck = true
            (v as String).split(",").forEach{ email ->
                if(!REG_EMAIL.matches(email)) isCheck = false
            }
            if(isCheck) v else eRuleSet.FALSE
        }
    }
}
val ValiEventCmdMessage = eRuleVali{
    Case{
        String()
        Trim()
        MinLength(1, "r@메시지를 입력해 주세요.@5/8/x")
    }
}
val ValiEventMemo = eRuleVali{
    Case{
        String()
        Trim()
        MinLength(1, "r@메모를 입력해 주세요.@5/8/E")
    }
}