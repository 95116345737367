package frm.model

import ein2b.core.entity.eEntity

open class MdlCat1:eEntity(true){
    var cat1Rowid by long
    var title by string
    var cat2List by entityList(::MdlCat2)
}
class MdlCat2:eEntity(true){
    var cat1Rowid by long
    var cat2Rowid by long
    var title by string
    var cat2ItemList by entityList(::MdlCat2Item)
}
class MdlCat2Item:eEntity(true){
    var cat2Rowid by long
    var cat2ItemRowid by long{
        default(0)
    }
    var hex2TypeRowid by long{
        default(0L)
    }
    var key by string
    var value by string
    var title by string
    var updateDate by utc
    var eventCnt by int
    var isLogging by boolean
    var logMaxStorageDay by int{
        default(10)
    }

    var isSelected = false
    var hasEvent by boolean{
        default(false)
    }
}