package router.joinComplete

import app.ClientApiMemberMasterJoinEmailStep2
import frm.api.member.EntApiMemberMasterJoinEmailStep2

object N{
    suspend fun joinComplete2(token:String, actionToken:String):EntApiMemberMasterJoinEmailStep2.Res? = ClientApiMemberMasterJoinEmailStep2.net{ req->
        req.token = token
        req.actionToken = actionToken
    }
}