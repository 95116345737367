package frm.vali

import ein2b.core.validation.eRuleVali

val ValiCat1Title = eRuleVali{
    Case{
        String()
        Trim()
        MinLength(1, "r@출처명을 입력해 주세요.@5/3/g")
        MaxLength(50, "r@출처명은 50자 이내로 입력해 주세요.@5/3/h")
    }
}