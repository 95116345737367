package router.actionToken

import app.Api
import app.ClientApiMemberActionToken
import app.RouterKey
import app.hashManager
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import entity.EmailToken
import m42.app.M42App
import m42.common.M42ApiSetting
import m42.domBase.PageBase
import org.w3c.dom.HTMLElement

class ActionTokenP(router:eRouter<HTMLElement>):PageBase(::ActionTokenView, router){
    override suspend fun net(data:eRouterData){
        data.parse(EmailToken())?.also{ url ->
            ClientApiMemberActionToken.net{ req->
                req.actionToken = url.token
            }?.also{ res->
                val actionUrl = res.url
                //1. 로그아웃 상태일 때 - 로그인 페이지로 이동 로그인 후에는 목적지로 이동
                if(Api.isLogin){
                    //2. 동일한 계정으로 로그인 상태일 때 - 기존 로그인 유지하고 목적지 이동
                    //3. 다른 계정으로 로그인 상태일 때 - 기존 로그인 유지하고 목적지 이동하면 본인 권한의 페이지가 아니므로 에러를 발생
                    M42App.changeUrl(actionUrl)
                }else{
                    M42ApiSetting.apiAfterBlock = { M42App.changeUrl(actionUrl) }
                    hashManager.goUrl(RouterKey.LOGIN)
                }
            }
        }
    }
}