package m42.common

import ein2b.core.coroutine.eLaunch
import ein2b.core.entity.eEntity
import ein2b.core.net.eApi
import ein2b.core.net.eApiInfo
import ein2b.core.net.eResponseTask
import ein2b.core.resource.Resource
import ein2b.core.resource.Rsc
import ein2b.js.browser.eSessionStorage
import kotlinx.browser.window
import m42.resource.model.MdlS3CategoryData

object M42ApiResource:Resource.Cache{
    var isLocalHost = window.location.port.isNotBlank()

    private const val CATEGORY_KEY = "resource/category"
    private var s3Url = window.location.origin
    private var categoryMap = mutableMapOf<String, String>()
    private var s3CategoryData = MdlS3CategoryData()

    private val URL get() = "$s3Url/resource"
    private var apiMap = mutableMapOf<String, eApi>()

    private val _waitList:HashMap<String, MutableList<Pair<String, (Any)->Unit>>> = hashMapOf()
    private val _map:HashMap<String, Rsc> = hashMapOf()
    fun init(url:String = "", vararg cat:Pair<String,String>, callBack:()->Unit){
        if(Resource.cache == null) Resource.cache = this
        if(url.isNotBlank()) s3Url = url
        setCategory(*cat)

        eSessionStorage[CATEGORY_KEY]?.also{
            setS3CategoryData(it)
            callBack()
        } ?:also {
            val apiUrl = "${URL}/category"
            //로딩 후, 세션스토리지에 써준 뒤 블럭을 호출
            if(isLocalHost){
                window.fetch(apiUrl).then{ response ->
                    if(response.ok && response.status == 200.toShort()){
                        response.text().then{ body ->
                            categoryApiEnd(body)
                            callBack()
                        }
                    }
                }
            }else{
                eLaunch{
                    eApi(apiUrl, eApi.DEFAULT to eApiInfo{
                        method = eApi.GET
                        this.url = apiUrl
                        responseTask += eResponseTask.Text
                    })().also{ result ->
                        if(result.isOk) (result.response!!.result as? String)?.also{ body ->
                            categoryApiEnd(body)
                            callBack()
                        }
                    }
                }
            }
        }
    }
    private fun categoryApiEnd(body:String){
        eSessionStorage[CATEGORY_KEY] = body
        setS3CategoryData(body)
    }
    private fun setS3CategoryData(body:String){
        eEntity.parse(MdlS3CategoryData(), body)?.also{
            s3CategoryData = it
        }
    }
    fun setCategory(vararg cat:Pair<String,String>){
        cat.forEach{ (k, v) -> categoryMap[k] = v }
    }

    private fun pageCatKey(k:String):String{
        val keys = k.split("/")
        val categoryKey = keys.first()
        return s3CategoryData.categoryList.find{
            it.key == categoryKey
        }?.let{
            val list = mutableListOf<String>()
            it.itemList.forEach{ s3CategoryItem ->
                categoryMap[s3CategoryItem.key]?.also{ cat ->
                    list.add(cat)
                }
            }
            "$k/${list.joinToString("-")}"
        } ?: ""
    }
    private fun parseLoad(v:String){
        eEntity.parse(Resource.Data(), v){
            console.log("========= parseLoad report0 =========")
            console.log(v)
            console.log("report::", it.message)
            console.log("========= parseLoad report1 =========")
        }?.also{
            Resource.load(it)
        }
    }
    private fun value(k:String):Any{
        //서브키가 없으면 invalid value
        return _map[k]?.let{ _value(it) } ?: Resource.NO_KEY
    }
    private fun apiEnd(key:String, body:String){
        eSessionStorage[key] = body
        parseLoad(body)

        _waitList[key]?.forEach{ (wK, wBlock) -> wBlock(value(wK)) }
        _waitList.remove(key)
    }

    override fun set(k:String, rsc:Rsc){ _map[k] = rsc }
    override fun get(k:String, block:(Any)->Unit):Any?{
        if(k.isBlank()) return null

        val key = pageCatKey(k.substring(0, k.lastIndexOf('/')))
        if(key.isBlank()) return null

        //키를 분해하여 페이지와 캣 조합으로 세션스토리지 확인 _map
        return eSessionStorage[key]?.let{
            parseLoad(it)
            //있으면 서브키를 마져 해당 맵으로 찾은뒤 반환
            value(k)
        }?:let{
            if(!_waitList.contains(key)){
                _waitList[key] = mutableListOf()

                val apiUrl = "$URL/$key"
                //로딩 후, 세션스토리지에 써준 뒤 블럭을 호출
                if(isLocalHost){
                    window.fetch(apiUrl).then{ response ->
                        if(response.ok && response.status == 200.toShort()){
                            response.text().then{ body -> apiEnd(key, body) }
                        }
                    }
                }else{
                    if(!apiMap.containsKey(key)){
                        apiMap[key] = eApi(key, eApi.DEFAULT to eApiInfo{
                            method = eApi.GET
                            url = apiUrl
                            responseTask += eResponseTask.Text
                        })
                    }
                    eLaunch{
                        apiMap[key]!!().also{ result ->
                            if(result.isOk) (result.response!!.result as? String)?.also{ body -> apiEnd(key, body) }
                        }
                    }
                }
            }
            _waitList[key]!! += k to block

            //없으면 기본값 반환
            null
        }
    }
}