package router.viewDecorator

import app.*

object N{
    suspend fun get(p:Long) = ClientApiViewDecoratorE.net{ req ->
        req.p = p
    }
    suspend fun edit(title:String, js:String, rowid:Long=0L) = ClientApiViewDecoratorEp.net{ req->
        req.viewDecoratorRowid = rowid
        req.title = title
        req.js = js
    }
}