package router.masterEdit

import app.ClientApiMemberMasterE
import app.ClientApiMemberMasterEp
import app.ClientApiMemberMasterSecretKeyEp
import frm.api.member.EntApiMemberMasterE
import frm.api.member.EntApiMemberMasterEp
import m42.common.api.EntM42Api.Companion.FILE_KEY
import org.w3c.files.Blob

object N {
    suspend fun editReady(): EntApiMemberMasterE.Res? = ClientApiMemberMasterE.net()
    suspend fun edit(
        userName:String, phone:String, profileUrl:String, companyName:String, profileFile:Blob
    ):EntApiMemberMasterEp.Res?{
        return ClientApiMemberMasterEp.net(FILE_KEY to profileFile){ req->
            req.phone = phone
            req.profileUrl = profileUrl
            req.companyName = companyName
            req.userName = userName
        }
    }

    suspend fun editSecretKey():EntApiMemberMasterEp.Res? = ClientApiMemberMasterSecretKeyEp.net()
}