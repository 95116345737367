package router.viewForm

import app.RouterKey
import app.hashManager
import ein2b.core.view.eEntityHook
import ein2b.core.view.eView
import frm.api.EntApiViewE
import frm.model.MdlViewEditParam
import org.w3c.dom.HTMLElement
import view.CompViewAlert

suspend fun ViewEditView() = ViewFormView("r@뷰 수정@5/6/g","r@수정@5/4/k", submit = {param->
    N.edit(param as MdlViewEditParam)?.also{ res ->
        CompViewAlert.open("r@뷰가 수정되었습니다. 상세 페이지로 이동합니다.@5/6/i"){
            hashManager.goUrl(RouterKey.VIEW_DETAIL, "r" to res.viewRowid)
        }
    }
}){staticView, model ->
    staticView.addEntityHook(EntApiViewE.Res::class, object:eEntityHook<HTMLElement, EntApiViewE.Res> {
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntApiViewE.Res){
            model.init()
            model.editSetData(entity)
            view.entity(model)
        }
    })
}