package entity

import app.App
import ein2b.core.date.eUtc
import ein2b.core.entity.eEntity
import ein2b.core.view.eView
import frm.api.EntApiCat1E
import frm.api.EntApiStorageConfigEp
import frm.enum.EnumRateCat
import frm.enum.EnumStorageCat
import frm.model.MdlS3Config
import frm.model.MdlStorageConfig
import m42.model.ViewModel
import org.w3c.dom.HTMLElement

class EntClientStorage:ViewModel(){
    var cat1Rowid = 0L
    var cat1Title = ""
    var configList = mutableListOf<EntStorageConfig>()
    class EntStorageConfig{
        var view: eView<HTMLElement>?=null
        var configRowid = 0L
        var rateCat:EnumRateCat? = null
        var storageCat:EnumStorageCat? = null
        var accessKey = ""
        var secretKey = ""
        var bucket = ""
        var region = ""
        var prefix = ""
        var logMaxStorageDay = 10
        var isActive = false
        var isDelete = false
        var lastUpdated = ""
    }

    fun init(){
        cat1Rowid = 0L
        cat1Title = ""
        configList = mutableListOf()
    }
    fun setData(target: EntApiStorageConfigEp.Res){
        cat1Rowid = target.cat1.cat1Rowid
        cat1Title = target.cat1.title
        configList = target.storageConfigList.map {from->
            val s3Config = parse(MdlS3Config(), from.data) ?: MdlS3Config()
            EntStorageConfig().also {to->
                to.rateCat = from.rateCat
                to.storageCat = from.storageCat
                to.accessKey = s3Config.accessKey
                to.secretKey = s3Config.secretKey
                to.bucket = s3Config.bucket
                to.prefix = s3Config.prefix
                to.region = s3Config.region
                to.isActive = from.isActive
                to.isDelete = from.isDelete
                to.logMaxStorageDay = from.logMaxStorageDay
                to.configRowid = from.storageConfigRowid
                to.lastUpdated = if(from.lastUpdated== eUtc.default()) "" else App.datePart(from.lastUpdated)
            }
        }.toMutableList()

    }
    fun addForm(cat:EnumStorageCat){
        configList += EntStorageConfig().also {
            it.storageCat = cat
        }
    }
    fun setData(target:EntApiCat1E.Res){
        val cat1 = target.cat1
        cat1Rowid = cat1.cat1Rowid
        cat1Title = cat1.title
    }
}