package router.viewDecorator

import CompLabelInputSection
import app.CompApp
import app.Factory
import app.clearCompValue
import comp.Comp
import comp.CompValueOut
import comp.OutType
import comp.input.CompInputText
import comp.input.CompTextarea
import compLabelInputSectionTextAreaSet
import compLabelInputSectionTextSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.click
import ein2b.core.view.eView
import ein2b.core.view.html
import entity.EntClientViewDecorator
import org.w3c.dom.HTMLElement
import prop.compLabelInputSectionText
import prop.compLabelInputSectionTextArea

class CompViewDecoratorForm:Comp,CompValueOut<EntClientViewDecorator.ViewDecorator>{
    private enum class K{ title, js, submit }
    companion object{
        private val factory = Factory.htmlUrl("viewDecorator/form")
        suspend operator fun invoke(rootView:eView<HTMLElement>, subKey:Any, block:(CompViewDecoratorForm)->Unit){
            val comp = CompViewDecoratorForm()
            block(comp)
            rootView.sub(subKey, factory){
                it.compLabelInputSectionTextSet(K.title, "r@컨버터 명@5/b/3", isInline = true, sectionWrapperClass = "flex-grow-1"){ title ->
                    title.changeBlock = { v ->
                        comp.mdl.title = v
                        v
                    }
                }.also {compInputSectionText->
                    comp.compTitle = compInputSectionText
                }
                it.compLabelInputSectionTextAreaSet(K.js, "r@컨버터 스크립트@5/b/4", isInline = true, sectionWrapperClass = "margin-top15"){ js ->
                    js.changeBlock = { v ->
                        comp.mdl.js = v
                        v
                    }
                }.also {compInputSectionTextarea->
                    comp.compJs = compInputSectionTextarea
                }
                it.sub(K.submit){ submitView ->
                    submitView.html = comp.btnLabel
                    submitView.click = { _,_-> eLaunch{ comp.submitBlock() } }
                }
                comp.target = it
                comp.mdlRender()
                comp.baseProp(it, true)
            }
        }
    }
    private lateinit var target:eView<HTMLElement>
    lateinit var submitBlock:suspend ()->Unit
    var btnLabel = "r@추가@5/b/2"
    val mdl = EntClientViewDecorator.ViewDecorator()
    private lateinit var compTitle:CompLabelInputSection<String,String,String,CompInputText>
    private lateinit var compJs:CompLabelInputSection<String,String,String,CompTextarea>

    suspend fun mdlRender(){
        target.sub(K.title).compLabelInputSectionText{ title ->
            title.inputValue(mdl.title)
        }
        target.sub(K.js).compLabelInputSectionTextArea{ js ->
            js.inputValue(mdl.js)
        }
    }

    override val outs:HashMap<OutType, suspend ()->EntClientViewDecorator.ViewDecorator> = hashMapOf(OutType.DEFAULT to { mdl })
    override suspend fun clear(){
        mdl.init()
        target.clearCompValue(K.title, K.js)
    }
    override fun check() = CompApp.checkAll(compTitle, compJs)

}
suspend fun eView<HTMLElement>.compViewDecoratorFormSet(subKey:Any, block:(CompViewDecoratorForm)->Unit = {}){
    CompViewDecoratorForm(this, subKey, block)
}