package router.viewDetail

import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import m42.common.model.MdlM42ApiRowid
import m42.domBase.PageBase
import org.w3c.dom.HTMLElement

class ViewDetailP(router:eRouter<HTMLElement>):PageBase(::ViewDetail, router){
    override suspend fun net(data:eRouterData){
        data.parse(MdlM42ApiRowid())?.also {
            N.view(it.r.toLong())?.also { res ->
                view?.entity(res)
            }
        }
    }
}