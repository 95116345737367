package frm.api

import ein2b.core.entity.eEntity
import frm.model.MdlCat2EpParam
import m42.common.enum.EnumApiAccessAllow

object EntApiCat2Wp:EntFrmApi<MdlCat2EpParam, EntApiCat1E.Res>(EntApiCat1E.Res::class, ::MdlCat2EpParam, EntApiCat1E::Res){
    const val PATH = "${API_CAT2_PATH}/wp"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow = EnumApiAccessAllow.LOGIN
    override var isMenu = true
}

object EntApiCat2Dp:EntFrmApi<EntApiCat2Dp.Req, EntApiCat1E.Res>(EntApiCat1E.Res::class, ::Req, EntApiCat1E::Res){
    const val PATH = "${API_CAT2_PATH}/dp"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow = EnumApiAccessAllow.LOGIN
    override var isMenu = true
    class Req:eEntity(true){
        var cat1Rowid by long
        var cat2Rowid by long
    }
}