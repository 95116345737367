package router.pwSearch

import app.*
import compLabelInputSectionTextSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import entity.EntClientPwSearch
import frm.api.member.EntApiMemberPwEmailE1
import frm.vali.ValiMemAccountEmail
import m42.model.HcInit
import org.w3c.dom.HTMLElement
import prop.compLabelInputSectionText
import router.comp.CompMemberEmail
import router.comp.compMemberEmail

private val factory = Factory.htmlUrl("pwSearch")
private enum class K{ step1, email, step2, submitBtn, moveLoginBtn }
suspend fun PwSearchView() = eView(factory){ staticView->
    val model = EntClientPwSearch
    staticView.sub(K.step1)
    CompMemberEmail(staticView, K.step2){
        it.title = "r@비밀번호 재설정 메일이 발송되었습니다.@5/5/1w"
        it.contents = "r@이메일 주소로 비밀번호 재설정 메일을 발송했습니다.<br>메일함에서 비밀번호 재설정을 완료해 주세요.<br>(비밀번호 재설정 메일은 발송 후 20분간 유효합니다.)@5/5/1x"
    }
    staticView.compLabelInputSectionTextSet(K.email, "r@아이디 (이메일 주소)@5/5/1y", ValiMemAccountEmail){
        it.placeholder = "r@nameExample.com@5/5/1z"
    }
    staticView.sub(K.submitBtn).click = {_,_ ->
        eLaunch {
            val email = staticView.sub(K.email).compLabelInputSectionText()
            if(email.check()){
                N.emailE1(email.out())?.also {res->
                    /** 비밀번호 찾기 - 1단계	비밀번호 재설정 메일 발송 버튼	클릭 시	서버 통신 - 실패 - 아이디 - [컴포넌트] CompLabelInputSection - CompInputText	가입되지 않은 아이디인 경우	    가입되지 않은 아이디입니다. member/server/vali/id/01 */
                    /** 비밀번호 찾기 - 1단계	비밀번호 재설정 메일 발송 버튼	클릭 시	서버 통신 - 실패 - 아이디 - [컴포넌트] CompLabelInputSection - CompInputText	가입 인증 절차를 완료하지 않은 아이디인 경우	가입 인증 절차를 완료하지 않은 아이디입니다. 인증을 완료해 주세요.  member/server/vali/id/02 */
                    if(res.isError) email.changeError(res.errorMsg, false)
                    else{
                        /** 비밀번호 찾기 - 1단계	비밀번호 재설정 메일 발송 버튼	클릭 시	서버 통신 - 성공  모델에 이메일 설정 */
                        model.email = email.out()
                        /** 비밀번호 찾기 - 1단계	비밀번호 재설정 메일 발송 버튼	클릭 시	서버 통신 - 성공  페이지 리렌더 */
                        staticView.entity(res)
                    }
                }
            }
        }
    }
    /** 비밀번호 찾기    로그인 페이지 이동 버튼    클릭 시    로그인 페이지로 이동 */
    staticView.sub(K.moveLoginBtn).href = ALink(RouterKey.LOGIN).link

    staticView.addEntityHook(HcInit::class, object:eEntityHook<HTMLElement, HcInit>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: HcInit) {
            /** 비밀번호 찾기 	초기화   모델 초기화*/
            model.init()
            /** 비밀번호 찾기 	초기화	아이디		view.clearCompValue	[컴포넌트] CompLabelInputSection - CompInputText */
            view.clearCompValue(K.email)
            /** 비밀번호 찾기     초기화   비밀번호 찾기 - 1단계 표시 */
            view.sub(K.step1).displayBlock()
            /** 비밀번호 찾기     초기화   비밀번호 찾기 - 2단계 표시 안함 */
            view.sub(K.step2).displayNone()
        }
    })

    staticView.addEntityHook(EntApiMemberPwEmailE1.Res::class, object:eEntityHook<HTMLElement,EntApiMemberPwEmailE1.Res>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntApiMemberPwEmailE1.Res) {
            /** 비밀번호 찾기 - 1단계	비밀번호 재설정 메일 발송 버튼	클릭 시	서버 통신 - 성공  비밀번호 찾기 - 1단계 표시 안함 */
            view.sub(K.step1).displayNone()
            /** 비밀번호 찾기 - 1단계	비밀번호 재설정 메일 발송 버튼	클릭 시	서버 통신 - 성공  비밀번호 찾기 - 2단계 표시 */
            view.sub(K.step2).displayBlock()
            /** 비밀번호 찾기 - 1단계	비밀번호 재설정 메일 발송 버튼	클릭 시	서버 통신 - 성공  CompMemberEmail - email  모델의 email 설정 */
            view.sub(K.step2).compMemberEmail().setEmail(model.email)
        }
    })
}
