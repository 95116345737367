package frm.vali

import ein2b.core.validation.eRuleVali

val ValiViewName = eRuleVali{
    Case{
        String()
        Trim()
        MinLength(1, "r@뷰 이름을 입력해 주세요.@5/6/l")
        MaxLength(50, "r@뷰 이름을 50자 이내로 입력해 주세요.@5/6/c")
    }
}
val ValiViewItemTitle = eRuleVali{
    Case{
        String()
        Trim()
        MaxLength(50, "r@뷰 타이틀을 50자 이내로 입력해 주세요.@5/6/t")
    }
}
val ValiViewUnit = eRuleVali{
    Case{
        String()
        Trim()
        MaxLength(10, "r@단위를 10자 이내로 입력해 주세요.@5/6/e")
    }
}