package frm.vali

import ein2b.core.validation.eRuleVali

val ValiCat2Title = eRuleVali{
    Case{
        String()
        Trim()
        MinLength(1, "r@구분명을 입력해 주세요.@5/3/i")
        MaxLength(50, "r@구분명은 50자 이내로 입력해 주세요.@5/3/j")
    }
}

val ValiCat2ItemValue = eRuleVali{
    Case{
        String()
        Trim()
        MinLength(1, "r@값을 입력해 주세요.@5/3/k")
        MaxLength(50, "r@값은 50자 이내로 해주세요.@5/3/l")
    }
}

val ValiCat2ItemTitle = eRuleVali{
    Case{
        String()
        Trim()
        MinLength(1, "r@타이틀을 입력해 주세요.@5/3/m")
        MaxLength(50, "r@타이틀은 50자 이내로 해주세요.@5/3/n")
    }
}