package app

import frm.api.*
import frm.api.member.*
import frm.model.*

//------------ 계정
object ClientApiMemberRsa:ClientApi<EntApiMemberRsa.Req, EntApiMemberRsa.Res>(EntApiMemberRsa)
object ClientApiMemberLoginEmail:ClientApi<EntApiMemberLoginEmail.Req, EntApiMemberLoginEmail.Res>(EntApiMemberLoginEmail)
object ClientApiMemberLoginCheck:ClientApi<EntApiMemberLoginCheck.Req, EntApiMemberLoginCheck.Res>(EntApiMemberLoginCheck)
object ClientApiMemberLogout:ClientApi<EntApiMemberLogout.Req, EntApiMemberLogout.Res>(EntApiMemberLogout)
object ClientApiMemberPwEp:ClientApi<EntApiMemberPwEp.Req, EntApiMemberPwEp.Res>(EntApiMemberPwEp)
object ClientApiMemberPwEmailE1:ClientApi<EntApiMemberPwEmailE1.Req, EntApiMemberPwEmailE1.Res>(EntApiMemberPwEmailE1)
object ClientApiMemberPwEmailE2:ClientApi<EntApiMemberPwEmailE2.Req, EntApiMemberPwEmailE2.Res>(EntApiMemberPwEmailE2)
object ClientApiMemberPwEmailEp:ClientApi<EntApiMemberPwEmailEp.Req, EntApiMemberPwEmailEp.Res>(EntApiMemberPwEmailEp)
object ClientApiMemberSessionCheck:ClientApi<EntApiMemberSessionCheck.Req, EntApiMemberSessionCheck.Res>(EntApiMemberSessionCheck)
object ClientApiMemberActionToken:ClientApi<EntApiMemberActionToken.Req, EntApiMemberActionToken.Res>(EntApiMemberActionToken)
//------------- 마스터
object ClientApiMemberMasterJoinEmailStep1:ClientApi<MdlMemberJoinParam, EntApiMemberMasterJoinEmailStep1.Res>(EntApiMemberMasterJoinEmailStep1)
object ClientApiMemberMasterJoinEmailStep2:ClientApi<EntApiMemberMasterJoinEmailStep2.Req, EntApiMemberMasterJoinEmailStep2.Res>(EntApiMemberMasterJoinEmailStep2)
object ClientApiMemberMasterE:ClientApi<EntApiMemberMasterE.Req, EntApiMemberMasterE.Res>(EntApiMemberMasterE)
object ClientApiMemberMasterEp:ClientApi<MdlMemberEditParam, EntApiMemberMasterEp.Res>(EntApiMemberMasterEp)
object ClientApiMemberMasterSecretKeyEp:ClientApi<EntApiMemberMasterSecretKeyEp.Req, EntApiMemberMasterEp.Res>(EntApiMemberMasterSecretKeyEp)

//------------ 출처
object ClientApiCat1Wp:ClientApi<MdlCat1Param, EntApiCat1Wp.Res>(EntApiCat1Wp)
object ClientApiCat1E:ClientApi<EntApiCat1E.Req, EntApiCat1E.Res>(EntApiCat1E)
object ClientApiCat1Ep:ClientApi<MdlCat1EpParam, EntApiCat1E.Res>(EntApiCat1Ep)
object ClientApiCat2Ep:ClientApi<MdlCat2EpParam, EntApiCat1E.Res>(EntApiCat2Wp)
object ClientApiCat2Dp:ClientApi<EntApiCat2Dp.Req, EntApiCat1E.Res>(EntApiCat2Dp)

//------------ 출처 뷰
object ClientApiViewW:ClientApi<EntApiViewW.Req, EntApiViewW.Res>(EntApiViewW)
object ClientApiViewE:ClientApi<EntApiViewE.Req, EntApiViewE.Res>(EntApiViewE)
object ClientApiViewWp:ClientApi<MdlViewAddParam, EntApiViewWp.Res>(EntApiViewWp)
object ClientApiViewEp:ClientApi<MdlViewEditParam, EntApiViewEp.Res>(EntApiViewEp)
object ClientApiViewV:ClientApi<EntApiViewV.Req, EntApiViewV.Res>(EntApiViewV)

//------------ 출처 로그
object ClientApiCat2ItemLogE:ClientApi<EntApiCat2ItemLogE.Req, EntApiCat2ItemLogE.Res>(EntApiCat2ItemLogE)
object ClientApiCat2ItemLogEp:ClientApi<EntApiCat2ItemLogEp.Req, EntApiCat2ItemLogE.Res>(EntApiCat2ItemLogEp)
object ClientApiCat2ItemStorageDayEp:ClientApi<EntApiCat2ItemLogStorageDayEp.Req, EntApiCat2ItemLogE.Res>(EntApiCat2ItemLogStorageDayEp)

//------------ 출처 이벤트
object ClientApiEventV:ClientApi<EntApiEventV.Req, EntApiEventV.Res>(EntApiEventV)
object ClientApiEventCmdWp:ClientApi<MdlCat2ItemEventCmdParam, EntApiEmpty>(EntApiEventCmdWp)
object ClientApiEventCmdE:ClientApi<EntApiEventCmdE.Req, EntApiEventCmdE.Res>(EntApiEventCmdE)
object ClientApiEventCmdEp1:ClientApi<MdlCat2ItemEventCmdParam, EntApiEmpty>(EntApiEventCmdEp1)
object ClientApiEventCmdEp2:ClientApi<EntApiEventCmdEp2.Req, EntApiEmpty>(EntApiEventCmdEp2)
object ClientApiEventEp:ClientApi<EntApiEventEp.Req, EntApiEmpty>(EntApiEventEp)

//------------ 수집기
object ClientApiStorageConfigE:ClientApi<EntApiStorageConfigE.Req, EntApiStorageConfigEp.Res>(EntApiStorageConfigE)
object ClientApiStorageConfigEp:ClientApi<MdlStorageConfigEpParam, EntApiStorageConfigEp.Res>(EntApiStorageConfigEp)

//------------ 뷰 데코레이터
object ClientApiViewDecoratorE:ClientApi<EntApiViewDecoratorE.Req, EntApiViewDecoratorE.Res>(EntApiViewDecoratorE)
object ClientApiViewDecoratorEp:ClientApi<MdlViewDecorator, EntApiViewDecoratorEp.Res>(EntApiViewDecoratorEp)

//------------ Hex2Type
object ClientApiHex2TypeE:ClientApi<EntApiHex2TypeE.Req, EntApiHex2TypeE.Res>(EntApiHex2TypeE)
object ClientApiHex2TypeEp:ClientApi<MdlHex2Type, EntApiHex2TypeEp.Res>(EntApiHex2TypeEp)