package frm.enum

import ein2b.core.entity.field.EnumRowid

enum class EnumEventCat(val label:String):EnumRowid<Int>{
    LESS_THAN_VALUE("작다"){
        override val rowid:Int
            get() = 10
        override fun title(condition:String):String = "값<${condition}"
    },
    GREATER_THAN_VALUE("크다"){
        override val rowid:Int
            get() = 20
        override fun title(condition:String):String = "값>${condition}"
    },
    WITHIN_THE_RANGE("범위 안"){
        override val rowid:Int
            get() = 30
        override fun title(condition:String):String{
            val conditions = condition.split(",")
            return "${conditions[0]}<값<${conditions[1]}"
        }
    },
    EXCLUDE_THE_RANGE("범위 밖"){
        override val rowid:Int
            get() = 40
        override fun title(condition:String):String{
            val conditions = condition.split(",")
            return "값<${conditions[0]},${conditions[1]}<값"
        }
    },
    IN("포함"){
        override val rowid:Int
            get() = 50
        override fun title(condition:String):String = "값=${condition}"
    },
    NOT_EQUAL("같지 않음"){
        override val rowid:Int
            get() = 60
        override fun title(condition:String):String = "값!=${condition}"
    };
    abstract fun title(condition:String):String
}