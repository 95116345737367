package router.cat1Edit

import app.Factory
import comp.compInputSectionOneSet
import comp.input.CompTextarea
import compLabelInputSectionTextSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.click
import ein2b.core.view.eView
import entity.EntClientCat1Edit
import entity.strToList
import frm.model.*
import frm.vali.ValiCat2Title
import org.w3c.dom.HTMLElement
import prop.compInputSection
import prop.compInputSectionString
import prop.compLabelInputSectionText
import view.CompViewConfirm

private enum class CAT2{
    cat2_title, cat2_delBtn, cat2_addForm, cat2Item_valueTitle;
    override fun toString() = if ("_" in name) name.substring(name.lastIndexOf("_") + 1) else name
}
private val cat2Factory = Factory.htmlUrl("cat1Edit/cat2")


suspend fun cat2AddList(staticView:eView<HTMLElement>, subView:eView<HTMLElement>, addCat2List:MutableList<EntClientCat1Edit.EntClientCat2>, model:EntClientCat1Edit) {
    subView.setClearList { listView ->
        addCat2List.forEachIndexed { itemIdx, cat2 ->
            listView += eView(cat2Factory) { cat2View ->
                cat2.view = cat2View
                cat2View.sub(CAT2.cat2_delBtn).click = { _, _->
                    eLaunch{
                        CompViewConfirm.open(
                            "r@정말 삭제하시겠습니까? (관련 뷰 아이템/로그/이벤트 모두 삭제됩니다.)@5/3/u",
                            "r@삭제@5/3/v",
                            "r@취소@5/3/w"
                        ){
                            addCat2List.removeAt(itemIdx)
                            eLaunch{ staticView.entity(model) }
                        }
                    }
                }
                cat2View.compLabelInputSectionTextSet(CAT2.cat2_title, "r@구분명@5/3/8", ValiCat2Title, true, 120, sectionWrapperClass = "", sectionClass = "input-section width4-3"){ compTitle ->
                    compTitle.initValue = cat2.cat2Title
                    compTitle.changeBlock = { v->
                        cat2.cat2Title = v
                        v
                    }
                }
                cat2View.compInputSectionOneSet<String>(CAT2.cat2_addForm, CompTextarea{ compAddList ->
                    compAddList.initValue = cat2.itemAddList
                    compAddList.placeholder = "r@키|값|타이틀 형식으로 입력하세요.@5/3/9"
                    compAddList.changeBlock = { v->
                        cat2.itemAddList = v
                        v
                    }
                }, inputClass = "input-section")
            }
        }


    }
}

suspend fun subCat2Check(cat2Param:MdlCat2Param, cat2ItemList:List<EntClientCat1Edit.EntClientCat2Item>):Boolean{
    var isCheck = true
    cat2ItemList.forEachIndexed{idx,  cat2Item ->
        cat2Item.view.also{itemView ->
            itemView.sub(CAT2.cat2Item_valueTitle).compInputSection<String>{compInputSection ->
                if(compInputSection.check()){
                    cat2Param.cat2ItemList.find {it.cat2ItemRowid == cat2Item.cat2ItemRowid}?.also {
                        it.cat2ItemRowid = cat2Item.cat2ItemRowid
                        it.key = cat2Item.cat2ItemKey
                        it.value = cat2Item.cat2ItemValue
                        it.title = cat2Item.cat2ItemTitle
                        it.hex2TypeRowid = cat2Item.hex2TypeRowid
                    }
                }else isCheck = false
            }

        }
    }
    return isCheck
}



suspend fun subCat2AddCheck(cat2EpParam: MdlCat2EpParam, addList:MutableList<EntClientCat1Edit.EntClientCat2>):Boolean{
    var isCheck = true
    addList.forEachIndexed{idx,  cat2 ->
        cat2.view.also { cat2View->
            val cat2Param = MdlCat2Param().also {
                it.cat2Rowid = cat2.cat2Rowid
            }
            cat2View.sub(CAT2.cat2_title).compLabelInputSectionText{
                if(it.check()) cat2Param.title = it.out() else isCheck = false
            }
            isCheck = cat2AddForm(cat2View, cat2Param)
            cat2EpParam.addList.add(cat2Param)
        }

    }
    return isCheck
}

suspend fun cat2AddForm(view:eView<HTMLElement>, cat2Param: MdlCat2Param):Boolean{
    var isAddItemCheck = true
    view.sub(CAT2.cat2_addForm).compInputSectionString{ comp ->
        comp.check()
        val str = comp.out()
        if(str.isNotBlank()){
            try{
                cat2Param.cat2ItemList = strToList(str).map { cat2Item ->
                    MdlCat2ItemParam().also{
                        it.key = cat2Item.key
                        it.value = cat2Item.value
                        it.title = cat2Item.title
                    }
                }.toMutableList()
            }catch(e:Throwable){
                isAddItemCheck = false
            }
        }
        comp.changeError(isAddItemCheck, "r@키|값|타이틀 형식으로 입력하세요.@5/3/9")
    }
    return isAddItemCheck
}