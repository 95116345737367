package frm.model

import ein2b.core.entity.eEntity


class MdlView:eEntity(true){
    var viewRowid by long{ default(0L) }
    var _cat1Rowid by long{
        encoding.isExcluded()
    }
    var title by string
    var viewItemList by entityList(::MdlViewItem)
}

class MdlViewItem:eEntity(true){
    companion object {
        val DEFAULT = MdlViewItem().also {
            it.title = ""
            it.unit = ""
        }
    }
    var viewItemRowid by long{ default(0L) }
    var cat2ItemRowid by long{ default(0L) }
    var viewDecoratorRowid by long{ default(0L) }
    var title by string
    var unit by string
}
