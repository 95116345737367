package app

import ein2b.core.coroutine.eLaunch
import ein2b.core.date.eUtc
import entity.EntClientCompAccount
import frm.enum.EnumGrpCat
import frm.model.MdlMenuCat1
import kotlinx.browser.document
import org.w3c.dom.HTMLScriptElement
import view.CompViewLnb
import kotlin.random.Random

object App{
    suspend fun menuSetting(menuList:List<MdlMenuCat1>){
        CompViewLnb.menuList = menuList.map{ cat1 ->
            CompViewLnb.MenuItem(cat1.title, cat1.cat1Rowid).apply{
                iconList = listOf(
                    CompViewLnb.MenuItem.Icon(ALink(RouterKey.CAT1_EDIT, mutableMapOf("r" to cat1.cat1Rowid)), "ic-edit2-white"),
                    CompViewLnb.MenuItem.Icon(ALink(RouterKey.VIEW_ADD, mutableMapOf("r" to cat1.cat1Rowid)), "ic-plus-white")
                )
                subList = cat1.viewList.map{ view ->
                    CompViewLnb.MenuItem.SubItem("${RouterKey.VIEW_DETAIL}/${view.viewRowid}", view.title).also{
                        it.link = ALink(RouterKey.VIEW_DETAIL, mutableMapOf("r" to view.viewRowid))
                        it.btnClassName = "ic-edit2-white"
                        it.btnLink = ALink(RouterKey.VIEW_EDIT, mutableMapOf("r" to view.viewRowid))
                    }
                }.toMutableList()
            }
        }.toMutableList()
        CompViewLnb.menuList += CompViewLnb.MenuItem("r@관리@5/4/s").apply{
            subList = mutableListOf(
                CompViewLnb.MenuItem.SubItem(RouterKey.CAT1_ADD, "r@출처 등록@5/4/6").also{
                    it.link = ALink(RouterKey.CAT1_ADD)
                },
                CompViewLnb.MenuItem.SubItem(RouterKey.VIEWDECORATOR, "r@뷰 데코레이터 관리@5/4/r").also{
                    it.link = ALink(RouterKey.VIEWDECORATOR)
                },
                CompViewLnb.MenuItem.SubItem(RouterKey.HEX2TYPE, "r@Raw2Type 관리@5/4/t").also{
                    it.link = ALink(RouterKey.HEX2TYPE)
                }
            )
        }
        CompViewLnb.setList()
    }
    fun datePart(v:eUtc, part:String = "yyyy-mm-dd(WK) HH24:MM:SS") = """<span style="font-size:12px;color:#666666">${v.format(part, "Asia/Seoul", "")}</span>"""
    fun logDate(v:String, date:eUtc) = """$v<span class="margin-left6">${datePart(date)}</span>"""


    private val random = Random(0)
    fun rand(from:Int, to:Int) = random.nextInt(to - from) + from
    fun groupList(groupList:List<EntClientCompAccount.MemberShipper.ShipperGrp>):List<EntClientCompAccount.MemberShipper.ShipperGrp>{
        val grpList1 = groupList.filter{ it.grpCat == EnumGrpCat.DEFAULT }.sortedBy{ it.grpTitle }
        val grpList2 = groupList.filter{ it.grpCat != EnumGrpCat.DEFAULT }.sortedBy{ it.grpTitle }
        return grpList1 + grpList2
    }

    fun getConverterStr(viewDecoratorRowid:String, hex2TypeRowid:String, value:String): String{
        return if(viewDecoratorRowid=="0") frmHex2Type(hex2TypeRowid, value)
        else frmViewDecorator(viewDecoratorRowid, frmHex2Type(hex2TypeRowid, value))
    }

    internal fun converterJsSetting(url:String){
        document.head?.append(
            document.createElement("script").also {
                it as HTMLScriptElement
                it.src = url
            }
        )
    }

}
external fun frmViewDecorator(r:String, v:String):String
external fun frmHex2Type(r:String, v:String):String
