package app

import app.RouterKey.ACTION_TOEKN
import app.RouterKey.CAT1_ADD
import app.RouterKey.CAT1_EDIT
import app.RouterKey.VIEWDECORATOR
import app.RouterKey.EVENT
import app.RouterKey.HEX2TYPE
import app.RouterKey.HOME
import app.RouterKey.HOME_LOGIN
import app.RouterKey.JOIN
import app.RouterKey.JOIN_COMPLETE
import app.RouterKey.LOG
import app.RouterKey.LOGIN
import app.RouterKey.MASTER_EDIT
import app.RouterKey.PW_CHANGE
import app.RouterKey.PW_RESET
import app.RouterKey.PW_SEARCH
import app.RouterKey.STORAGE_CONFIG
import app.RouterKey.VIEW_ADD
import app.RouterKey.VIEW_DETAIL
import app.RouterKey.VIEW_EDIT
import ein2b.core.core.eDecodeQueryString
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.router.eBaseRouter
import ein2b.core.view.router.eHashManager
import ein2b.core.view.router.eStaticRouter
import router.actionToken.ActionTokenP
import router.cat1Add.Cat1AddP
import router.cat1Edit.Cat1EditP
import router.viewDecorator.ViewDecoratorP
import router.event.EventP
import router.hex2Type.Hex2TypeP
import router.home.HomeP
import router.homeLogin.HomeLoginP
import router.join.JoinP
import router.joinComplete.JoinCompleteP
import router.log.LogP
import router.login.LoginP
import router.masterEdit.MasterEditP
import router.pwChange.PwChangeP
import router.pwReset.PwResetP
import router.pwSearch.PwSearchP
import router.storage.StorageP
import router.viewDetail.ViewDetailP
import router.viewForm.ViewAddP
import router.viewForm.ViewEditP
import view.CompViewLnb
import view.CompViewToast

object RouterKey{
    const val HOME_LOGIN = "" //홈+로그인
    const val HOME = "home" //홈
    const val LOGIN = "login"
    const val JOIN = "join"
    const val JOIN_COMPLETE = "joinComplete" // 회원가입 완료
    const val PW_CHANGE = "pwChange"
    const val PW_SEARCH = "pwSearch"  // 비밀번호 찾기
    const val PW_RESET = "pwReset"  // 비밀번호 재설정
    const val MASTER_EDIT = "masterEdit"

    const val CAT1_ADD = "cat1Add"
    const val CAT1_EDIT = "cat1Edit"
    const val VIEW_ADD = "viewAdd"
    const val VIEW_EDIT = "viewEdit"
    const val VIEW_DETAIL = "viewDetail"
    const val LOG = "log"
    const val EVENT = "event"

    const val STORAGE_CONFIG = "storage"
    const val VIEWDECORATOR = "viewDecorator"
    const val HEX2TYPE = "hex2Type"

    const val ACTION_TOEKN = "actionToken" // 액션 토큰 처리 페이지
}
var hashManager = eHashManager(HOME_LOGIN, { hash->
    eLaunch{
        val keyArr = hash.split("?")
        val (routerKey, routerArgStr) = keyArr.first() to (if(keyArr.size > 1) keyArr.last() else "")
        CompRouter.backHash(routerKey, routerArgStr)
        CompRouter.backLinkFromHash(hash)
        CompViewToast.openLocalStorage()

        val routerArg = eDecodeQueryString(routerArgStr)
        val menuKey = when(routerKey){
            VIEW_DETAIL, VIEW_EDIT, LOG, EVENT -> "$VIEW_DETAIL/${routerArg["r"]}"
            else -> routerKey
        }
        CompViewLnb.addHook(menuKey)
    }
}).apply{
    addRouters(
        eStaticRouter(CompRouter.uiRouter).apply{
            addFactories(
                LOGIN to { router -> LoginP(router) },
                JOIN to { router -> JoinP(router) },
                JOIN_COMPLETE to { router -> JoinCompleteP(router) },
                PW_SEARCH to { router -> PwSearchP(router) },
                PW_RESET to { router -> PwResetP(router) }
            )
        },
        eStaticRouter(CompRouter.staticRouter()).apply{
            addFactories(
                HOME_LOGIN to { router -> HomeLoginP(router) },
                HOME to { router -> HomeP(router) },
                CAT1_ADD to { router -> Cat1AddP(router) },
                PW_CHANGE to { router -> PwChangeP(router) },
                ACTION_TOEKN to { router -> ActionTokenP(router) },
                MASTER_EDIT to { router -> MasterEditP(router) }
            )
        },
        eBaseRouter(CompRouter.baseRouter()).apply{
            addFactories(
                VIEWDECORATOR to { router -> ViewDecoratorP(router) },
                HEX2TYPE to { router -> Hex2TypeP(router) },
                CAT1_EDIT to { router -> Cat1EditP(router) },
                STORAGE_CONFIG to { router -> StorageP(router) },
                VIEW_ADD to { router -> ViewAddP(router) },
                VIEW_EDIT to { router -> ViewEditP(router) },
                VIEW_DETAIL to { router -> ViewDetailP(router) },
                LOG to { router -> LogP(router) },
                EVENT to { router -> EventP(router) }
            )
        }
    )
}