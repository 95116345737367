package router.join

import app.ClientApiMemberLoginCheck
import app.ClientApiMemberMasterJoinEmailStep1
import app.ClientApiMemberRsa
import frm.api.member.EntApiMemberLoginCheck
import frm.api.member.EntApiMemberMasterJoinEmailStep1
import frm.api.member.EntApiMemberRsa
import frm.model.MdlMemberJoinParam
import m42.common.api.EntM42Api

object N{
    suspend fun rsaGet(): EntApiMemberRsa.Res? = ClientApiMemberRsa.net()
    suspend fun joinStep1(param: MdlMemberJoinParam): EntApiMemberMasterJoinEmailStep1.Res? = ClientApiMemberMasterJoinEmailStep1.net(
        EntM42Api.ENTITY_KEY to { param })
    suspend fun loginCheck(): EntApiMemberLoginCheck.Res? = ClientApiMemberLoginCheck.net()
}