package router.cat1Add

import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import m42.domBase.PageBase
import org.w3c.dom.HTMLElement

class Cat1AddP(router:eRouter<HTMLElement>):PageBase(::Cat1AddView, router){
    override suspend fun net(data:eRouterData){
        N.addReady()?.also{
            view?.entity(it)
        }
    }
}