package router.log

import app.ClientApiCat2ItemLogE
import app.ClientApiCat2ItemLogEp
import app.ClientApiCat2ItemStorageDayEp
import frm.api.EntApiCat2ItemLogE

object N{
    suspend fun ready(cat2ItemRowid:Long, p:Long = 1):EntApiCat2ItemLogE.Res? = ClientApiCat2ItemLogE.net{ req->
        req.cat2ItemRowid = cat2ItemRowid
        req.p = p
    }
    suspend fun edit(cat2ItemRowid:Long, isLogging:Boolean):EntApiCat2ItemLogE.Res? = ClientApiCat2ItemLogEp.net{ req ->
        req.cat2ItemRowid = cat2ItemRowid
        req.isLogging = isLogging
    }

    suspend fun editMaxStorageDay(cat2ItemRowid:Long, maxStorageDay:Int):EntApiCat2ItemLogE.Res? = ClientApiCat2ItemStorageDayEp.net{ req ->
        req.cat2ItemRowid = cat2ItemRowid
        req.logMaxStorageDay = maxStorageDay
    }
}