package entity

import app.App
import frm.model.MdlCat2Item
import m42.model.ViewModel

class EntClientCatInfo:ViewModel(){
    companion object{
        val DEFAULT = EntClientCatInfo()
    }
    var cat2ItemRowid = 0L
    var catTitle = ""
    var cat2ItemTitle = ""
    var valueDate = ""
    var hex2TypeRowid = 0L
    fun setData(cat1Title:String, cat2Title:String, cat2Item:MdlCat2Item, viewTitle:String = ""){
        cat2ItemRowid = cat2Item.cat2ItemRowid
        catTitle = "$cat1Title > $cat2Title"
        cat2ItemTitle = viewTitle.ifBlank{ cat2Item.title.ifBlank{ cat2Item.key } }
        valueDate = App.logDate(cat2Item.value, cat2Item.updateDate)
        hex2TypeRowid = cat2Item.hex2TypeRowid
    }
}