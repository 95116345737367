package router.pwReset

import app.*
import compLabelInputSectionPasswordSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import ein2b.js.crypto.eCrypto
import frm.api.member.EntApiMemberMasterJoinEmailStep2
import frm.api.member.EntApiMemberPwEmailE2
import frm.vali.ValiMemberAccountPw
import m42.model.HcInit
import org.w3c.dom.HTMLElement
import prop.compLabelInputSectionPassword

private val factory = Factory.htmlUrl("pwReset")
private enum class K{
    reset, pw, pwConfirm, submitBtn, moveLoginLink,
    complete, success, fail, moveLoginBtn
}
suspend fun PwResetView() = eView(factory){ staticView->
    staticView.sub(K.reset)
    staticView.sub(K.complete)
    staticView.sub(K.success).displayNone()
    staticView.sub(K.fail).displayNone()

    staticView.compLabelInputSectionPasswordSet(K.pw,"r@새로운 비밀번호@5/5/W", ValiMemberAccountPw){
        it.placeholder = "r@8~32자, 영문/숫자/특수문자 중 2개 이상의 조합@5/5/X"
    }
    staticView.compLabelInputSectionPasswordSet(K.pwConfirm,"r@새로운 비밀번호 확인@5/5/Y")
    staticView.sub(K.submitBtn)
    staticView.sub(K.moveLoginLink).href = ALink(RouterKey.LOGIN).link
    staticView.sub(K.moveLoginBtn).href = ALink(RouterKey.LOGIN).link

    staticView.addEntityHook(HcInit::class, object:eEntityHook<HTMLElement, HcInit>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: HcInit) {
            view.clearCompValue(K.pw, K.pwConfirm)
            view.sub(K.reset).displayBlock()
            view.sub(K.complete).displayNone()
        }
    })

    staticView.addEntityHook(EntApiMemberPwEmailE2.Res::class, object:eEntityHook<HTMLElement,EntApiMemberPwEmailE2.Res>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntApiMemberPwEmailE2.Res) {
            if(entity.isError){
                view.sub(K.reset).displayNone()
                view.sub(K.complete).displayBlock()
                view.sub(K.success).displayNone()
                view.sub(K.fail).displayBlock()
            }else{
                staticView.sub(K.submitBtn).click = {_,_ ->
                    eLaunch {
                        val pw = staticView.sub(K.pw).compLabelInputSectionPassword()
                        val pwConfirm = staticView.sub(K.pwConfirm).compLabelInputSectionPassword()
                        if(CompApp.checkAll(pw, pwConfirm)){
                            if(pw.out() != pwConfirm.out()){
                                pwConfirm.changeError("r@새로운 비밀번호가 일치하지 않습니다.@5/5/Z", false)
                            }else{
                                N.rsaGet()?.also { rsa ->
                                    N.pwEmailChange(rsa.rsa.id, eCrypto.rsaEncrypt(pw.out(), rsa.rsa.key), entity.token)?.also {res->
                                        if(res.isSuccess) {
                                            view.sub(K.success).displayBlock()
                                            view.sub(K.fail).displayNone()
                                        } else {
                                            view.sub(K.success).displayNone()
                                            view.sub(K.fail).displayBlock()
                                        }
                                        staticView.sub(K.reset).displayNone()
                                        staticView.sub(K.complete).displayBlock()
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    })
}