package frm.api.member

import ein2b.core.entity.eEntity
import frm.api.API_MEMBER_PW_PATH
import frm.api.EntFrmApi
import frm.vali.isServerVali3
import m42.common.enum.EnumApiAccessAllow
import m42.member.model.MdlM42MemberAcPwParam

//비밀번호 재설정 메일 보내는것
object EntApiMemberPwEmailE1:EntFrmApi<EntApiMemberPwEmailE1.Req, EntApiMemberPwEmailE1.Res>(Res::class, ::Req, ::Res){
    const val PATH = "$API_MEMBER_PW_PATH/email/e1"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow :EnumApiAccessAllow = EnumApiAccessAllow.LOGOUT
    override val rscKeys = listOf("pwSearch")
    class Req:eEntity(){
        var email by string()
    }
    class Res:eEntity(true){
        var errorMsg by string{ default("") } // 가입되지 않은 이메일 주소입니다. or 가입 인증 절차를 완료하지 않은 이메일 주소입니다. 인증을 완료해 주세요.
        val isError:Boolean get() = errorMsg.isNotBlank()
    }
}
//비밀번호 재설정 준비 - 로그인한 계정에 해당하는 재설정 토큰만 발급해줌
object EntApiMemberPwEmailE2:EntFrmApi<EntApiMemberPwEmailE2.Req, EntApiMemberPwEmailE2.Res>(Res::class, ::Req, ::Res){
    const val PATH = "$API_MEMBER_PW_PATH/email/e2"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow :EnumApiAccessAllow = EnumApiAccessAllow.LOGOUT
    class Req:eEntity(){
        var urlToken by string()
    }
    class Res:eEntity(){
        var errorMsg by string{ default("") }  //실패한 경우 메세지 전달됨
        val isError:Boolean get() = errorMsg.isNotBlank()
        var token by string{ default("") } //성공할 경우 token 전달됨.
    }
}
//비밀번호 재설정
object EntApiMemberPwEmailEp:EntFrmApi<EntApiMemberPwEmailEp.Req, EntApiMemberPwEmailEp.Res>(Res::class, ::Req, ::Res){
    const val PATH = "$API_MEMBER_PW_PATH/email/ep"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow :EnumApiAccessAllow = EnumApiAccessAllow.LOGOUT
    class Req:MdlM42MemberAcPwParam(){
        var token by string()
    }
    class Res:eEntity(true){
        var errorMsg by string{ default("") }  //실패한 경우 메세지 전달됨
        val isSuccess get() = errorMsg.isEmpty()
    }
}
//비밀번호 변경
object EntApiMemberPwEp:EntFrmApi<EntApiMemberPwEp.Req, EntApiMemberPwEp.Res>(Res::class, ::Req, ::Res){
    const val PATH = "$API_MEMBER_PW_PATH/ep"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    class Req:eEntity(){
        var rsaId by string()
        var oldPw by string()
        var newPw by string()
        var isServer by boolean{ default(isServerVali3) }
    }
    class Res:eEntity(){
        var errorMsg by string{ default("") }  //실패한 경우 메세지 전달됨
        val isSuccess get() = errorMsg.isEmpty()
    }
}