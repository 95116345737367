package frm.model

import ein2b.core.entity.eEntity
import frm.vali.ValiCat1Title
import frm.vali.ValiCat2Title

class MdlCat1Param:eEntity(true){
    var cat1Rowid by long{ default(0L) }
    var title by string{ validator(ValiCat1Title) }
    var cat2List by entityList(::MdlCat2Param)
    var _masterAcRowid by long{ encoding.isExcluded() }
}
class MdlCat2Param:eEntity(true){
    var cat2Rowid by long{ default(0) }
    var title by string{ validator(ValiCat2Title) }
    var cat2ItemList by entityList(::MdlCat2ItemParam)
    var _cat1Rowid by long{ encoding.isExcluded() }
}
class MdlCat2ItemParam:eEntity(true){
    var cat2ItemRowid by long{ default(0L) }
    var key by string
    var value by string
    var title by string
    var hex2TypeRowid by long
    var _cat2Rowid by long{ encoding.isExcluded() }
}

class MdlCat1EpParam:eEntity(true){
    var cat1Rowid by long
    var cat1Title by string
    var cat2Rowid by long{default(0L)}
    var cat2Title by string{default("")}
    var deleteList by longList
    var editList by entityList(::MdlCat2ItemParam)
    var _masterAcRowid by long{ encoding.isExcluded() }
}

class MdlCat2EpParam:eEntity(true){
    var cat1Rowid by long
    var addList by entityList(::MdlCat2Param)
}