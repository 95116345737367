package frm.api

import ein2b.core.entity.eEntity
import frm.model.MdlCat1
import frm.model.MdlStorageConfig
import frm.model.MdlStorageConfigEpParam
import m42.common.enum.EnumApiAccessAllow


object EntApiStorageConfigEp:EntFrmApi<MdlStorageConfigEpParam, EntApiStorageConfigEp.Res>(Res::class, ::MdlStorageConfigEpParam, ::Res){
    const val PATH = "${API_STORAGE_PATH}/wp"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow = EnumApiAccessAllow.LOGIN
    override var isMenu = true
    class Res:eEntity(){
        var cat1 by entity(::MdlCat1)
        var storageConfigList by entityList(::MdlStorageConfig)
    }
}
object EntApiStorageConfigE:EntFrmApi<EntApiStorageConfigE.Req, EntApiStorageConfigEp.Res>(EntApiStorageConfigEp.Res::class, ::Req, EntApiStorageConfigEp::Res){
    const val PATH = "${API_STORAGE_PATH}/e"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow = EnumApiAccessAllow.LOGIN
    class Req:eEntity(){
        var cat1Rowid by long
    }
}