package entity

import app.App
import ein2b.core.core.ePage
import ein2b.core.view.eView
import frm.api.EntApiCat1E
import frm.model.MdlCat2
import frm.model.MdlCat2ItemParam
import frm.model.MdlCat2Param
import m42.common.model.MdlM42ApiPageMeta
import m42.model.ViewModel
import org.w3c.dom.HTMLElement

class EntClientCat1Add:ViewModel(){
    class Cat2{
        lateinit var view: eView<HTMLElement>
        var title = ""
        var cat2ItemStr = ""
        var cat2ItemList = mutableListOf<EntClientAddCat2Item>()
    }
    var cat1Title = ""
    var cat2AddList = mutableListOf<Cat2>()
    suspend fun init() {
        cat2AddList = mutableListOf()
        cat1Title = ""
    }
    fun addForm(){
        cat2AddList += Cat2()
    }

}