package frm.model

import ein2b.core.entity.eEntity
import frm.enum.EnumTypeCat

class MdlHex2Type:eEntity(true){
    var hex2TypeRowid by long{
        default(0L) //등록 수정 같이 씀
    }
    var title by string
    var typeCat by enum<EnumTypeCat>()
    var js by string
}