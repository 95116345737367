package frm.api

import ein2b.core.entity.eEntity
import frm.model.MdlHex2Type
import m42.common.enum.EnumApiAccessAllow
import m42.common.model.MdlM42ApiPageMeta

object EntApiHex2TypeE:EntFrmApi<EntApiHex2TypeE.Req, EntApiHex2TypeE.Res>(Res::class, ::Req, ::Res){
    const val PATH = "${API_HEX2TYPE_PATH}/e"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow = EnumApiAccessAllow.LOGIN
    class Req:eEntity(){
        var p by long{ default(1) }
    }
    class Res:eEntity(){
        var meta by entity(::MdlM42ApiPageMeta)
        var hex2TypeList by entityList(::MdlHex2Type)
    }}

object EntApiHex2TypeEp:EntFrmApi<MdlHex2Type, EntApiHex2TypeEp.Res>(Res::class, ::MdlHex2Type, ::Res){
    const val PATH = "${API_HEX2TYPE_PATH}/ep"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow = EnumApiAccessAllow.LOGIN
    class Res:eEntity()
}