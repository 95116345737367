package comp.input

import ein2b.core.coroutine.eLaunch
import ein2b.core.view.keydown
import ein2b.core.view.keyup
import ein2b.js.dom.eEvent

abstract class CompInputNumberBase:CompInputSingle<String>(){
    companion object{
        //-
        val KEYCODE_MINUS = hashSetOf(109, 189)
        //.
        val KEYCODE_DECIMAL = hashSetOf(110, 190)

        val NOT_NUMBER = """[^-.0-9]""".toRegex()
        fun valueNumber(v:String) = v.replace(NOT_NUMBER, "")
    }
    final override fun changedValue(v:String, isViewOnly:Boolean){
        elValue = v
        value.inputValue(v)
        if(isChangeCheck) eLaunch{ value.check() }
    }
    protected fun numberAfterTargetInited(){
        target.keydown = { e, el ->
            val ev = eEvent(e, el)
            val keyCode = ev.keycode()
            val v = ev.value

            val keyCodes = CompInput.KEYCODE.toMutableSet()
            if(isMinus) keyCodes += KEYCODE_MINUS
            if(isDecimal) keyCodes += KEYCODE_DECIMAL

            var isNumber = true
            if(v.isNotBlank()){
                //첫째 자리가 아닌 곳에 -가 들어왔다면 입력못하게 막기
                if(keyCode in KEYCODE_MINUS) isNumber = false
                //소수점(.)을 이미 입력했다면 또 입력못하게 막기
                if(v.indexOf('.') > -1 && keyCode in KEYCODE_DECIMAL) isNumber = false
            }
            if(!isNumber || keyCode !in keyCodes){
                e.stopImmediatePropagation()
                ev.prevent()
            }
            keyDownBlock?.invoke(v)
        }

        target.keyup = { e, el ->
            isKeyUpEvent = true
            val ev = eEvent(e, el)
            val v = ev.value
            changedValue(v, true)
            if(ev.keycode() == 13) eLaunch{ enterEvent?.invoke(this) }
            keyUpBlock?.invoke(v)
        }

        if(initValue.isNotBlank()) value.inputValue(initValue)
    }

    var isDecimal:Boolean = false
    var isMinus:Boolean = false
}