package frm.model

import ein2b.core.entity.eEntity

open class MdlCat2ItemLog:eEntity(true){
    var value by string
    var regDate by utc
}

class MdlCat2ItemLogParam:eEntity(true){
    var cat2ItemRowid by long
    var value by string
    var regDate by string
}