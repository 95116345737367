package entity

import ein2b.core.core.ePage
import ein2b.core.view.eView
import frm.api.EntApiViewE
import frm.api.EntApiViewW
import frm.model.*
import m42.common.model.MdlM42ApiPageMeta
import m42.model.ViewModel
import org.w3c.dom.HTMLElement

class EntClientViewForm:ViewModel(){
    companion object{
        const val RPP = 50L
        private fun cat2ItemTitle(cat2Item:MdlCat2Item) = "${cat2Item.key}${if(cat2Item.title.isBlank()) "" else "|${cat2Item.title}"}"
    }
    class ViewItem{
        var view:eView<HTMLElement>? = null
        var cat2ItemRowid = 0L
        var cat2Itemtitle = ""
        var isLogActive = false

        var viewItemRowid = 0L
        var viewDecoratorRowid = 0L
        var viewItemTitle = ""
        var viewItemUnit = ""

        var isViewItemTitleOk = true
        var isViewItemUnitOk = true
        val isOk get() = isViewItemTitleOk && isViewItemUnitOk
        fun errorClear(){
            isViewItemTitleOk = true
            isViewItemUnitOk = true
        }
    }
    class Cat2{
        companion object{
            var EMPTY = Cat2()
        }
        lateinit var mdlCat2:MdlCat2
        var mdlView:MdlView? = null
        val isEmpty get() = this == EMPTY
        var isSelected = false //셀렉트 박스 선택 여부
        val cat2Title get() = mdlCat2.title
        val itemCnt get() = "${viewItemList.size}/${mdlCat2.cat2ItemList.size}"
        var pageList = listOf<Cat2Page>()
        var viewItemList = listOf<ViewItem>()

        var page = 1L
        var pageMeta = MdlM42ApiPageMeta().also{
            it.rowPerPage = "$RPP"
        }
        val currPage get() = pageList.find{ it.page == page } ?: Cat2Page.EMPTY
        val isOk get() = viewItemList.all{ it.isOk }
        fun setData(){
            val cat2ItemList = mdlCat2.cat2ItemList.filter{ !it.isSelected }
            val totalRows = cat2ItemList.size
            val totalPage = ePage.totalPage(totalRows.toLong(), RPP)
            pageMeta.totalRows = "$totalRows"
            pageList = (1..totalPage).map{
                val startIdx = ePage.start(it, RPP).toInt()
                var endIdx = ePage.end(it, RPP).toInt()
                if(totalRows < endIdx) endIdx = totalRows
                Cat2Page(it).also{ cat2Page ->
                    cat2Page.itemList = cat2ItemList.subList(startIdx, endIdx).map{ cat2Item->
                        Cat2PageItem(cat2Item)
                    }
                }
            }

            viewItemList = mdlCat2.cat2ItemList.filter{ it.isSelected }.map{ cat2Item ->
                ViewItem().also{ viewItem ->
                    viewItem.cat2ItemRowid = cat2Item.cat2ItemRowid
                    viewItem.cat2Itemtitle = cat2ItemTitle(cat2Item)
                    viewItem.isLogActive = cat2Item.isLogging
                    mdlView?.viewItemList?.find{ it.cat2ItemRowid == cat2Item.cat2ItemRowid }?.also{ mdlViewItem->
                        viewItem.viewItemRowid = mdlViewItem.viewItemRowid
                        viewItem.viewDecoratorRowid = mdlViewItem.viewDecoratorRowid
                        viewItem.viewItemTitle = mdlViewItem.title
                        viewItem.viewItemUnit = mdlViewItem.unit
                    }
                }
            }
        }
        fun init(){
            setPage(1)
        }
        fun setPage(p:Long){
            page = ePage.validPage(p, pageMeta.totalRows.toLong(), RPP)
            pageMeta.page = "$page"
        }
        fun allCheck(v:Boolean){
            mdlCat2.cat2ItemList.forEach{ it.isSelected = v }
            setData()
        }
        fun itemCheckActive(item:Cat2PageItem){
            mdlCat2.cat2ItemList.find{ it.cat2ItemRowid == item.cat2ItemRowid }?.isSelected = true
            setData()
        }
        fun itemCheckInActive(item:ViewItem){
            mdlCat2.cat2ItemList.find{ it.cat2ItemRowid == item.cat2ItemRowid }?.isSelected = false
            setData()
        }
    }
    class Cat2Page(val page:Long){
        companion object{
            var EMPTY = Cat2Page(0)
        }
        var itemList = listOf<Cat2PageItem>()
    }
    class Cat2PageItem(cat2Item:MdlCat2Item){
        val cat2ItemRowid = cat2Item.cat2ItemRowid
        val cat2Itemtitle = cat2ItemTitle(cat2Item)
        val isLogActive = cat2Item.isLogging
    }


    var viewRowid = 0L
    var cat1Rowid = 0L
    var cat1Title = ""
    var viewTitle = ""
    var refreshRate = ""

    var mdlView: MdlView? = null
    var viewDecoratorList = listOf<MdlViewDecorator>()
    var cat2List = listOf<Cat2>()
    val cat2 get() = cat2List.find{ it.isSelected } ?: Cat2.EMPTY

    fun init(){
        viewRowid = 0L
        cat1Rowid = 0L
        cat1Title = ""
        viewTitle = ""
        refreshRate = ""
        cat2List = listOf()
    }
    fun addSetData(target:EntApiViewW.Res){
        cat1Rowid = target.cat1Rowid
        cat1Title = target.cat1Title
        viewDecoratorList = target.viewDecoratorList
        setData(target.cat2List)
    }
    fun editSetData(target:EntApiViewE.Res){
        viewRowid = target.view.viewRowid
        cat1Title = target.cat1Title
        viewDecoratorList = target.viewDecoratorList

        val viewData = target.view
        viewTitle = viewData.title
        mdlView = viewData
        setData(target.cat2List)
    }
    fun setData(targetCat2List:List<MdlCat2>){
        cat2List = targetCat2List.map{ mdlCat2 ->
            Cat2().also{ cat2 ->
                cat2.mdlCat2 = mdlCat2
                cat2.mdlView = mdlView

                mdlView?.also{ viewData ->
                    cat2.mdlCat2.cat2ItemList.forEach{ cat2Item ->
                        cat2Item.isSelected = viewData.viewItemList.find{ it.cat2ItemRowid == cat2Item.cat2ItemRowid } != null
                    }
                }
                cat2.setData()
            }
        }
    }
    fun cat2Select(cat2:Cat2){
        cat2List.forEach{ it.isSelected = false }
        cat2.isSelected = true
        cat2.init()
    }
    fun cat2SetPage(p:Long){
        cat2.setPage(p)
    }
}