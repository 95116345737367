package comp.input

import app.Factory
import ein2b.core.core.err
import ein2b.core.view.attr
import ein2b.core.view.eView
import ein2b.core.view.value
import org.w3c.dom.HTMLElement

class CompInputNumber:CompInputNumberBase(){
    companion object{
        operator fun invoke(block:(CompInputNumber)->Unit):CompInputNumber{
            val comp = CompInputNumber()
            block(comp)

            comp.isMinus = comp.minValue.toDouble() < 0.0
            comp.isDecimal = comp.step.lastIndexOf('.') > -1
            comp.afterTargetInited = {
                comp.target.attr("step", comp.step)
                comp.target.attr("min", comp.minValue)
                comp.target.attr("max", comp.maxValue)
                comp.value = CompValue("", "", comp.vali, comp.errorListener, CompInput.CONV){
                    comp.isKeyUpEvent = false
                    comp.target.value = it
                    //comp.elValue = it
                }
                comp.numberAfterTargetInited()
            }
            return comp
        }
    }
    override var subKey:String = "CompInputNumber_input"
    override val factory:suspend ()-> HTMLElement = Factory.html("""<input data-view="$subKey" type="number">""")
    var minValue:Number = 0.0
    var maxValue:Number = Double.MAX_VALUE
    var step = ""
}

// ============================ prop ============================
inline fun eView<HTMLElement>.compInputNumber(block:(CompInputNumber)->Unit = {}):CompInputNumber{
    val comp = this["compInputNumber_value"] as? CompInputNumber ?: err("fail to get compInputNumber")
    block(comp)
    return comp
}