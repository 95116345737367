package router.cat1Edit

import app.ALink
import app.Factory
import app.RouterKey
import comp.*
import comp.input.*
import compLabelInputSectionTextSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import entity.EntClientCat1Edit
import entity.strToList
import frm.api.EntApiCat1E
import frm.model.MdlCat1EpParam
import frm.model.MdlCat2EpParam
import frm.model.MdlCat2ItemParam
import frm.vali.ValiCat1Title
import frm.vali.ValiCat2ItemTitle
import frm.vali.ValiCat2ItemValue
import frm.vali.ValiCat2Title
import m42.app.M42App
import m42.model.HcInit
import org.w3c.dom.HTMLElement
import prop.compInputSectionString
import prop.compLabelInputSectionText
import router.comp.CompLog
import view.CompViewConfirm
import view.CompViewToast

private val factory = Factory.htmlUrl("cat1Edit/cat1")
private val cat2ItemFactory = Factory.htmlUrl("cat1Edit/cat2Item")
private val cat2BtnFactory = Factory.htmlUrl("cat1Edit/cat2Btn")

private enum class K{
    configBtn, saveBtn,
    cat1Title, cat2BtnArea, cat2BtnList, cat2Area, cat2Title, cat2ItemList, page, cat2DelBtn,
    cat2Item_key, cat2Item_valueTitle, cat2Item_hex2type, cat2Item_updatedDate, cat2Item_eventBtn, cat2Item_compLog, cat2Item_delBtn, addCat2Item,
    cat2AddList, cat2AddBtn, addBtn;
    override fun toString() = if ("_" in name) name.substring(name.lastIndexOf("_") + 1) else name
}

suspend fun Cat1EditView2() = eView(factory){ staticView->
    val model = EntClientCat1Edit()
    CompPageTitle(staticView){ it.title = "r@출처 수정@5/3/4" }
    staticView.compLabelInputSectionTextSet(K.cat1Title, "r@출처명@5/3/7", ValiCat1Title, true, 100, "input-section width3-1", ""){comp ->
        comp.changeBlock = { v->
            model.cat1Title = v
            v
        }
    }
    staticView.compToggleRadioSet(K.cat2BtnList){
        it.wrapperClass = "cat2-list flex-center"
        it.itemClass = "cat2-list-item"
        it.checkBlock = { idx ->
            model.cat2Select(it.itemList[idx].value)
            eLaunch{ staticView.entity(model) }
        }
    }
    staticView.compLabelInputSectionTextSet(K.cat2Title, "r@구분명@5/3/8", ValiCat2Title, true, 100, "input-section width3-1", ""){comp ->
        comp.changeBlock = { v->
            model.cat2?.param?.title = v
            v
        }
    }
    staticView.sub(K.cat2DelBtn).click = {_,_->
        eLaunch{
            CompViewConfirm.open(
                "r@정말 삭제하시겠습니까? (관련 뷰 아이템/로그/이벤트 모두 삭제됩니다.)@5/3/u",
                "r@삭제@5/3/v",
                "r@취소@5/3/w"
            ){
            }
        }

    }
    staticView.compPaginationSet(K.page, "page"){ comp->
        comp.clickEvent = {p->
            model.cat2SetPage(p)
            staticView.entity(model)
            M42App.windowScrollTo(0.0, 0.0)
        }
    }

    staticView.compInputSectionOneSet<String>(K.addCat2Item, CompTextarea{comp ->
        comp.initValue = model.cat2ItemAddStr
        comp.placeholder = "r@키|값|타이틀 형식으로 입력하세요.@5/3/9"
        comp.changeBlock = { v->
            model.cat2ItemAddStr = v
            v
        }
    }, inputClass = "input-section", wrapperClass = "margin-top12")

    staticView.sub(K.saveBtn)
    staticView.sub(K.cat2Area).displayNone()
    staticView.sub(K.cat2AddList).displayNone()
    staticView.sub(K.addBtn).displayNone()
    staticView.sub(K.cat2BtnArea).displayNone()
    M42App.emptySub(staticView, K.cat2ItemList, K.cat2AddBtn, K.configBtn, K.cat2BtnList)
    suspend fun cat2BtnRender(){
        staticView.sub(K.cat2BtnList).compToggleRadio<EntClientCat1Edit.EntClientCat2>{ comp ->
            comp.setList(model.cat2List.map{
                comp.item(it.cat2Title, it, it.isSelect)
            }, cat2BtnFactory){ view, item ->
                val cat2 = item.value
                view.sub(router.viewForm.K.cat2Btn_title).html = cat2.cat2Title
                view.sub(router.viewForm.K.cat2Btn_cnt).html = cat2.itemCnt
            }
        }
    }
    staticView.addEntityHook(HcInit::class, object:eEntityHook<HTMLElement, HcInit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:HcInit){
            model.init()
        }
    })
    staticView.addEntityHook(EntApiCat1E.Res::class, object:eEntityHook<HTMLElement, EntApiCat1E.Res>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntApiCat1E.Res){
            model.setData(entity)
            view.entity(model)
        }
    })
    staticView.addEntityHook(EntClientCat1Edit::class, object:eEntityHook<HTMLElement, EntClientCat1Edit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntClientCat1Edit){
            view.sub(K.cat1Title).compLabelInputSectionText().inputValue(entity.cat1Title)
            view.sub(K.configBtn){
                if(entity.cat1Rowid==0L) it.displayNone() else it.displayInlineBlock()
                ALink(RouterKey.STORAGE_CONFIG, mutableMapOf("r" to entity.cat1Rowid)).href(it)
            }
            view.sub(K.cat2BtnArea){
                if(entity.cat2List.isEmpty()) it.displayNone() else it.displayBlock()
            }
            cat2BtnRender()
            view.sub(K.cat2DelBtn).click = {_,_->
                eLaunch{
                    CompViewConfirm.open(
                        "r@정말 삭제하시겠습니까? (관련 뷰 아이템/로그/이벤트 모두 삭제됩니다.)@5/3/u",
                        "r@삭제@5/3/v",
                        "r@취소@5/3/w"
                    ){
                        entity.cat2?.also {cat2->
                            eLaunch {
                                N.cat2Delete(entity.cat1Rowid, cat2.cat2Rowid)?.also{res->
                                    CompViewToast.open("r@구분이 삭제되었습니다.@5/3/14")
                                    staticView.entity(res)
                                }
                            }
                        }
                    }
                }

            }
            view.sub(K.cat2Title).compLabelInputSectionText { comp->
                val v = entity.cat2?.let { it.cat2Title } ?: ""
                comp.inputValue(v)
            }
            entity.cat2?.also { cat2->
                view.sub(K.cat2Area).displayBlock()
                view.sub(K.cat2ItemList).setClearList {listView ->
                    val currPage = cat2.currPage
                    val cat2ItemList = currPage?.cat2ItemList?.toMutableList() ?: mutableListOf()
                    cat2ItemList.forEachIndexed { itemIdx, cat2Item ->
                        entity.cat2ItemList += cat2Item
                        listView += eView(cat2ItemFactory) {itemView ->
                            cat2Item.view = itemView
                            itemView.sub(K.cat2Item_key).html = cat2Item.cat2ItemKey
                            itemView.compInputSectionSet<String>(
                                K.cat2Item_valueTitle,
                                errorClass = "flex",
                                inputClass = "flex"
                            ) {
                                listOf(
                                    CompInputText {
                                        it.placeholder = "r@값@5/3/e"
                                        it.initValue = cat2Item.cat2ItemValue
                                        it.inputClass = "width8-1 margin-right8"
                                        it.vali = ValiCat2ItemValue
                                        it.changeBlock = { v ->
                                            cat2Item.cat2ItemValue = v
                                            v
                                        }
                                    },
                                    CompInputText {
                                        it.placeholder = "r@타이틀@5/3/f"
                                        it.initValue = cat2Item.cat2ItemTitle
                                        it.inputClass = "width3-1 margin-right8"
                                        it.vali = ValiCat2ItemTitle
                                        it.changeBlock = { v ->
                                            cat2Item.cat2ItemTitle = v
                                            v
                                        }
                                    }
                                )
                            }
                            itemView.compSelectSet<Long>(K.cat2Item_hex2type){ comp ->
                                comp.wrapperClass = "margin-left8 width6-1"
                                comp.checkBlock = { idx ->
                                    cat2Item.hex2TypeRowid = comp.itemList[idx].value
                                }
                                comp.afterInited = {
                                    comp.setList{
                                        entity.hex2TypeList.map{ h ->
                                            comp.item(
                                                h.title,
                                                h.hex2TypeRowid,
                                                h.hex2TypeRowid == cat2Item.hex2TypeRowid
                                            )
                                        }
                                    }
                                }
                            }
                            itemView.sub(K.cat2Item_updatedDate).html = cat2Item.updatedDate
                            itemView.sub(K.cat2Item_eventBtn) {
                                it.attr("v0" to cat2Item.eventCnt)
                                it.html =
                                    if (cat2Item.eventCnt == 0) "r@Evt@5/4/h" else "r@Evt (${cat2Item.eventCnt})@5/4/g"
                                ALink(RouterKey.EVENT, mutableMapOf("r" to cat2Item.cat2ItemRowid)).href(it)

                            }
                            CompLog(itemView, K.cat2Item_compLog) {
                                it.cat2ItemRowid = cat2Item.cat2ItemRowid
                                it.isActive = cat2Item.isLogActive
                            }
                            itemView.sub(K.cat2Item_delBtn).click = { _, _ ->
                                eLaunch {
                                    CompViewConfirm.open(
                                        "r@정말 삭제하시겠습니까? (관련 뷰 아이템/로그/이벤트 모두 삭제됩니다.)@5/3/u",
                                        "r@삭제@5/3/v",
                                        "r@취소@5/3/w"
                                    ) {
                                        model.cat2?.currPage?.cat2ItemList?.removeAt(itemIdx)
                                        model.deleteList.add(cat2Item.cat2ItemRowid)
                                        eLaunch { view.entity(model) }
                                    }
                                }
                            }
                        }
                    }

                }
                view.sub(K.page).compPagination().setPage(cat2.pageMeta.totalRows, cat2.pageMeta.page, cat2.pageMeta.rowPerPage, cat2.pageMeta.pagePerGroup)

            }?:also{
                view.sub(K.cat2Area).displayNone()
            }
            view.sub(K.addCat2Item).compInputSectionString{comp->
                comp.clear()
            }
            view.sub(K.cat2AddBtn).click = { _, _->
                entity.addForm()
                eLaunch{ view.entity(model) }
            }
            view.sub(K.addBtn){
                if(entity.cat2AddList.isEmpty()) it.displayNone() else it.displayInlineBlock()
            }
            view.sub(K.cat2AddList){
                if(entity.cat2AddList.isEmpty()) it.displayNone() else it.displayBlock()
                cat2AddList(view, it, entity.cat2AddList, model)
            }
            view.sub(K.saveBtn).click = { _, _->
                eLaunch{
                    var isCheck = true
                    val cat1EpParam = MdlCat1EpParam().also{
                        it.cat1Rowid = entity.cat1Rowid
                        it.editList = mutableListOf()
                        it.deleteList = entity.deleteList
                        it.editList = mutableListOf()
                    }
                    val cat1Title = view.sub(K.cat1Title).compLabelInputSectionText()
                    val cat2Title = view.sub(K.cat2Title).compLabelInputSectionText()

                    if(cat1Title.check()) cat1EpParam.cat1Title = cat1Title.out() else isCheck = false
                    entity.cat2?.also {cat2->
                        cat1EpParam.cat2Rowid = cat2.cat2Rowid
                        if(cat2Title.check()) cat1EpParam.cat2Title = cat2Title.out() else isCheck = false
                        if(!subCat2Check(cat2.param, entity.cat2ItemList)) isCheck = false
                        view.sub(K.addCat2Item).compInputSectionString{comp ->
                            comp.check()
                            var isAddItemCheck = true
                            val str = comp.out()
                            if(str.isNotBlank()){
                                try{
                                    cat1EpParam.editList += strToList(str).map { cat2Item ->
                                        MdlCat2ItemParam().also{
                                            it.key = cat2Item.key
                                            it.value = cat2Item.value
                                            it.title = cat2Item.title
                                        }
                                    }.toMutableList()
                                }catch(e:Throwable){
                                    isAddItemCheck = false
                                }
                            }
                            comp.changeError(isAddItemCheck, "r@키|값|타이틀 형식으로 입력하세요.@5/3/9")
                            if(!isAddItemCheck) isCheck = false
                        }
                        if(isCheck) {
                            cat1EpParam.editList += cat2.currPage?.cat2ItemList?.map {from->
                                MdlCat2ItemParam().also { to->
                                    to.cat2ItemRowid = from.cat2ItemRowid
                                    to.key = from.cat2ItemKey
                                    to.value = from.cat2ItemValue
                                    to.title = from.cat2ItemTitle
                                    to.hex2TypeRowid = from.hex2TypeRowid
                                }
                            }?.toMutableList() ?: mutableListOf()
                            N.edit(cat1EpParam)?.also{res ->
                                CompViewToast.open("r@출처가 수정되었습니다.@5/3/t")
                                model.setData(res)
                                model.cat2Select(cat2)
                                model.cat2SetPage(cat2.page)
                                staticView.entity(model)
                            }
                        }
                    }

                }
            }

            view.sub(K.addBtn).click = { _, _->
                eLaunch {
                    val cat1Title = view.sub(K.cat1Title).compLabelInputSectionText()
                    if(cat1Title.check()){
                        val cat2EpParam = MdlCat2EpParam().also{
                            it.cat1Rowid = entity.cat1Rowid
                            it.addList = mutableListOf()
                        }
                        if(subCat2AddCheck(cat2EpParam, entity.cat2AddList)){
                            N.cat2Add(cat2EpParam)?.also{res ->
                                CompViewToast.open("r@구분이 등록되었습니다.@5/3/13")
                                staticView.entity(res)
                            }
                        }
                    }
                }
            }
        }
    })
}