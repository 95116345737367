package router.event

import app.Factory
import comp.input.compInputText
import comp.input.compInputTextSet
import comp.input.compOneRadio
import comp.input.compOneRadioSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.displayNone
import ein2b.core.view.eEntityHook
import ein2b.core.view.eView
import ein2b.core.view.html
import entity.EntClientEvent
import entity.EntClientEventCat
import org.w3c.dom.HTMLElement
import frm.enum.EnumEventCat

private val cat30factory = Factory.htmlUrl("event/cat30")
private val cat40factory = Factory.htmlUrl("event/cat40")
suspend fun subCatTwo(staticView:eView<HTMLElement>, subKey:Any, model:EntClientEvent, catModel:EntClientEventCat.Two){
    staticView.sub(subKey, if(catModel.cat == EnumEventCat.WITHIN_THE_RANGE) cat30factory else cat40factory){ subView ->
        subView.addEntityHook(EntClientEventCat.Two::class, object:eEntityHook<HTMLElement, EntClientEventCat.Two>{
            override suspend fun invoke(view:eView<HTMLElement>, entity:EntClientEventCat.Two){
                if(entity.isForm){
                    view.init(view.initBlock ?: {
                        view.sub(K.cat_radioWrap)
                        view.sub(K.cat_value1).displayNone()
                        view.sub(K.cat_value2).displayNone()

                        view.compOneRadioSet(K.cat_radio){
                            it.checkBlock = {
                                model.form.setCat(entity)
                                eLaunch{ staticView.entity(model) }
                            }
                        }
                        view.compInputTextSet(K.cat_condition1){
                            it.inputClass = "width80px"
                            it.changeBlock = { v ->
                                entity.condition1 = v
                                v
                            }
                        }
                        view.compInputTextSet(K.cat_condition2){
                            it.inputClass = "width80px"
                            it.changeBlock = { v ->
                                entity.condition2 = v
                                v
                            }
                        }
                    })
                    view.sub(K.cat_radio).compOneRadio().inputValue(entity.isSelected)
                    view.sub(K.cat_condition1).compInputText{
                        it.clear()
                        it.value.inputValue(entity.condition1)
                    }
                    view.sub(K.cat_condition2).compInputText(){
                        it.clear()
                        it.value.inputValue(entity.condition2)
                    }
                }else{
                    view.sub(K.cat_radioWrap).displayNone()
                    view.sub(K.cat_radio)
                    view.sub(K.cat_condition1).displayNone()
                    view.sub(K.cat_condition2).displayNone()

                    view.sub(K.cat_value1).html = entity.condition1
                    view.sub(K.cat_value2).html = entity.condition2
                }
            }
        })
        subView.addEntityHook(EntClientEvent.Form::class, object:eEntityHook<HTMLElement, EntClientEvent.Form>{
            override suspend fun invoke(view:eView<HTMLElement>, entity:EntClientEvent.Form){
                catModel.isOk = !catModel.isSelected || (catModel.condition1.isNotBlank() && catModel.condition2.isNotBlank())
                view.sub(K.cat_condition1).compInputText().error(!catModel.isSelected || catModel.condition1.isNotBlank())
                view.sub(K.cat_condition2).compInputText().error(!catModel.isSelected || catModel.condition2.isNotBlank())
            }
        })
        subView.entity(catModel)
    }
}