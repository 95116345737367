package router.viewForm

import app.ClientApiViewE
import app.ClientApiViewEp
import app.ClientApiViewW
import app.ClientApiViewWp
import frm.api.EntApiViewE
import frm.api.EntApiViewEp
import frm.api.EntApiViewW
import frm.api.EntApiViewWp
import frm.model.MdlViewAddParam
import frm.model.MdlViewEditParam
import m42.common.api.EntM42Api

object N{
    suspend fun addReady(cat1Rowid:Long):EntApiViewW.Res? = ClientApiViewW.net{ req->
        req.cat1Rowid = cat1Rowid
    }
    suspend fun editReady(viewRowid:Long):EntApiViewE.Res? = ClientApiViewE.net{ req->
        req.viewRowid = viewRowid
    }

    suspend fun add(param:MdlViewAddParam):EntApiViewWp.Res? = ClientApiViewWp.net(EntM42Api.ENTITY_KEY to { param })
    suspend fun edit(param:MdlViewEditParam):EntApiViewEp.Res? = ClientApiViewEp.net(EntM42Api.ENTITY_KEY to { param })
}