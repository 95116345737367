package entity

import app.App
import frm.api.member.EntApiMemberMasterE
import m42.model.ViewModel

object EntClientAccountEdit:ViewModel() {
    var email = ""
    var secretKey = ""
    var secretKeyDate = ""
    var userName = ""
    var phone = ""
    var company = ""
    var profile = ""
    var profileList = mutableListOf<String>()

    fun init(){
        email = ""
        userName = ""
        phone = ""
        company = ""
        profile = ""
        profileList = mutableListOf(
            "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/21/f358e9c2224edd3c611355cb1f15b8b8.png",
            "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/21/502e76b6fd2ae5432c399ed322a57fda.png",
            "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/21/e669304040a5ae3517b6754c8dd4cd50.png",
            "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/21/163dc620d6e64451d847ca9a216cae8e.png",
            "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/21/aa6eb7f845b11ceacda43689c0437558.png",
            "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/21/79843605ef7473c45405adc64c6ca090.png",
            "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/21/4ace36a355dd168da906dc3e4e7b4896.png",
            "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/21/a1bbb1d6423237dbeaaef4f8bf890c9e.png",
        )
    }
    fun setData(target: EntApiMemberMasterE.Res){
        secretKey = target.member.secretKey
        secretKeyDate = "(${App.datePart(target.member.secretKeyDate)}에 발급됨)"
        email = target.member.email
        userName = target.member.userName
        phone = target.member.phone
        company = target.companyName
        profile = target.member.profileUrl
        profileList = target.profileUrlList
    }
}