package router.viewDecorator

import app.*
import comp.CompPageTitle
import comp.compPagination
import comp.compPaginationSet
import ein2b.core.view.displayBlock
import ein2b.core.view.displayNone
import ein2b.core.view.eEntityHook
import ein2b.core.view.eView
import entity.EntClientViewDecorator
import frm.api.EntApiViewDecoratorE
import m42.app.M42App
import m42.model.HcInit
import org.w3c.dom.HTMLElement
import view.CompViewToast

private val factory = Factory.htmlUrl("viewDecorator/main")
//language=html
private val itemFactory = Factory.html("""<div class="border-top"><div data-view="item"></div></div>""")
internal enum class K{
    addForm, list, page, item
}
suspend fun ViewDecoratorView() = eView(factory){ staticView ->
    val model = EntClientViewDecorator()
    CompPageTitle(staticView){
        it.title = "r@뷰 데코레이터 관리@5/b/5"
    }
    staticView.compViewDecoratorFormSet(K.addForm){
        it.submitBlock = {
            if(it.check()){
                val cvt = it.out()
                N.edit(cvt.title, cvt.js)?.also {
                    CompViewToast.setLocalStorage("r@컨버터가 등록되었습니다.@5/b/7")
                    M42App.reload()
                }
            }

        }
    }
    staticView.sub(K.list)
    staticView.compPaginationSet(K.page, "page"){}
    staticView.addEntityHook(HcInit::class, object:eEntityHook<HTMLElement, HcInit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:HcInit){
            model.init()
            view.clearCompValue(K.addForm)
        }
    })
    staticView.addEntityHook(EntApiViewDecoratorE.Res::class, object:eEntityHook<HTMLElement, EntApiViewDecoratorE.Res>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntApiViewDecoratorE.Res){
            model.setData(entity)
            view.entity(model)
        }
    })
    staticView.addEntityHook(EntClientViewDecorator::class, object:eEntityHook<HTMLElement, EntClientViewDecorator>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntClientViewDecorator){
            view.sub(K.list){ listView ->
                if(entity.list.isEmpty()) listView.displayNone() else listView.displayBlock()
                listView.setClearList{listListView ->
                    entity.list.forEach{viewDecorator ->
                        listListView += eView(itemFactory){
                            it.compViewDecoratorFormSet(K.item){ comp ->
                                comp.mdl.title = viewDecorator.title
                                comp.mdl.js = viewDecorator.js
                                comp.btnLabel = "r@수정@5/b/1"
                                comp.submitBlock = {
                                    if(comp.check()){
                                        val cvt = comp.out()
                                        N.edit(cvt.title, cvt.js, viewDecorator.viewDecoratorRowid)?.also {
                                            CompViewToast.setLocalStorage("r@컨버터가 수정되었습니다.@5/b/6")
                                            M42App.reload()
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            view.sub(K.page).compPagination{comp->
                comp.pageLink = ALink(RouterKey.VIEWDECORATOR, addArgKey = "p")
                comp.setPage(entity.meta.totalRows.toLong(), entity.meta.page.toLong(), entity.meta.rowPerPage.toLong(), entity.meta.pagePerGroup.toLong())
            }
        }
    })
}