package frm

import ein2b.core.date.eUtc
import ein2b.core.validation.eRuleVali

const val NOT_SET_DATE = "1900-01-01"
const val NOT_SET_DATETIME = "${frm.NOT_SET_DATE} 00:00:00"
const val DEFAULT_CURRENCY_ROWID = "200"
val NOT_SET_UTC = eUtc.of(frm.NOT_SET_DATETIME)!!
const val FILE_SIZE_10MB = 10485760

/** 계정 관련 */
const val MEMBER_CO_KEY = "member"

const val MEMBER_SUB_INFO_KEY = "subInfo" //가령 '회사명', '그룹리스트'

const val MEMBER_AC_USERNAME_KEY = "userName"
const val MEMBER_AC_PHONE_KEY = "phone"
const val MEMBER_AC_PROFILE_URL_KEY = "profileUrl"
const val MEMBER_AC_CARGO_COUNT_KEY = "cargoCount"
const val MEMBER_CO_LOGO_URL_KEY = "logoUrl"

const val HEX_2_TYPE_JS_DEV = "https://s3.ap-northeast-2.amazonaws.com/frm-upload.mobility42.io/dev/frmHex2Type.js"
const val VIEW_DECORATOR_JS_DEV = "https://s3.ap-northeast-2.amazonaws.com/frm-upload.mobility42.io/dev/frmViewDecorator.js"

const val HEX_2_TYPE_JS_PROD = "https://s3.ap-northeast-2.amazonaws.com/frm-upload.mobility42.io/frmHex2Type.js"
const val VIEW_DECORATOR_JS_PROD = "https://s3.ap-northeast-2.amazonaws.com/frm-upload.mobility42.io/frmViewDecorator.js"

/** 타임존 관련 */
const val TIMEZONE = "Asia/Seoul"

fun notSetUtcCheck(v:eUtc) = if(v == frm.NOT_SET_UTC) null else v

fun cdata(key:String):String{
    if(key.startsWith("c@")) return key.substring(2, key.indexOf('@', 2))
    else return key
}
fun addDashesToPhoneNumber(phoneNumber: String): String {
    val pattern = "(\\d{2,3})(\\d{3,4})(\\d{4})".toRegex()
    return pattern.replace(phoneNumber, "$1-$2-$3")
}

const val CAT1_S3_CONFIG_TITLE_KEY = "s3Config"