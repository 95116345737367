package entity

import app.App
import app.frmHex2Type
import app.frmViewDecorator
import ein2b.core.entity.eEntity
import frm.api.EntApiCat2ItemLogE
import frm.model.MdlCat2Item
import frm.model.MdlCat2ItemLog
import m42.common.model.MdlM42ApiPageMeta
import m42.model.ViewModel

class EntClientLog:ViewModel(){
    var isLogging = false
    var logMaxStorageDay = 0
    var catInfo:EntClientCatInfo? = null
    var list = listOf<MdlCat2ItemLog>()
    var meta = MdlM42ApiPageMeta()
    var viewDecoratorRowid = "0"  //데코레이터 로우아디
    fun init(){
        catInfo = null
        logMaxStorageDay = 0
        list = listOf()
    }
    fun setData(target:EntApiCat2ItemLogE.Res){
        viewDecoratorRowid = target.viewDecoratorRowid
        isLogging = target.cat2Item.isLogging
        logMaxStorageDay = target.cat2Item.logMaxStorageDay
        catInfo = EntClientCatInfo().also{
            it.setData(target.cat1.title, target.cat2.title, MdlCat2Item().also{ cat2Item ->
                cat2Item.cat2ItemRowid = target.cat2Item.cat2ItemRowid
                cat2Item.key = target.cat2Item.key
                cat2Item.value = App.getConverterStr(viewDecoratorRowid, target.cat2Item.hex2TypeRowid.toString(), target.cat2Item.value)
                cat2Item.title = target.viewItem.title.ifBlank { target.cat2Item.title }
                cat2Item.updateDate = target.cat2Item.updateDate
            })
        }
        list = target.cat2ItemLogList.map{log ->
            MdlCat2ItemLog().also{
                it.value = App.getConverterStr(viewDecoratorRowid, target.cat2Item.hex2TypeRowid.toString(), log.value)
                it.regDate = log.regDate
            }
        }
        meta = target.meta
    }
}

class EntClientLogUrl:eEntity(){
    var r by string
    var dr by string
    var p by string{ default("1") }
}