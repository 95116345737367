package comp.input

import app.ALink
import app.Factory
import comp.OutType
import app.eWindow
import ein2b.core.core.err
import ein2b.core.core.uuid
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import org.w3c.dom.HTMLElement
class CompSelect<V>:CompSelectBase<V>(){
    companion object {
        private val LIST = mutableListOf<CompSelect<*>>()
        //language=html
        private const val TEXT = "text"

        private val itemFactory:suspend ()-> HTMLElement = Factory.html("""<li><a data-view="$TEXT" class="alink item-item ellipsis"></a></li>""")
        operator fun<V> invoke(block:(CompSelect<V>)->Unit):CompSelect<V>{
            val comp = CompSelect<V>()
            LIST.add(comp)
            block(comp)
            comp.afterTargetInited = {
                comp.target.sub(PLACEHOLDER){
                    it.html = comp.placeholder
                    if(comp.initValue.isBlank()) it.displayBlock() else it.displayNone()
                }
                comp.target.sub(SELECT).html = comp.initValue
                comp.target.sub(ARROW)
                comp.target.sub(ITEM_LIST).className = if(comp.isReverse) "item-list-reverse scroll2" else "item-list scroll2"
                comp.itemListDisplay()
                comp.target.click = { _,_->
                    eWindow.currClickId = comp.compId
                    if(!comp.isDisabled) eLaunch{
                        if(!comp.isItemListOpen) LIST.forEach{ it.itemListDisplay(false) }
                        comp.itemListDisplay(!comp.isItemListOpen)
                    }
                }
                eWindow.addClick(comp.compId){ eLaunch{ comp.itemListDisplay(false) } }
            }
            return comp
        }
    }
    override var itemClass = "alink item-item ellipsis"
    public override suspend fun setList(vararg list:Item<V>){
        itemList = list
        value.inputValue(listOf())
        /**
         * 초기에 list를 빈 리스트로 주면 isDisabled가 true로 바뀌고
         * 나중에 list에 데이터를 넣어준 경우 isDisabled가 계속 true인 상태가 발생
         * isDisabled = list.all{it.isDisabled}를 하기에는 다른 비활성화 문제가 발생
         *
         * 이 코드를 삭제하고 클라이언트에서 isDisabled 값을 변경해줘서 컴포넌트의 활성화 여부를 직접 결정하도록 함
         * */
//        if(list.all{ it.isDisabled }) isDisabled = true
        target.className = setClassName()
        target.sub(SELECT).html = initValue
        target.sub(PLACEHOLDER).displayBlock()
        target.sub(ITEM_LIST).setClearList{
            list.forEachIndexed{ idx, item ->
                it += eView(itemFactory){ itemView ->
                    itemView.sub(TEXT){ textView ->
                        item.view = textView
                        item.idx = idx
                        textView.html = item.label
                        textView.className = setClassName(item)
                        clickLink?.also{
                            it.href(textView, itemList[idx].value)
                        } ?:also {
                            textView.click = { e, _ ->
                                e.stopPropagation()
                                e.stopImmediatePropagation()
                                if(!item.isDisabled) eLaunch{
                                    list.forEach{ listItem->
                                        listItem.isSelect = false
                                        listItem.view?.className = setClassName(listItem)
                                    }
                                    item.isSelect = true
                                    textView.className = setClassName(item)
                                    itemListDisplay()

                                    value.inputValue(listOf(item.idx))
                                    value.check()
                                    checkBlock?.invoke(item.idx)
                                }
                            }
                        }
                    }
                }
                if(item.isSelect){
                    defaultIdx = item.idx
                    value.inputValue(listOf(item.idx))
                    value.check()
                    initBlock?.invoke(item.idx)
                }
            }
        }
    }
}

// ============================ prop ============================
suspend fun<T> eView<HTMLElement>.compSelectSet(subKey:Any, block:(CompSelect<T>)->Unit = { }){
    CompSelect<T>{
        block(it)
    }.also{
        it.comp(this, subKey)
    }
}
@Suppress("UNCHECKED_CAST")
inline fun<T> eView<HTMLElement>.compSelect(block:(CompSelect<T>)->Unit = { }):CompSelect<T>{
    val comp = this["compSelect_value"] as? CompSelect<T> ?: err("fail to get compSelect with type")
    block(comp)
    return comp
}
inline fun eView<HTMLElement>.compSelect(block:(CompSelect<*>)->Unit = { }):CompSelect<*>{
    val comp = this["compSelect_value"] as? CompSelect<*> ?: err("fail to get compSelect")
    block(comp)
    return comp
}

@Suppress("UNCHECKED_CAST")
inline fun eView<HTMLElement>.compSelectString(block:(CompSelect<String>)->Unit = { }):CompSelect<String>{
    val comp = this["compSelect_value"] as? CompSelect<String> ?: err("fail to get compSelectString")
    block(comp)
    return comp
}

@Suppress("UNCHECKED_CAST")
inline fun eView<HTMLElement>.compSelectInt(block:(CompSelect<Int>)->Unit = { }):CompSelect<Int>{
    val comp = this["compSelect_value"] as? CompSelect<Int> ?: err("fail to get compSelectString")
    block(comp)
    return comp
}
































