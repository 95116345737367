package entity

import frm.api.EntApiViewDecoratorE
import frm.model.MdlViewDecorator
import m42.common.model.MdlM42ApiPageMeta
import m42.model.ViewModel

class EntClientViewDecorator:ViewModel(){
    class ViewDecorator{
        var title = ""
        var js = ""
        fun init(){
            title = ""
            js = ""
        }
    }
    var meta = MdlM42ApiPageMeta()
    var list = mutableListOf<MdlViewDecorator>()
    suspend fun init(){
        list = mutableListOf()
    }
    suspend fun setData(target: EntApiViewDecoratorE.Res){
        meta = target.meta
        list = target.viewDecoratorList.filter{ it.viewDecoratorRowid != 0L }.map{ from->
            MdlViewDecorator().also{to->
                to.viewDecoratorRowid = from.viewDecoratorRowid
                to.title = from.title
                to.js = """
                    ${from.js}
                """.trimIndent()
            }
        }.toMutableList()
    }
}