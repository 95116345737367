package router.comp

import app.ALink
import app.Factory
import app.RouterKey
import comp.Comp
import ein2b.core.view.className
import ein2b.core.view.eView
import ein2b.core.view.html
import enum.EnumClientLogOnOff
import org.w3c.dom.HTMLElement

class CompLog: Comp {
    companion object{
        private enum class K{ link, state }
        val factory = Factory.htmlUrl("comp/logBtn")
        suspend operator fun invoke(rootView: eView<HTMLElement>, subKey:Any, block:(CompLog)->Unit): CompLog{
            val comp = CompLog()
            block(comp)
            comp.target = rootView.sub(subKey, factory){target->
                target.sub(K.link){link->
                    ALink(RouterKey.LOG, mutableMapOf("r" to comp.cat2ItemRowid, "dr" to comp.viewDecoratorRowid)).href(link)
                }
                target.sub(K.state){state->
                    state.html = if(comp.isActive) EnumClientLogOnOff.ON.badge else EnumClientLogOnOff.OFF.badge
                    state.className = "margin-left4 info-small ${if(comp.isActive) "info-green" else "info-gray"}"
                }
            }
            return comp
        }
    }
    private lateinit var target: eView<HTMLElement>
    var cat2ItemRowid = 0L
    var isActive = false
    var viewDecoratorRowid = 0L
}