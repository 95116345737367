import app.*
import app.RouterKey.HOME
import ein2b.core.core.Now
import ein2b.core.core.err
import ein2b.core.core.jsNow
import ein2b.core.coroutine.eLaunch
import ein2b.core.net.eApi
import ein2b.core.net.eApiInfo
import ein2b.core.net.eResponseTask
import ein2b.core.view.eDomBinder
import ein2b.core.view.eView
import frm.HEX_2_TYPE_JS_DEV
import frm.HEX_2_TYPE_JS_PROD
import frm.VIEW_DECORATOR_JS_DEV
import frm.VIEW_DECORATOR_JS_PROD
import kotlinx.browser.window
import m42.app.M42App
import m42.common.M42ApiResource
import m42.common.M42ApiSetting
import m42.common.model.MdlM42Setting
import view.*

suspend fun main(){
    Now.instance = jsNow
    eView.binder = eDomBinder().apply{ start() }

    M42App.homeRouterKey = HOME
    M42App.hashManager = hashManager

    CompRouter.maxWidth = -1
    CompRouter.menuWidth = 220

    M42ApiSetting.blockingOpen = { CompViewBlocking.open() }
    M42ApiSetting.blockingClose = { CompViewBlocking.close() }
    M42ApiSetting

    val hex2TypeJsS3Url = if(M42ApiResource.isLocalHost) HEX_2_TYPE_JS_DEV else HEX_2_TYPE_JS_PROD
    App.converterJsSetting(hex2TypeJsS3Url)

    val viewDecoratorJsS3Url = if(M42ApiResource.isLocalHost) VIEW_DECORATOR_JS_DEV else VIEW_DECORATOR_JS_PROD
    App.converterJsSetting(viewDecoratorJsS3Url)

    var setting = MdlM42Setting().also{ it.data = hashMapOf("serverUrl" to window.location.origin) }
    val s3Url = if(M42ApiResource.isLocalHost) "https://frm.mobility42.io"
    else{
        eApi("setting", eApi.DEFAULT to eApiInfo{
            method = eApi.GET
            url = "${window.location.origin}/setting/front"
            responseTask += eResponseTask.Text
            responseTask += eResponseTask.Entity{ MdlM42Setting() }
        })().also{ result ->
            if(result.isOk){
                (result.response!!.result as? MdlM42Setting)?.let{ res ->
                    setting = res
                }
            }
        }
        ""
    }

    M42ApiResource.init(s3Url, "ln" to "ko"){
        eLaunch{
            CompViewBlocking.init(CompRouter.viewEL)
            CompViewAlert.init(CompRouter.viewEL)
            CompViewConfirm.init(CompRouter.viewEL)
            CompViewToast.init(CompRouter.viewEL)

            CompViewLnb.init(CompRouter.menuRouterEl, "r@wmsView@5/4/4", false){
                M42App.goUrl(M42App.homeRouterKey)
            }
            CompViewLnb.displayNone()

            //LNB 스켈레톤
            CompViewLnb.dummyOn()
            window.setInterval({
                eLaunch{ CompViewLnb.dummyOff() }
            }, 250)

            Api.serverDomain = setting.data["serverUrl"] ?: err("세팅 정보를 가져오지 못했습니다.")
            ClientApiMemberLoginCheck.net().also{
                hashManager.init()
            }
        }
    }
}