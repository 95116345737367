package entity

import frm.api.EntApiHex2TypeE
import frm.enum.EnumTypeCat
import frm.model.MdlHex2Type
import m42.common.model.MdlM42ApiPageMeta
import m42.model.ViewModel

class EntClientHex2Type:ViewModel(){
    class Hex2Type{
        var title = ""
        var js = ""
        var typeCat:EnumTypeCat? = null
        fun init(){
            title = ""
            js = ""
            typeCat = null
        }
    }
    var meta = MdlM42ApiPageMeta()
    var list = mutableListOf<MdlHex2Type>()
    suspend fun init(){
        list = mutableListOf()
    }
    suspend fun setData(target: EntApiHex2TypeE.Res){
        meta = target.meta
        list = target.hex2TypeList.map{from->
            MdlHex2Type().also{ to->
                to.hex2TypeRowid = from.hex2TypeRowid
                to.typeCat = from.typeCat
                to.title = from.title
                to.js = """
                    ${from.js}
                """.trimIndent()
            }
        }.toMutableList()
    }
}