package frm.model

import ein2b.core.entity.eEntity
import frm.enum.EnumEventCat
import m42.common.model.MdlM42ApiPageMeta

class MdlCat2ItemEventCmd:eEntity(true){
    var cat2ItemEventCmdRowid by long
    var cat2ItemRowid by long
    var eventCat by enum<EnumEventCat>()
    var hex2TypeRowid by long
    var condition by string
    var period by int
    var emailList by string
    var message by string
    var isActive by boolean
    var executionPeriod by int
    var regDate by utc
}
class MdlCat2ItemEvent:eEntity(true){
    var cat2ItemEventRowid by long
    var cat2ItemEventCmdRowid by long
    var value by string
    var memo by string
    var regDate by utc

    //클라에서 사용
    var isFormOpen = false
}
class MdlCat2ItemEventMeta:MdlM42ApiPageMeta(){
    companion object{
        val DEFAULT = MdlCat2ItemEventMeta().also{
            it.cat2ItemEventCmdRowid = 0L
        }
    }
    var cat2ItemEventCmdRowid by long
}