package frm.model

import ein2b.core.entity.eEntity
import ein2b.core.entity.field.IntMapField.encoding

class MdlMenuCat1:eEntity(true){
    var cat1Rowid by string
    var title by string
    var viewList by entityList(::MdlMenuView)
}

class MdlMenuView:eEntity(true){
    var cat1Rowid by string{ encoding.isExcluded() }
    var viewRowid by string
    var title by string
}