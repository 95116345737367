package router.viewForm

import app.RouterKey
import app.hashManager
import ein2b.core.view.eEntityHook
import ein2b.core.view.eView
import frm.api.EntApiViewW
import frm.model.MdlViewAddParam
import org.w3c.dom.HTMLElement
import view.CompViewAlert

suspend fun ViewAddView() = ViewFormView("r@뷰 등록@5/6/f","r@등록@5/4/j", isAdd = true, submit = {param->
    N.add(param as MdlViewAddParam)?.also{ res ->
        CompViewAlert.open("r@뷰가 등록되었습니다. 상세 페이지로 이동합니다.@5/6/h"){
            hashManager.goUrl(RouterKey.VIEW_DETAIL, "r" to res.viewRowid)
        }
    }
}){staticView, model ->
    staticView.addEntityHook(EntApiViewW.Res::class, object:eEntityHook<HTMLElement, EntApiViewW.Res> {
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntApiViewW.Res){
            model.init()
            model.addSetData(entity)
            view.entity(model)
        }
    })
}