package entity

import app.frmViewDecorator
import app.frmHex2Type
import frm.api.EntApiViewV
import m42.model.ViewModel

class EntClientView: ViewModel(){
    class Item{
        var cat2ItemRowid = 0L
        var viewDecoratorRowid = 0L
        var title = ""
        var value = ""
        var eventCnt = 0
        var hasEvent = false
        var isLogActive = false
    }
    class Cat2{
        var title = ""
        var viewItemList = mutableListOf<Item>()
    }
    var viewRowid = 0L
    var viewTitle = ""
    var refreshRate = ""
    var cat2List = mutableListOf<Cat2>()
    fun setData(target:EntApiViewV.Res){
        val view = target.view
        viewRowid = view.viewRowid
        viewTitle = view.title
        refreshRate = target.rateCatMin.toString()
        cat2List = target.cat2List.map{ mdlCat2->
            Cat2().also{ cat2 ->
                cat2.title = mdlCat2.title
                val c2ItemList = target.cat2List.flatMap{ it.cat2ItemList }
                val dbViewItemRowids = view.viewItemList.map{ it.cat2ItemRowid }
                val vItemList = c2ItemList.filter{ it.cat2ItemRowid in dbViewItemRowids }
                cat2.viewItemList = vItemList.filter{ it.cat2Rowid == mdlCat2.cat2Rowid }.map{ cat2Item->
                    view.viewItemList.find{ it.cat2ItemRowid == cat2Item.cat2ItemRowid }!!.let{ viewItem->
                        val v = if(viewItem.viewDecoratorRowid == 0L) frmHex2Type(cat2Item.hex2TypeRowid.toString(), cat2Item.value) else frmViewDecorator(viewItem.viewDecoratorRowid.toString(), frmHex2Type(cat2Item.hex2TypeRowid.toString(), cat2Item.value))
                        Item().also{ item->
                            item.cat2ItemRowid = viewItem.cat2ItemRowid
                            item.title = viewItem.title.ifBlank{ cat2Item.title }
                            item.value = "${v}${if(viewItem.unit.isBlank()) "" else " ${viewItem.unit}"}"
                            item.hasEvent = cat2Item.hasEvent
                            item.eventCnt = cat2Item.eventCnt
                            item.isLogActive = cat2Item.isLogging
                            item.viewDecoratorRowid = viewItem.viewDecoratorRowid
                        }
                    }
                }.toMutableList()
            }
        }.toMutableList()
    }
}