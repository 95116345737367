package frm.api

import ein2b.core.entity.Union
import ein2b.core.entity.eEntity
import frm.MEMBER_AC_PROFILE_URL_KEY
import frm.MEMBER_AC_USERNAME_KEY
import frm.MEMBER_SUB_INFO_KEY
import m42.member.M42MemberSession

sealed class FrmApiResponseMember:eEntity(true){
    companion object:Union<FrmApiResponseMember>(FrmApiResponseMember::Login, FrmApiResponseMember::Logout){
        fun fromMemberSession(m: M42MemberSession.Login) = Login().also{
            it.memberAcRowid = m.memberAcRowid
            it.masterAcRowid = m.masterAcRowid
            it.uuid = m.uuid
            it.isMaster = m.isMaster
            it.coKey = m.coKey
            it.userName = m.data[MEMBER_AC_USERNAME_KEY] ?: ""
            it.subInfo = m.data[MEMBER_SUB_INFO_KEY] ?: ""
            it.authzKeys = m.authzKeys
            it.profileUrl = m.data[MEMBER_AC_PROFILE_URL_KEY] ?: ""
        }
        fun fromMemberSession(m:M42MemberSession.Logout) = Logout()
    }
    class Login:FrmApiResponseMember(){
        var memberAcRowid by long{
            encoding.isExcluded()
        }
        var masterAcRowid by long{
            encoding.isExcluded()
        }
        var uuid by string
        var isMaster by boolean
        var coKey by string
        var authzKeys by stringList
        var profileUrl by string
        var subInfo by string
        var userName by string
    }
    class Logout:FrmApiResponseMember()
}