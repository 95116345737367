package router.log

import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import entity.EntClientLogUrl
import m42.app.M42App
import m42.domBase.PageBase
import org.w3c.dom.HTMLElement

class LogP(router:eRouter<HTMLElement>):PageBase(::LogView, router){
    override suspend fun net(data:eRouterData){
        data.parse(EntClientLogUrl())?.also{ ent->
            N.ready(ent.r.toLong(), ent.p.toLong())?.also{ res ->
                res.viewDecoratorRowid = ent.dr
                view?.entity(res)
            }
        } ?: M42App.routerError()
    }
}